import { Box, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { $verifyEmailError } from '@/services/email'
import { I18n } from '@/services/i18n'
import { $user, $userError, User, verifyChangeEmailFx } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { If } from '@/shared/utils/if'

import { $ChangeEmailRequestCodeForm, $ChangeEmailVerifyEmailForm, ChangeEmailByCodeMode } from '../../model'
import { changeChangeEmailByCodeMode } from '../../model/events'

export const ChangeEmailByCodeStep2 = () => {
  const changeEmailError = useStore($verifyEmailError)
  const changeEmailPending = useStore(verifyChangeEmailFx.pending)
  const userError = useStore($userError)
  const user = useStore($user) as User

  const {
    fields: { newEmail },
  } = useForm($ChangeEmailRequestCodeForm)
  const { fields, submit, errorText } = useForm($ChangeEmailVerifyEmailForm)

  return (
    <>
      <TextField
        label={
          <>
            <I18n id='features.profile.change_email.code_current_email' /> {user.email}
          </>
        }
        fullWidth
        disabled={changeEmailPending}
        error={fields.code.hasError()}
        variant='standard'
        helperText={errorText('code')}
        onKeyDown={(e) => e.key === 'Enter' && submit()}
        value={fields.code.value}
        onChange={(e) => fields.code.onChange(e.target.value)}
      />
      <TextField
        label={
          <>
            <I18n id='features.profile.change_email.code_new_email' /> {newEmail.value}
          </>
        }
        fullWidth
        disabled={changeEmailPending}
        error={fields.newEmailCode.hasError()}
        variant='standard'
        helperText={errorText('newEmailCode')}
        onKeyDown={(e) => e.key === 'Enter' && submit()}
        value={fields.newEmailCode.value}
        onChange={(e) => fields.newEmailCode.onChange(e.target.value)}
      />
      <Box sx={{ mt: 2 }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          loading={changeEmailPending}
          onClick={() => submit()}
        >
          <I18n id='features.profile.change_email.change_email' />
        </LoadingButton>
        <Button
          sx={{ width: '100%' }}
          color='secondary'
          onClick={() => {
            changeChangeEmailByCodeMode(ChangeEmailByCodeMode.STEP1)
          }}
        >
          <I18n id='base.cancel' />
        </Button>
      </Box>
      <If condition={changeEmailError || userError}>
        <AlertError text={changeEmailError || userError} variant='filled' sx={{ mt: 2 }} />
      </If>
    </>
  )
}
