import { Card, CardActions, CardContent, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { $userError } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { Text } from '@/shared/components/text'
import { If } from '@/shared/utils/if'

import { changeSignUpMode, SignUpMode } from '../../model'

import { $signUpError, $SignUpForm, $signUpPending } from './model'
import * as Styled from './styled'

export const SignUpByPassword = () => {
  const signUpError = useStore($signUpError)
  const signUpPending = useStore($signUpPending)
  const userError = useStore($userError)

  const { fields, submit, errorText } = useForm($SignUpForm)

  return (
    <Card sx={{ minWidth: 250 }}>
      <CardContent>
        <Text
          id='features.auth.ui.sign-up.base.title'
          sx={{ fontSize: 24, textAlign: 'center', mb: 1 }}
          color='text.secondary'
          gutterBottom
        />
        <Styled.Fields>
          <TextField
            label={<I18n id='base.email' />}
            autoFocus
            variant='standard'
            disabled={signUpPending}
            error={fields.email.hasError()}
            helperText={errorText('email')}
            value={fields.email.value}
            onKeyDown={(e) => e.key === 'Enter' && submit()}
            onChange={(e) => fields.email.onChange(e.target.value)}
          />
          <TextField
            label={<I18n id='base.password' />}
            type='password'
            disabled={signUpPending}
            error={fields.password.hasError()}
            variant='standard'
            helperText={errorText('password')}
            onKeyDown={(e) => e.key === 'Enter' && submit()}
            value={fields.password.value}
            onChange={(e) => fields.password.onChange(e.target.value)}
          />
        </Styled.Fields>
      </CardContent>
      <CardActions sx={{ flexDirection: 'column' }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          loading={signUpPending}
          onClick={() => submit()}
        >
          <I18n id='features.auth.ui.sign-up.base.register_button' />
        </LoadingButton>
        <Button sx={{ width: '100%' }} color='secondary' onClick={() => changeSignUpMode(SignUpMode.BY_CODE)}>
          <I18n id='features.auth.ui.sign-up.base.reg_without_pass' />
        </Button>
      </CardActions>
      <Styled.Errors>
        <If condition={signUpError || userError}>
          <AlertError text={signUpError || userError} variant='filled' sx={{ mt: 2 }} />
        </If>
      </Styled.Errors>
    </Card>
  )
}
