import { AlertProps, Fade, Tooltip, Typography, TypographyProps } from '@mui/material'
import React from 'react'

import { I18n, i18n } from '@/services/i18n'
import { IconButton } from '@/shared/components/icon-button'
import { If } from '@/shared/utils/if'

import * as Styled from './styled'

type Props = AlertProps & {
  text?: string | null | React.ReactNode
  TypographyProps?: TypographyProps
  size?: number
  onReload?: () => void
}
export const AlertError = ({
  text = i18n('shared.components.alert.errorText'),
  size = 16,
  onReload,
  TypographyProps = {},
  ...otherProps
}: Props) => {
  return (
    <Styled.Root severity='error' {...otherProps}>
      <Typography variant='body2' whiteSpace='break-spaces' textAlign='left' fontSize={size} {...TypographyProps}>
        {text}
      </Typography>
      <If condition={onReload}>
        <Tooltip
          arrow
          title={<I18n id='shared.components.alert.repeat_request' />}
          placement='left'
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <IconButton iconName='Replay' size='small' onClick={onReload} />
        </Tooltip>
      </If>
    </Styled.Root>
  )
}
