import { forward, guard, sample } from 'effector'

import { requestCodeSignIn, requestCodeSignInFx } from '@/services/email'
import { signInByCode, signInByCodeApi, signInByCodeFx, signInFx } from '@/services/user'

import { SingInByCodeMode } from './enum'
import { changeSingInByCodeMode } from './events'
import { $SignInRequestCodeForm, $SignInVerifyEmailForm } from './forms'

signInByCodeFx.use(signInByCodeApi)

guard({
  source: signInByCode,
  filter: signInByCodeFx.pending.map((state) => !state),
  target: signInByCodeFx,
})

forward({
  from: [signInByCodeFx.done, signInFx.done],
  to: [$SignInVerifyEmailForm.reset, $SignInRequestCodeForm.reset],
})

forward({
  from: requestCodeSignInFx.done,
  to: changeSingInByCodeMode.prepend(() => SingInByCodeMode.STEP2),
})

forward({
  from: $SignInRequestCodeForm.formValidated,
  to: requestCodeSignIn,
})

sample({
  source: $SignInRequestCodeForm.$values,
  clock: $SignInVerifyEmailForm.formValidated,
  fn: ({ email }, { code }) => ({ email, code }),
  target: signInByCode,
})
