import { Drawer } from '@mui/material'
import { useStore } from 'effector-react'

import { $user } from '@/services/user'

import { $openDrawerMenu, changeDrawerMenu } from '../../model/menu'
import { SidebarMenu } from '../sidebar-menu'

import { useCloseDrawerOnRouteChange } from './hooks'

export const DrawerMenu = () => {
  const open = useStore($openDrawerMenu)
  const user = useStore($user)
  if (!user) return null

  useCloseDrawerOnRouteChange()

  const handleClose = () => {
    changeDrawerMenu(false)
  }

  return (
    <Drawer anchor='right' open={open} onClose={handleClose}>
      <SidebarMenu />
    </Drawer>
  )
}
