import { forward, guard } from 'effector'

import { signUpFast, signUpFastApi, signUpFastFx } from '@/services/user'

import { $SignUpByUsernameForm } from './forms'

signUpFastFx.use(signUpFastApi)

guard({
  source: signUpFast,
  filter: signUpFastFx.pending.map((state) => !state),
  target: signUpFastFx,
})

forward({
  from: $SignUpByUsernameForm.formValidated,
  to: signUpFast,
})

forward({
  from: signUpFastFx.doneData,
  to: [$SignUpByUsernameForm.reset],
})
