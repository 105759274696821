import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { signUpByCodeFx } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { If } from '@/shared/utils/if'

import { $SignInSecretCodeForm, $secretModeModalVisible, $signInBySecretCodeError } from './model'
import { changeVisibleSecretModeModal } from './model/events'

export const SignInSecretCode = () => {
  const isOpen = useStore($secretModeModalVisible)
  const { fields, submit, errorText } = useForm($SignInSecretCodeForm)
  const pending = useStore(signUpByCodeFx.pending)
  const error = useStore($signInBySecretCodeError)

  return (
    <>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Button color='secondary' onClick={() => changeVisibleSecretModeModal(true)}>
          <I18n id='features.auth.secret-code.button' />
        </Button>
      </Box>
      <Dialog
        open={isOpen}
        onClose={() => changeVisibleSecretModeModal(false)}
        maxWidth='xs'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' sx={{ textAlign: 'center' }}>
          <I18n id='features.auth.secret-code.modal.title' />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
            <TextField
              label={<I18n id='base.secret-code' />}
              type='password'
              fullWidth
              disabled={pending}
              error={fields.secretCode.hasError()}
              variant='standard'
              helperText={errorText('secretCode')}
              onKeyDown={(e) => e.key === 'Enter' && submit()}
              value={fields.secretCode.value}
              onChange={(e) => fields.secretCode.onChange(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
          <Button onClick={() => changeVisibleSecretModeModal(false)} color='error'>
            <I18n id='base.cancel' />
          </Button>
          <LoadingButton sx={{ minWidth: 200 }} variant='contained' onClick={() => submit()} color='success' autoFocus>
            <I18n id='base.enter' />
          </LoadingButton>
        </DialogActions>
        <If condition={error}>
          <Box sx={{ margin: '0 8px 8px 8px' }}>
            <AlertError text={error} variant='filled' sx={{ mt: 2 }} />
          </Box>
        </If>
      </Dialog>
    </>
  )
}
