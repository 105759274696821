import { Box, Divider } from '@mui/material'
import { useStore } from 'effector-react'

import { $user } from '@/services/user'
import { Logo } from '@/shared/components/logo'
import { useMedia } from '@/shared/utils/media'

import * as Styled from './styled'
import { BottomBlock } from './ui/bottom-block'
import { SidebarHelp } from './ui/help'
import { SidebarList } from './ui/list'
import { UserProfile } from './ui/user-profile'

export const SidebarMenu = () => {
  const user = useStore($user)
  const { isDesktop } = useMedia()

  if (!user) return null

  return (
    <Styled.SidebarContainer $isDesktop={isDesktop}>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Logo />
        <Divider />
        <Box sx={{ px: 1, my: 2 }}>
          <UserProfile username={user.username} />
        </Box>
        <SidebarList />
      </Box>

      <Box sx={{ width: '100%' }}>
        <SidebarHelp onboardingStep={user.preferences.onboardingStep} />
        <Divider />
        <BottomBlock />
      </Box>
    </Styled.SidebarContainer>
  )
}
