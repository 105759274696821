import { Box, TextField, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'

import { NAV_CLASSES } from '@/shared/consts/onboarding'
import { getTodayDate } from '@/shared/utils/moment'

import { $entry, getTodayEntryFx, MainGate, updateTodayEntry } from './model'
import * as Styled from './styled'
import { LoadingOverlay } from './ui/loading'
import { MoodSelector } from './ui/mood-selector'
import { StatusIndicator } from './ui/status-indicator'

export const Main = () => {
  const entry = useStore($entry)
  const actualLoading = useStore(getTodayEntryFx.pending) && !entry

  useGate(MainGate)

  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateTodayEntry({ content: event.target.value })
  }

  return (
    <Styled.Root className={NAV_CLASSES.TODAY.PAGE}>
      <Typography variant='h4' sx={{ color: 'text.primary' }}>
        Сегодня
      </Typography>
      <Box display='flex' justifyContent='space-between' alignItems='center' width='100%' sx={{ my: 3 }}>
        <Typography variant='h5' sx={{ color: 'text.primary' }}>
          {getTodayDate()}
        </Typography>
        <MoodSelector disabled={actualLoading} />
      </Box>
      <Box position='relative' width='100%' className={NAV_CLASSES.TODAY.FORM_INPUT}>
        <TextField
          fullWidth
          multiline
          rows={10}
          variant='outlined'
          value={entry?.content || ''}
          onChange={handleContentChange}
          placeholder='Как у тебя сегодня дела?)'
          disabled={actualLoading}
        />
        <LoadingOverlay loading={actualLoading} />
      </Box>
      <StatusIndicator />
    </Styled.Root>
  )
}
