import { Api } from '@/services/api'

import { UpdateMoodParams, UpdateTodayEntryParams } from './types'

export const getTodayEntryApi = () => {
  return Api.get('/diary-entry/today/get/')
}

export const updateTodayEntryApi = (data: UpdateTodayEntryParams) => {
  return Api.put('/diary-entry/today/update', data)
}

export const updateMoodApi = (data: UpdateMoodParams) => {
  return Api.put('/diary-entry/today/mood/', data)
}
