import { Box } from '@mui/material'
import { useStore } from 'effector-react'

import { $user, User } from '@/services/user'

import { PasswordSettingsHeader } from './ui/header'
import { PasswordSettingsInfo } from './ui/info'

export const PasswordSettings = () => {
  const user = useStore($user) as User

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <PasswordSettingsHeader />
      <PasswordSettingsInfo passwordVerified={user.hasPassword} />
    </Box>
  )
}
