import { SectionKey } from './enum'

export const sections = [
  { id: SectionKey.HowTo, label: 'Как пользоваться?', icon: 'HelpOutline' },
  { id: SectionKey.Interesting, label: 'Что тут интересного?', icon: 'Info' },
  { id: SectionKey.Problems, label: 'Проблемы', icon: 'BugReport' },
]

export const content: Record<SectionKey, { question: string; answer: string }[]> = {
  howTo: [
    {
      question: 'Как создать учетную запись?',
      answer:
        'У нас можно легко создать свою учетную запись без регистрации. Но что бы не потерять свои данные, лучше зарегистрироваться',
    },
    {
      question: 'Как добавить запись о своем дне?',
      answer:
        'Вы можете это делать на главной странице, просто заходите в любое время и записывайте, в конце дня воспоминание само сохранится',
    },
    {
      question: 'Можно ли добавить запись за прошлые дни?',
      answer:
        'Да, вы можете добавить запись за любой день, начиная с момента регистрации. Просто перейдите в календарь, нажмите на нужную дату и в открывшемся окошечке вы сможете добавить или отредактировать воспоминание',
    },
    {
      question: 'Как просматривать свои записи?',
      answer:
        'На главной странице вы можете увидеть свою запись за сегодняшний день. Для просмотра записей за предыдущие дни, перейдите в раздел "Календарь". Так же вам будут приходить воспоминания в раздел... Воспоминаний!)',
    },
    {
      question: 'Как поделиться своей записью?',
      answer: 'На текущий момент своими записями нельзя делиться',
    },
  ],
  interesting: [
    {
      question: 'Что особенного на этом сайте?',
      answer:
        'Приложение помогает сохранять ценные моменты каждого дня, а также отслеживать свои эмоции. Календарь отображает дни с разными эмоциями, помогая проанализировать и вспомнить, что делало ваш день особенным.',
    },
    {
      question: 'Как приложение помогает сохранять воспоминания?',
      answer:
        'Приложение не только позволяет добавлять ежедневные записи, но и напоминает вам о том, что происходило в прошлом: неделю назад, месяц назад, год и более. Таким образом, вы можете просматривать свои эмоции и значимые моменты в ретроспективе.',
    },
    {
      question: 'Зачем нужны смайлы для оценки дня?',
      answer:
        'Использование смайлов — это простой и удобный способ оценить свой день. Смайлы позволяют быстро отразить ваше настроение и помогают лучше понять свои эмоции в долгосрочной перспективе.',
    },
  ],
  problems: [
    {
      question: 'Я не могу добавить запись за прошлый день. Что делать?',
      answer:
        'Убедитесь, что вы выбрали нужную дату в календаре перед тем, как добавлять запись. Если проблема не исчезает, попробуйте перезагрузить страницу или выйти и войти в приложение снова.',
    },
    {
      question: 'Почему моя запись не сохраняется?',
      answer:
        'Проверьте, заполнили ли вы все необходимые поля и выбрали ли смайлик для оценки дня. Если проблема сохраняется, убедитесь, что у вас стабильное интернет-соединение, и повторите попытку.',
    },
    {
      question: 'Как сбросить пароль?',
      answer:
        'Если вы забыли пароль, на странице входа нажмите "Забыли пароль?" и следуйте инструкциям для сброса пароля. Мы отправим вам письмо с дальнейшими шагами для восстановления доступа к учетной записи.',
    },
    {
      question: 'Как связаться со службой поддержки?',
      answer:
        'Если у вас возникли технические проблемы, нажмите на кнопку "Связаться с нами" в разделе помощи, чтобы отправить нам сообщение. Мы постараемся ответить как можно быстрее.',
    },
  ],
}
