import { Card, CardActions, CardContent, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { $userError, signUpByCodeFx } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { Text } from '@/shared/components/text'
import { If } from '@/shared/utils/if'

import { $SignUpRequestCodeForm, $SignUpVerifyEmailForm } from '../../model'
import { SingUpByCodeMode } from '../../model/enum'
import { changeSingUpByCodeMode, resetErrors } from '../../model/events'
import { $signUpByCodeError } from '../../model/store'

import * as Styled from './styled'

export const SignUpByCodeStep2 = () => {
  const signUpError = useStore($signUpByCodeError)
  const signUpPending = useStore(signUpByCodeFx.pending)
  const userError = useStore($userError)

  const {
    fields: { email },
  } = useForm($SignUpRequestCodeForm)
  const { fields, submit, errorText, reset } = useForm($SignUpVerifyEmailForm)

  return (
    <Card sx={{ minWidth: 250 }}>
      <CardContent>
        <Text
          id='features.auth.ui.sign-up.without_pass.title'
          sx={{ fontSize: 24, textAlign: 'center', mb: 1 }}
          color='text.secondary'
          gutterBottom
        />
        <Styled.Fields>
          <TextField variant='standard' label={<I18n id='base.email' />} autoFocus disabled value={email.value} />
          <TextField
            label={<I18n id='base.code' />}
            type='password'
            disabled={signUpPending}
            error={fields.code.hasError()}
            variant='standard'
            helperText={errorText('code')}
            onKeyDown={(e) => e.key === 'Enter' && submit()}
            value={fields.code.value}
            onChange={(e) => fields.code.onChange(e.target.value)}
          />
        </Styled.Fields>
      </CardContent>
      <CardActions sx={{ flexDirection: 'column' }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          loading={signUpPending}
          onClick={() => submit()}
        >
          <I18n id='features.auth.ui.sign-up.base.register_button' />
        </LoadingButton>
        <Button
          sx={{ width: '100%' }}
          color='secondary'
          onClick={() => {
            reset()
            resetErrors()
            changeSingUpByCodeMode(SingUpByCodeMode.STEP1)
          }}
        >
          <I18n id='base.cancel' />
        </Button>
      </CardActions>
      <Styled.Errors>
        <If condition={signUpError || userError}>
          <AlertError text={signUpError || userError} variant='filled' sx={{ mt: 2 }} />
        </If>
      </Styled.Errors>
    </Card>
  )
}
