import { styled } from '@mui/material'

export const Root = styled('div')({
  width: '100%',
  margin: '12px 0 24px 0',
  padding: '0 32px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})
