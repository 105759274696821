import { requestCodeSignInFx } from '@/services/email'
import { i18n } from '@/services/i18n'
import { errorMessages, signInByCodeFx, signInFx } from '@/services/user'

import { domain } from './domain'
import { SingInByCodeMode } from './enum'
import { changeSingInByCodeMode, resetErrors } from './events'

export const $signInByCodeError = domain
  .createStore<string | null>(null)
  .on(signInByCodeFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([signInByCodeFx.done, resetErrors])

export const $requestCodeSignInError = domain
  .createStore<string | null>(null)
  .on(requestCodeSignInFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([requestCodeSignInFx.done, resetErrors])

export const $singInByCodeMode = domain
  .createStore<SingInByCodeMode>(SingInByCodeMode.STEP1)
  .on(changeSingInByCodeMode, (_, mode) => mode)
  .reset([signInByCodeFx.done, signInFx.done])
