import { createDomain } from 'effector'

import { DOMAINS } from '@/shared/consts/domains'

export const SUB_DOMAIN = {
  NEW_EMAIL: 'NEW_EMAIL',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  PASSWORD: 'PASSWORD',
  USERNAME: 'USERNAME',
}

export const domain = createDomain(DOMAINS.profile)
