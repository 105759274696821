import { SvgIconProps } from '@mui/material'
import { FlagComponent, GB, RU } from 'country-flag-icons/react/3x2'
import { useStore } from 'effector-react'
import { FormattedMessage, IntlProvider, IntlShape, PrimitiveType, createIntl, createIntlCache } from 'react-intl'

import { getLastSegment } from '@/shared/utils/get-last-segment'

import { STORAGE_NAMES, Storage } from '../local-storage'

import { domain } from './domain'
import { Language } from './enum'
import EnglishMessages from './locales/en.json'
import RussianMessages from './locales/ru.json'

type MessagesKeys = keyof typeof RussianMessages

type LangConfig = {
  [key in Language]: { name: string; icon: FlagComponent; messages: typeof RussianMessages }
}

const langConfig: LangConfig = {
  [Language.RU]: {
    name: 'Русский',
    icon: RU,
    messages: RussianMessages,
  },
  [Language.EN]: {
    name: 'English',
    icon: GB,
    messages: EnglishMessages,
  },
}
const langList = Object.entries(langConfig).map(([code, { name, icon }]) => ({
  code,
  name,
  icon,
})) as Array<{ code: Language; name: string; icon: React.ElementType<SvgIconProps> }>

const updateIntlStore = domain.createEvent<IntlShape>('updateIntl')

const cache = createIntlCache()

const initLang = Storage.get<Language>(STORAGE_NAMES.LANG)
const initIntl: IntlShape = createIntl(
  {
    locale: initLang || Language.RU,
    messages: langConfig[initLang || Language.RU].messages,
  },
  cache
)

const $intl = domain.createStore<IntlShape>(initIntl).on(updateIntlStore, (_, data) => data)

export const updateIntl = (newLocale: Language) => {
  const newIntl = createIntl({ locale: newLocale, messages: langConfig[newLocale].messages }, cache)

  Storage.set(STORAGE_NAMES.LANG, newLocale)

  updateIntlStore(newIntl)
}

type I18nArgs = {
  id: MessagesKeys
  values?: Record<string, PrimitiveType>
}

const I18nProvider = ({ children }: { children: React.ReactNode }) => {
  const intlStore = useStore($intl)

  return <IntlProvider {...intlStore}>{children}</IntlProvider>
}

const i18n = (id: MessagesKeys, values?: Record<string, PrimitiveType>): string => {
  const intlStore = $intl.getState()
  const defaultMessage = getLastSegment(id)

  return intlStore.formatMessage({ id, defaultMessage }, values)
}

const I18n = ({ id, values }: I18nArgs) => {
  const defaultMessage = getLastSegment(langConfig.ru.messages[id])

  return <FormattedMessage id={id} values={values} defaultMessage={defaultMessage} />
}

export { I18n, I18nProvider, Language, i18n, langConfig, langList }
export type { MessagesKeys }
