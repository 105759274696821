import { ROUTE_PATH } from '@/packages/paths'
import { I18n } from '@/services/i18n'
import { IconName } from '@/shared/components/icon'

interface MenuItem {
  path: string
  text: JSX.Element
  icon: IconName
}

export const navigationItems: MenuItem[] = [
  { path: ROUTE_PATH.CALENDAR, text: <I18n id='sidebar.item.calendar' />, icon: 'CalendarMonth' },
  { path: ROUTE_PATH.MEMORIES, text: <I18n id='sidebar.item.memories' />, icon: 'PendingActions' },
  { path: ROUTE_PATH.HOME, text: <I18n id='sidebar.item.today' />, icon: 'Home' },
]
