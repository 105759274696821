import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import StarIcon from '@mui/icons-material/Star'

// Массив настроений
export const moodOptions = [
  {
    value: 'very_bad',
    label: 'Очень плохо',
    icon: <SentimentVeryDissatisfiedIcon sx={{ color: '#f44336' }} />,
  },
  {
    value: 'bad',
    label: 'Плохо',
    icon: <SentimentDissatisfiedIcon sx={{ color: '#ff9800' }} />,
  },
  {
    value: 'neutral',
    label: 'Обычно',
    icon: <SentimentNeutralIcon sx={{ color: '#9e9e9e' }} />,
  },
  {
    value: 'good',
    label: 'Хорошо',
    icon: <SentimentSatisfiedIcon sx={{ color: '#4caf50' }} />,
  },
  {
    value: 'great',
    label: 'Отлично',
    icon: <SentimentVerySatisfiedIcon sx={{ color: '#4caf50' }} />,
  },
  {
    value: 'special',
    label: 'Особенный день',
    icon: <StarIcon sx={{ color: '#ffd700' }} />,
  },
]
