import { useStore } from 'effector-react'

import { Choose, When } from '@/shared/utils/choose'

import { $singInByCodeMode, SingInByCodeMode } from './model'
import { SignInByCodeStep1 } from './ui/step1'
import { SignInByCodeStep2 } from './ui/step2'

export const SignInByCode = () => {
  const signInMode = useStore($singInByCodeMode)

  return (
    <Choose>
      <When condition={signInMode === SingInByCodeMode.STEP1}>
        <SignInByCodeStep1 />
      </When>
      <When condition={signInMode === SingInByCodeMode.STEP2}>
        <SignInByCodeStep2 />
      </When>
    </Choose>
  )
}
