import { Box, Menu, MenuItem } from '@mui/material'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { ColorTheme, themeConfig, themeList } from '@/services/theme'
import { $colorTheme, changeColorTheme } from '@/services/user'
import { Button } from '@/shared/components/button'
import { Icon } from '@/shared/components/icon'
import { Text } from '@/shared/components/text'

const THEME_MENU_ID = 'theme_menu_header'

export const ThemeMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const activeTheme = useStore($colorTheme)

  const handleClose = () => setAnchorEl(null)

  const onChangeTheme = (theme: ColorTheme) => {
    changeColorTheme({ theme })
    handleClose()
  }

  return (
    <>
      <Button aria-controls={THEME_MENU_ID} aria-haspopup='true' onClick={(event) => setAnchorEl(event?.currentTarget)}>
        {<Icon name={themeConfig[activeTheme].iconName} htmlColor='orange' />}
      </Button>
      <Menu id={THEME_MENU_ID} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {themeList.map(({ code, name, iconName, color }) => (
          <MenuItem key={code} onClick={() => onChangeTheme(code)} disabled={code === activeTheme}>
            <Box sx={{ width: 20, mr: 2 }}>
              <Icon name={iconName} sx={{ color }} />
            </Box>
            <Text>{name}</Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
