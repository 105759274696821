import RefreshIcon from '@mui/icons-material/Refresh' // Импортируем иконку обновления
import { Box, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import {
  $secretCode,
  $user,
  disableSecretCode,
  disableSecretCodeFx,
  getUserSecretCode,
  getUserSecretCodeFx,
  refreshSecretCode,
  refreshSecretCodeFx,
} from '@/services/user'
import { Clipboard } from '@/shared/components/clipboard'
import { Icon } from '@/shared/components/icon'
import { If } from '@/shared/utils/if'

export const SecretCodeSettings = () => {
  const user = useStore($user)
  const secretCode = useStore($secretCode)
  const isRefreshing = useStore(refreshSecretCodeFx.pending)
  const isDisabling = useStore(disableSecretCodeFx.pending)
  const isLoading = useStore(getUserSecretCodeFx.pending)

  useEffect(() => {
    getUserSecretCode()
  }, [])

  const handleRefreshCode = () => {
    refreshSecretCode()
  }

  const handleDisableCode = () => {
    disableSecretCode()
  }

  const toggleCode = () => {
    if (secretCode) {
      handleDisableCode()
    } else {
      handleRefreshCode()
    }
  }

  const isDisable = isRefreshing || isDisabling || isLoading

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Icon name='Security' sx={{ mr: 1, color: 'primary.main' }} />
        <Typography variant='h6' fontWeight='bold'>
          Секретный код
        </Typography>
      </Box>

      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box>
          <Typography variant='body1'>Текущий секретный код:</Typography>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }}>
              <CircularProgress size={24} />
            </Box>
          ) : secretCode ? (
            <Clipboard blurred viewText={secretCode} copyText={secretCode} showIcon withSnackbar />
          ) : (
            <Typography color='text.secondary'>Код не установлен</Typography>
          )}
        </Box>

        {/* Кнопка обновления кода */}
        {secretCode && (
          <IconButton onClick={handleRefreshCode} disabled={isDisable} sx={{ ml: 2 }}>
            {isRefreshing ? <CircularProgress size={24} color='inherit' /> : <RefreshIcon />}
          </IconButton>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
        <FormControlLabel
          control={<Switch checked={!!secretCode} onChange={toggleCode} disabled={isDisable || !user?.emailVerified} />}
          label={secretCode ? 'Отключить код' : 'Включить код'}
        />
        <If condition={!user?.emailVerified}>
          <Typography variant='body2' fontSize={12}>
            Можно будет отключить после подтверждения аккаунта
          </Typography>
        </If>
      </Box>
    </Box>
  )
}
