import { Box } from '@mui/material'

import { MemoryEntry } from '../../model'
import { MemoryItem } from '../memory-item'

interface MemoryListProps {
  memories: MemoryEntry[]
  onMemoryClick: (memory: MemoryEntry) => void
}

export const MemoryList: React.FC<MemoryListProps> = ({ memories, onMemoryClick }) => {
  // Разделяем воспоминания на первые три и остальные
  const firstThreeMemories = memories.slice(0, 3)
  const remainingMemories = memories.slice(3)

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={3}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' gap={3} mb={4}>
        {firstThreeMemories.map((memory, index) => (
          <MemoryItem key={index} memory={memory} onClick={() => onMemoryClick(memory)} />
        ))}
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' flexWrap='wrap' gap={4}>
        {remainingMemories.map((memory, index) => (
          <MemoryItem key={index + 3} memory={memory} onClick={() => onMemoryClick(memory)} large />
        ))}
      </Box>
    </Box>
  )
}
