import { STORAGE_NAMES } from './enum'

class StorageClass {
  get<T>(key: string): T | null {
    const storageData = localStorage.getItem(key)

    if (!storageData) return null

    try {
      return JSON.parse(storageData)
    } catch (err) {
      return null
    }
  }

  remove(key: string): void {
    localStorage.removeItem(key)
  }

  set<T>(key: string, value: T): void {
    try {
      const jsonValue = JSON.stringify(value)
      localStorage.setItem(key, jsonValue)
    } catch (err) {
      console.error(err)
    }
  }

  clear(): void {
    localStorage.clear()
  }

  removeExcept(keepKeys: STORAGE_NAMES[]): void {
    const allKeys = Object.values(STORAGE_NAMES)
    allKeys.forEach((key) => {
      if (!keepKeys.includes(key)) {
        localStorage.removeItem(key)
      }
    })
  }
}

const Storage = new StorageClass()

export { Storage, STORAGE_NAMES }
