import * as Icons from '@mui/icons-material'
import { SvgIconComponent } from '@mui/icons-material'
import { SxProps } from '@mui/material'
import React from 'react'

import { pascalCase } from '../../utils/toPascalCase'

import { IconName } from './types'

interface IconProps {
  name: string
  sx?: SxProps
  htmlColor?: string
  size?: 'small' | 'medium' | 'large' | number
}

export const Icon: React.FC<IconProps> = ({ name, sx, htmlColor, size }) => {
  const pascalName = pascalCase(name)
  const IconComponent: SvgIconComponent | undefined = Icons[pascalName as IconName]

  const isNumberFontSize = typeof size === 'number'

  if (!IconComponent) return null

  return (
    <IconComponent
      sx={{ ...sx, size: isNumberFontSize ? size : '' }}
      htmlColor={htmlColor}
      fontSize={isNumberFontSize ? 'inherit' : size}
    />
  )
}

export type { IconName }
