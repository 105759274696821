import { forward, guard, sample } from 'effector'

import { logout } from '@/services/api'
import { requestChangeEmail, requestChangeEmailFx } from '@/services/email'
import { verifyChangeEmail, verifyChangeEmailFx } from '@/services/user'

import { ChangeEmailByCodeMode } from './enum'
import { changeChangeEmailByCodeMode } from './events'
import { $ChangeEmailRequestCodeForm, $ChangeEmailVerifyEmailForm } from './forms'

forward({
  from: [verifyChangeEmailFx.done, logout],
  to: [
    $ChangeEmailVerifyEmailForm.reset,
    $ChangeEmailRequestCodeForm.reset,
    changeChangeEmailByCodeMode.prepend(() => ChangeEmailByCodeMode.NONE),
  ],
})

forward({
  from: requestChangeEmailFx.done,
  to: changeChangeEmailByCodeMode.prepend(() => ChangeEmailByCodeMode.STEP2),
})

forward({
  from: $ChangeEmailRequestCodeForm.formValidated,
  to: requestChangeEmail,
})

guard({
  source: changeChangeEmailByCodeMode,
  filter: (mode) => mode === ChangeEmailByCodeMode.NONE,
  target: [$ChangeEmailVerifyEmailForm.reset, $ChangeEmailRequestCodeForm.reset],
})

sample({
  clock: $ChangeEmailVerifyEmailForm.formValidated,
  fn: ({ newEmailCode, code }) => ({ code, newEmailCode }),
  target: verifyChangeEmail,
})
