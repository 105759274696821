// ./main/ui/edit-entry-modal/MoodSelect.tsx
import { Box, MenuItem, Select, Typography } from '@mui/material'

import { MoodIcon } from '@/shared/components/mood'

import { moodOptions } from './consts'

interface MoodSelectProps {
  value: string | null
  onChange: (value: string) => void
  disabled: boolean
}

export const MoodSelect: React.FC<MoodSelectProps> = ({ value, onChange, disabled }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Typography sx={{ mr: 2 }}>Настроение:</Typography>
      <Select
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        disabled={disabled}
        variant='outlined'
        size='small'
        sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
      >
        <MenuItem value='' disabled>
          Не указано настроение
        </MenuItem>
        {moodOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <MoodIcon mood={option.value} />
            <Box sx={{ mr: 1 }} />
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
