import { useStore } from 'effector-react'

import { Choose, When } from '@/shared/utils/choose'

import { $changeEmailByCodeMode, ChangeEmailByCodeMode } from './model'
import { ChangeEmailByCodeStep1 } from './ui/step1'
import { ChangeEmailByCodeStep2 } from './ui/step2'

export const ChangeEmail = () => {
  const changeEmailMode = useStore($changeEmailByCodeMode)

  return (
    <Choose>
      <When condition={changeEmailMode === ChangeEmailByCodeMode.STEP1}>
        <ChangeEmailByCodeStep1 />
      </When>
      <When condition={changeEmailMode === ChangeEmailByCodeMode.STEP2}>
        <ChangeEmailByCodeStep2 />
      </When>
    </Choose>
  )
}
