import { createStore } from 'effector'

import { changeOnboardingStep } from '@/services/user'

import { changeOnboardingNextStep, changeOnboardingStepByName, closeSingleShow, startTour, stopTour } from './events'

// Хранилище для состояния, запущен ли тур
export const $isTourRunning = createStore<boolean>(false)
  .on(startTour, () => true)
  .on(stopTour, () => false)

// Хранилище для текущего шага
export const $currentStepIndex = createStore<number>(0)
  .on(changeOnboardingStep, (storeStep, { onboardingStep }) => (onboardingStep === -1 ? storeStep : onboardingStep))
  .on(changeOnboardingStepByName, (_, index) => index)
  .on(changeOnboardingNextStep, (step) => step + 1)

// Хранилище для одиночного показа
export const $isSingleShow = createStore<boolean>(false)
  .on(changeOnboardingStepByName, () => true)
  .reset(closeSingleShow)
