import { deleteUserFx } from '@/services/user'

import { domain } from '../../../domain'

import { changeModalVisible } from './events'

export const $modalVisible = domain
  .createStore<boolean>(false)
  .on(changeModalVisible, (_, visible) => visible)
  .reset(deleteUserFx.done)
