import { getMemoriesApi } from './api'
import { domain } from './domain'
import { DiaryError, GetMemoriesResponse } from './types'

// Событие для получения воспоминаний
export const getMemories = domain.createEvent<void>('getMemories')

// Эффект для получения воспоминаний
export const getMemoriesFx = domain
  .createEffect<void, GetMemoriesResponse, DiaryError>('getMemoriesFx')
  .use(getMemoriesApi)
