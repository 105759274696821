import { createDomain } from 'effector'

import { DOMAINS } from '@/shared/consts/domains'

export const SUB_DOMAIN = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  FAST_SIGN_UP: 'FAST_SIGN_UP',
}

export const domain = createDomain(DOMAINS.auth)
