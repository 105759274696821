import { styled } from '@mui/material'

export const Root = styled('div')({
  width: '100%',
  marginTop: '12px',
  position: 'relative',
  padding: '0px 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
