import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { motion, useAnimation } from 'framer-motion'

import { I18n } from '@/services/i18n'
import { signUpByCodeFx } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { Icon } from '@/shared/components/icon'
import { IconButton } from '@/shared/components/icon-button'
import { LoadingButton } from '@/shared/components/loading-button'
import { If } from '@/shared/utils/if'

import { $SignUpByUsernameForm, $signUpByUsernameError, $signUpByUsernameModal } from './model'
import { changeSignUpByUsernameModal } from './model/events'
import { generateUsername } from './model/utils'

export const SignUpByUsername = () => {
  const isOpen = useStore($signUpByUsernameModal)
  const { fields, submit, errorText } = useForm($SignUpByUsernameForm)
  const pending = useStore(signUpByCodeFx.pending)
  const error = useStore($signUpByUsernameError)

  const controls = useAnimation()
  const handleClick = () => {
    controls.start({ rotate: -360, transition: { duration: 0.3 } }).then(() => {
      fields.username.onChange(generateUsername())
      controls.start({ rotate: 0, transition: { duration: 0 } })
    })
  }

  return (
    <>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Button variant='outlined' size='large' onClick={() => changeSignUpByUsernameModal(true)}>
          <I18n id='features.auth.fast-sign-up.button' />
        </Button>
      </Box>
      <Dialog
        open={isOpen}
        onClose={() => changeSignUpByUsernameModal(false)}
        maxWidth='xs'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' sx={{ textAlign: 'center' }}>
          <I18n id='features.auth.fast-sign-up.modal.title' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <I18n id='features.auth.fast-sign-up.modal.description' />
          </DialogContentText>
          <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
            <TextField
              label={<I18n id='base.username' />}
              fullWidth
              disabled={pending}
              error={fields.username.hasError()}
              variant='standard'
              helperText={errorText('username')}
              onKeyDown={(e) => e.key === 'Enter' && submit()}
              value={fields.username.value}
              onChange={(e) => fields.username.onChange(e.target.value)}
            />
            <IconButton sx={{ ml: 2 }} onClick={handleClick}>
              <motion.div animate={controls} style={{ width: 24, height: 24 }}>
                <Icon name='Replay' />
              </motion.div>
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
          <Button onClick={() => changeSignUpByUsernameModal(false)} color='error'>
            <I18n id='base.cancel' />
          </Button>
          <LoadingButton sx={{ minWidth: 200 }} variant='contained' onClick={() => submit()} color='success' autoFocus>
            <I18n id='base.enter' />
          </LoadingButton>
        </DialogActions>
        <If condition={error}>
          <Box sx={{ margin: '0 8px 8px 8px' }}>
            <AlertError text={error} variant='filled' sx={{ mt: 2 }} />
          </Box>
        </If>
      </Dialog>
    </>
  )
}
