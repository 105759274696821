import { Alert, Snackbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { I18n } from '@/services/i18n'
import { Button } from '@/shared/components/button'
import { Text } from '@/shared/components/text'

import * as Styled from './styled'

export const BlockInvite = () => {
  const location = useLocation()
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    if (typeof window === 'undefined') return

    const fullUrl = `${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`
    setCurrentUrl(fullUrl)
  }, [location])

  const handleCopy = () => {
    navigator.clipboard.writeText(currentUrl)
    setSnackbarOpen(true)
  }

  return (
    <Styled.Root>
      <Text
        id='features.promo.block-invite.title'
        component='h1'
        textAlign='center'
        sx={{ fontSize: { xs: 28, sm: 40, md: 48 } }}
        lineHeight={1.1}
      />
      <Text id='features.promo.block-invite.description' sx={{ my: 3 }} fontSize={16} />
      <Button sx={{ my: 5 }} size='large' color='success' variant='contained' onClick={() => handleCopy()}>
        <I18n id='features.promo.block-invite.button' />
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Позиция уведомления
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success' sx={{ width: '100%' }}>
          <I18n id='features.promo.block-invite.invite-alert' />
        </Alert>
      </Snackbar>
    </Styled.Root>
  )
}
