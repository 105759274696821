import { forward, guard } from 'effector'

import { signIn, signInApi, signInByCodeFx, signInFx } from '@/services/user'

import { $SingInForm } from './forms'

signInFx.use(signInApi)

guard({
  source: signIn,
  filter: signInFx.pending.map((state) => !state),
  target: signInFx,
})

forward({
  from: [signInFx.done, signInByCodeFx.done],
  to: $SingInForm.reset,
})

forward({
  from: $SingInForm.formValidated,
  to: signIn,
})
