import { logout } from '@/services/api'
import { changePasswordFx } from '@/services/password'
import { setPasswordFx } from '@/services/user'

import { domain } from './domain'
import { PasswordMode } from './enum'
import { changePasswordMode } from './events'

export const $passwordMode = domain
  .createStore<PasswordMode>(PasswordMode.NONE)
  .on(changePasswordMode, (_, mode) => mode)
  .reset([setPasswordFx.done, changePasswordFx.done, logout])
