export const NAV_CLASSES = {
  APP: 'app',
  TODAY: {
    PAGE: 'page-today',
    FORM_INPUT: 'today-form-input',
    FORM_MOOD: 'today-form-mood',
  },
  SIDEBAR: {
    PROFILE: 'sidebar-profile',
    TODAY: 'sidebar-today',
    CALENDAR: 'sidebar-calendar',
    MEMORIES: 'sidebar-memories',
    STUDY: 'sidebar-study',
    HELP: 'sidebar-help',
    CHANGE_SETTINGS: 'change-settings',
  },
  MEMORIES: {
    PAGE: 'page-memories',
  },
  CALENDAR: {
    PAGE: 'page-calendar',
  },
  HELP: {
    PAGE: 'page-help',
  },
  PROFILE: {
    PAGE: 'page-profile',
    EMAIL: 'page-profile-email',
    PASSWORD: 'page-profile-password',
    USERNAME: 'page-profile-username',
    SECRET_CODE: 'page-profile-secret-code',
    DELETE: 'page-profile-delete-account',
  },
}
