import { Box, TextField } from '@mui/material'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { $isCodeRequested, $verifyEmailPending, requestCodeProfile, requestCodeProfileFx } from '@/services/email'
import { I18n } from '@/services/i18n'
import { $user, User, verifyEmail } from '@/services/user'
import { Icon } from '@/shared/components/icon'
import { LoadingButton } from '@/shared/components/loading-button'
import { Text } from '@/shared/components/text'
import { Choose, Otherwise, When } from '@/shared/utils/choose'

export const EmailSettingsConfirmForm = () => {
  const isCodeRequested = useStore($isCodeRequested)
  const user = useStore($user) as User
  const verifyEmailPending = useStore($verifyEmailPending)
  const requestVerifyCodePending = useStore(requestCodeProfileFx.pending)

  const [code, setCode] = useState('')

  return (
    <Choose>
      <When condition={!isCodeRequested}>
        <LoadingButton
          loading={requestVerifyCodePending}
          size='small'
          variant='contained'
          color='primary'
          onClick={() => requestCodeProfile()}
        >
          <I18n id='features.profile.ui.email_settings.ui.confirm-form.confirm' />
        </LoadingButton>
      </When>
      <Otherwise>
        <Text id='features.profile.ui.email_settings.ui.confirm-form.code_sent' sx={{ mb: 1, fontSize: 12 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            label={<I18n id='features.profile.ui.email_settings.ui.confirm-form.enter_code' />}
            type='number'
            disabled={verifyEmailPending}
            value={code}
            sx={{ mr: 2 }}
            onChange={(e) => setCode(e.target.value)}
          />
          <LoadingButton
            loading={verifyEmailPending}
            size='small'
            variant='contained'
            color='primary'
            onClick={() => verifyEmail({ code, email: user.email })}
          >
            <Icon name='Check' />
          </LoadingButton>
        </Box>
      </Otherwise>
    </Choose>
  )
}
