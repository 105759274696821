import { useStore } from 'effector-react'

import { Choose, When } from '@/shared/utils/choose'

import { SignUpMode } from './model'
import { $signUpMode } from './model/store'
import { SignUpByCode } from './ui/by-code'
import { SignUpByPassword } from './ui/by-password'

export const SignUp = () => {
  const signUpMode = useStore($signUpMode)

  return (
    <Choose>
      <When condition={signUpMode === SignUpMode.BY_PASSWORD}>
        <SignUpByPassword />
      </When>
      <When condition={signUpMode === SignUpMode.BY_CODE}>
        <SignUpByCode />
      </When>
    </Choose>
  )
}
