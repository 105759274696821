import { guard } from 'effector'

import { changeOnboardingStep, changeOnboardingStepFx } from '@/services/user'
import { navigate } from '@/shared/utils/navigate'

import { navigateTo, startTour } from './events'

// Связываем события с эффектами
guard({
  source: changeOnboardingStep,
  filter: changeOnboardingStepFx.pending.map((isRunning) => !isRunning),
  target: changeOnboardingStepFx, // Событие запуска
})

startTour.watch(() => {
  changeOnboardingStep({ onboardingStep: 0 })
})

navigateTo.watch((path) => {
  navigate(path)
})
