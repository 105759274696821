import { Box, Button, MenuItem, Select, TextField } from '@mui/material'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { I18n } from '@/services/i18n'
import { signUpFast, signUpFastFx } from '@/services/user'
import { MoodIcon } from '@/shared/components/mood'
import { Text } from '@/shared/components/text'

import * as Styled from './styled'

// Массив для настроений
const moodOptions = [
  { value: 'very_bad', label: 'Очень плохо' },
  { value: 'bad', label: 'Плохо' },
  { value: 'neutral', label: 'Нейтрально' },
  { value: 'good', label: 'Хорошо' },
  { value: 'great', label: 'Отлично' },
  { value: 'special', label: 'Особенное' },
]

export const BlockInteractive = () => {
  const [content, setContent] = useState('')
  const [mood, setMood] = useState<string | ''>('')
  const isSigningUp = useStore(signUpFastFx.pending)

  const handleSignUp = () => {
    signUpFast({ content, mood })
  }

  return (
    <Styled.Root>
      <Text
        id='features.promo.block-interactive.title'
        component='h2'
        textAlign='center'
        sx={{ fontSize: { xs: 28, sm: 32, md: 40 }, mb: 4 }}
        lineHeight={1.1}
      />

      {/* Текстовое поле для контента */}
      <TextField
        label='Напиши тут своё настроение!'
        value={content}
        onChange={(e) => setContent(e.target.value)}
        fullWidth
        multiline
        rows={8}
        sx={{ mb: 2 }}
      />

      {/* Селектор настроения */}
      <Select
        value={mood}
        onChange={(e) => setMood(e.target.value)}
        fullWidth
        displayEmpty
        sx={{
          mb: 3,
          '.MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <MenuItem value='' disabled>
          Выберите настроение
        </MenuItem>
        {moodOptions.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ display: 'flex', alignItems: 'center' }}>
            <MoodIcon mood={option.value} />
            <Box component='span' sx={{ ml: 1 }}>
              {option.label}
            </Box>
          </MenuItem>
        ))}
      </Select>

      <Button
        sx={{ mb: 5 }}
        size='large'
        color='success'
        variant='contained'
        onClick={handleSignUp}
        disabled={isSigningUp}
      >
        <I18n id='features.promo.block-interactive.button-go' />
      </Button>
    </Styled.Root>
  )
}
