import { useStore } from 'effector-react'

import { Choose, When } from '@/shared/utils/choose'

import { SingUpByCodeMode } from './model/enum'
import { $singUpByCodeMode } from './model/store'
import { SignUpByCodeStep1 } from './ui/step1'
import { SignUpByCodeStep2 } from './ui/step2'

export const SignUpByCode = () => {
  const signUpMode = useStore($singUpByCodeMode)

  return (
    <Choose>
      <When condition={signUpMode === SingUpByCodeMode.STEP1}>
        <SignUpByCodeStep1 />
      </When>
      <When condition={signUpMode === SingUpByCodeMode.STEP2}>
        <SignUpByCodeStep2 />
      </When>
    </Choose>
  )
}
