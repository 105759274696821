import { Template } from '../../templates/main'

import { HelpMain } from './ui/main'

export const HelpPage = () => {
  return (
    <Template withContainer={false}>
      <HelpMain />
    </Template>
  )
}

export default HelpPage
