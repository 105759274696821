import { Theme } from '@mui/material'

import { IconName } from '@/shared/components/icon'

import { i18n } from '../i18n'

import { ColorTheme } from './enum'
import { darkTheme } from './themes/dark'
import { lightTheme } from './themes/light'

type ThemeConfig = {
  [key in ColorTheme]: { name: string; iconName: IconName; theme: Theme; color: string }
}

const themeConfig: ThemeConfig = {
  [ColorTheme.LIGHT]: {
    name: i18n('theme.light'),
    iconName: 'WbSunny',
    color: 'orange',
    theme: lightTheme,
  },
  [ColorTheme.DARK]: {
    name: i18n('theme.dark'),
    iconName: 'NightsStay',
    color: 'text.primary',
    theme: darkTheme,
  },
}

const themeList = Object.entries(themeConfig).map(([code, { name, iconName, color }]) => ({
  code,
  name,
  iconName,
  color,
})) as Array<{ code: ColorTheme; name: string; iconName: IconName; color: string }>

if (typeof window !== 'undefined') {
  // @ts-ignore light theme for window
  window.lightTheme = lightTheme
  // @ts-ignore dark theme for window
  window.darkTheme = darkTheme
}

const getTheme = (colorTheme: ColorTheme): Theme => {
  return themeConfig[colorTheme].theme
}

export { ColorTheme, getTheme, themeConfig, themeList }
