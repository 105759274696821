import { Container, styled } from '@mui/material'

export const Root = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
})

export const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})
