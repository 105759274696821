import { Card, CardActions, CardContent, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { $userError, signInByCodeFx } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { Text } from '@/shared/components/text'
import { If } from '@/shared/utils/if'

import { $SignInRequestCodeForm, $SignInVerifyEmailForm, $signInByCodeError, SingInByCodeMode } from '../../model'
import { changeSingInByCodeMode, resetErrors } from '../../model/events'

import * as Styled from './styled'

export const SignInByCodeStep2 = () => {
  const signInError = useStore($signInByCodeError)
  const signInPending = useStore(signInByCodeFx.pending)
  const userError = useStore($userError)

  const {
    fields: { email },
    reset: resetSignInRequestCodeForm,
  } = useForm($SignInRequestCodeForm)
  const { fields, submit, errorText } = useForm($SignInVerifyEmailForm)

  return (
    <Card sx={{ minWidth: 250 }}>
      <CardContent>
        <Text
          id='features.auth.sign-in.title'
          sx={{ fontSize: 24, textAlign: 'center', mb: 1 }}
          color='text.secondary'
          gutterBottom
        />
        <Styled.Fields>
          <TextField variant='standard' label={<I18n id='base.email' />} autoFocus disabled value={email.value} />
          <TextField
            label={<I18n id='base.code' />}
            disabled={signInPending}
            error={fields.code.hasError()}
            variant='standard'
            helperText={errorText('code')}
            onKeyDown={(e) => e.key === 'Enter' && submit()}
            value={fields.code.value}
            onChange={(e) => fields.code.onChange(e.target.value)}
          />
        </Styled.Fields>
      </CardContent>
      <CardActions sx={{ flexDirection: 'column' }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          loading={signInPending}
          onClick={() => submit()}
        >
          <I18n id='features.auth.sign_in.signin' />
        </LoadingButton>
        <Button
          sx={{ width: '100%' }}
          color='secondary'
          onClick={() => {
            resetErrors()
            resetSignInRequestCodeForm()
            changeSingInByCodeMode(SingInByCodeMode.STEP1)
          }}
        >
          <I18n id='base.cancel' />
        </Button>
      </CardActions>
      <Styled.Errors>
        <If condition={signInError || userError}>
          <AlertError text={signInError || userError} variant='filled' sx={{ mt: 2 }} />
        </If>
      </Styled.Errors>
    </Card>
  )
}
