import { guard } from 'effector'

import { setPassword, setPasswordFx } from '../user'

import * as api from './api'
import { changePassword, changePasswordFx, requestPasswordCode, requestPasswordCodeFx } from './events'

// request password code

requestPasswordCodeFx.use(api.requestPasswordCodeApi)

guard({
  source: requestPasswordCode,
  filter: requestPasswordCodeFx.pending.map((state) => !state),
  target: requestPasswordCodeFx,
})

// request change password

changePasswordFx.use(api.changePasswordApi)

guard({
  source: changePassword,
  filter: changePasswordFx.pending.map((state) => !state),
  target: changePasswordFx,
})

// request set password

setPasswordFx.use(api.setPasswordApi)

guard({
  source: setPassword,
  filter: setPasswordFx.pending.map((state) => !state),
  target: setPasswordFx,
})
