import { forward, sample } from 'effector'

import { logout } from '@/services/api'
import { requestCodeNewEmail, requestCodeNewEmailFx } from '@/services/email'
import { verifyEmail, verifyEmailFx } from '@/services/user'

import { NewEmailByCodeMode } from './enum'
import { changeNewEmailByCodeMode } from './events'
import { $NewEmailRequestCodeForm, $NewEmailVerifyEmailForm } from './forms'

forward({
  from: [verifyEmailFx.done, logout],
  to: [$NewEmailVerifyEmailForm.reset, $NewEmailRequestCodeForm.reset],
})

forward({
  from: requestCodeNewEmailFx.done,
  to: changeNewEmailByCodeMode.prepend(() => NewEmailByCodeMode.STEP2),
})

forward({
  from: $NewEmailRequestCodeForm.formValidated,
  to: requestCodeNewEmail,
})

sample({
  source: $NewEmailRequestCodeForm.$values,
  clock: $NewEmailVerifyEmailForm.formValidated,
  fn: ({ email }, { code }) => ({ code, email }),
  target: verifyEmail,
})
