import { styled } from '@mui/material'

export const Root = styled('div')({
  width: '100%',
  marginTop: '20px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const ModalBox = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  boxShadow: theme.shadows[5],
  borderRadius: '8px',
}))
