import { Box } from '@mui/material'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { logout } from '@/services/api'
import { $secretCode, $user, getUserSecretCode } from '@/services/user'
import { Button } from '@/shared/components/button'
import { Icon } from '@/shared/components/icon'
import { NAV_CLASSES } from '@/shared/consts/onboarding'

import { LangMenu } from '../../../lang'
import { ThemeMenu } from '../../../theme'

import * as Styled from './styled'
import { LogoutConfirmationDialog } from './ui/logout-modal'

export const BottomBlock = () => {
  const [openModal, setOpenModal] = useState(false)
  const secretCode = useStore($secretCode)
  const user = useStore($user)

  const handleOpen = () => {
    if (!user?.emailVerified && !secretCode) {
      getUserSecretCode()
    }
    setOpenModal(true)
  }

  const handleClose = () => setOpenModal(false)

  const handleLogout = () => {
    logout()
    handleClose()
  }

  return (
    <Styled.Container className={NAV_CLASSES.SIDEBAR.CHANGE_SETTINGS}>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-around', my: 1 }}>
        <LangMenu />
        <ThemeMenu />
        <Button onClick={handleOpen}>
          <Icon name='Logout' sx={{ color: 'error.main' }} />
        </Button>
      </Box>

      {/* Модальное окно подтверждения выхода */}
      <LogoutConfirmationDialog
        open={openModal}
        secretCode={secretCode}
        emailVerified={Boolean(user?.emailVerified)}
        onClose={handleClose}
        onLogout={handleLogout}
      />
    </Styled.Container>
  )
}
