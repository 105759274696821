import { forward, guard, sample } from 'effector'

import { requestCodeSignUp, requestCodeSignUpFx } from '@/services/email'
import { signUpByCode, signUpByCodeApi, signUpByCodeFx } from '@/services/user'

import { SingUpByCodeMode } from './enum'
import { changeSingUpByCodeMode } from './events'
import { $SignUpRequestCodeForm, $SignUpVerifyEmailForm } from './forms'

signUpByCodeFx.use(signUpByCodeApi)

guard({
  source: signUpByCode,
  filter: signUpByCodeFx.pending.map((state) => !state),
  target: signUpByCodeFx,
})

forward({
  from: signUpByCodeFx.done,
  to: [$SignUpRequestCodeForm.reset, $SignUpVerifyEmailForm.reset],
})

forward({
  from: requestCodeSignUpFx.done,
  to: changeSingUpByCodeMode.prepend(() => SingUpByCodeMode.STEP2),
})

forward({
  from: $SignUpRequestCodeForm.formValidated,
  to: requestCodeSignUp,
})

sample({
  source: $SignUpRequestCodeForm.$values,
  clock: $SignUpVerifyEmailForm.formValidated,
  fn: ({ email }, { code }) => ({ email, code }),
  target: signUpByCode,
})
