import { createTheme } from '@mui/material'

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#7b1fa2', // Более темный и насыщенный фиолетовый
    },
    secondary: {
      main: '#ba68c8', // Насыщенный светло-фиолетовый
    },
    error: {
      main: '#d32f2f', // Насыщенный красно-розовый
    },
    background: {
      default: '#ede7f6', // Бледно-фиолетовый для основного фона, гармонирующий с основным цветом
      paper: '#f5f5f5', // Светлый фиолетовый для фона элементов интерфейса
    },
    text: {
      primary: '#212121', // Почти черный для основного текста
      secondary: '#757575', // Темно-серый для вторичного текста
    },
  },
})
