import { Box, Typography } from '@mui/material'

import { Icon } from '@/shared/components/icon'

export const PasswordSettingsHeader = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Icon name='Lock' sx={{ mr: 1, color: 'primary.main' }} />
      <Typography variant='h6' fontWeight='bold'>
        Настройки пароля
      </Typography>
    </Box>
  )
}
