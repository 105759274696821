import { Template } from '../../templates/main'

import { Profile } from './ui/main'

export const ProfilePage = () => {
  return (
    <Template withContainer={false}>
      <Profile />
    </Template>
  )
}

export default ProfilePage
