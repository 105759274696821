import { Alert, styled } from '@mui/material'

export const Root = styled(Alert)(({}) => ({
  '.MuiAlert-icon': {
    display: 'flex',
    alignItems: 'center',
  },
  '.MuiAlert-message': {
    alignItems: 'center',
    width: '100%',
    display: 'flex',
  },
}))

export const CircularWrapper = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
