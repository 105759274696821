import { Box } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect, useState } from 'react'

import { $user } from '@/services/user'
import { NAV_CLASSES } from '@/shared/consts/onboarding'
import { If } from '@/shared/utils/if'

import { $calendar, DayStatus, getMonthEntries, getMonthEntriesFx } from './model'
import { CalendarGrid } from './ui/calendar-grid'
import { CalendarHeader } from './ui/calendar-header'
import { EditEntryDialog } from './ui/edit-entry-modal'
import { MonthYearSwitcher } from './ui/month-year-switcher'
import { WeekDaysHeader } from './ui/week-days-header'
import { WelcomeMessage } from './ui/welcome-message'

export const Calendar: React.FC = () => {
  const calendarCache = useStore($calendar)
  const isLoading = useStore(getMonthEntriesFx.pending)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [selectedDay, setSelectedDay] = useState<DayStatus | null>(null)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const user = useStore($user)

  const cacheKey = `${year}-${month}`
  const calendar = calendarCache[cacheKey]

  const firstDayOfMonth = new Date(year, month - 1, 1).getDay()
  const emptyDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1

  useEffect(() => {
    getMonthEntries({ year, month })
  }, [year, month])

  const handleDayClick = (day: DayStatus) => {
    setSelectedDay(day)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedDay(null)
  }

  const isUserJustJoined = user?.dateJoined
    ? new Date(user.dateJoined).toDateString() === new Date().toDateString()
    : false

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }} className={NAV_CLASSES.CALENDAR.PAGE}>
      <CalendarHeader />

      {isUserJustJoined ? (
        <WelcomeMessage />
      ) : (
        <>
          <MonthYearSwitcher year={year} month={month} onYearChange={setYear} onMonthChange={setMonth} />
          <WeekDaysHeader />
          <CalendarGrid calendar={calendar} emptyDays={emptyDays} isLoading={isLoading} onDayClick={handleDayClick} />
          <If condition={selectedDay}>
            <EditEntryDialog open={isDialogOpen} onClose={handleCloseDialog} day={selectedDay as DayStatus} />
          </If>
        </>
      )}
    </Box>
  )
}
