export const DOMAINS = {
  header: 'header',
  auth: 'auth',
  user: 'user',
  sidebar: 'sidebar',
  profile: 'profile',
  api: 'api',
  email: 'email',
  password: 'password',
  localization: 'localization',
  mainWelcome: 'mainWelcome',
  todayDiaryEntry: 'todayDiaryEntry',
  memories: 'memories',
  calendar: 'calendar',
  onboarding: 'onboarding',
  feedback: 'feedback',
  payments: 'payments',
  paymentStatus: 'payment-status',
}
