import { signInByCodeFx, signInFx } from '@/services/user'

import { domain } from './domain'
import { SignInMode } from './enum'
import { changeSignInMode } from './events'

export const $signInMode = domain
  .createStore<SignInMode>(SignInMode.BY_PASSWORD)
  .on(changeSignInMode, (_, mode) => mode)
  .reset([signInByCodeFx.done, signInFx.done])
