import { Storage, STORAGE_NAMES } from '@/services/local-storage'

class TokenServiceClass {
  getRefreshToken(): string | null {
    return Storage.get(STORAGE_NAMES.REFRESH_TOKEN_NAME)
  }

  getAccessToken(): string | null {
    return Storage.get(STORAGE_NAMES.ACCESS_TOKEN_NAME)
  }

  setTokens(access: string, refresh?: string): void {
    Storage.set(STORAGE_NAMES.ACCESS_TOKEN_NAME, access)
    if (refresh) {
      Storage.set(STORAGE_NAMES.REFRESH_TOKEN_NAME, refresh)
    }
  }

  removeTokens(): void {
    Storage.remove(STORAGE_NAMES.ACCESS_TOKEN_NAME)
    Storage.remove(STORAGE_NAMES.REFRESH_TOKEN_NAME)
  }
}

export const TokenService = new TokenServiceClass()
