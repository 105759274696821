import { Box } from '@mui/material'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { Text } from '@/shared/components/text'

import logoSrc from './assets/logo.svg'

type Props = {
  textColor?: string
  logoSize?: number
}

export const Logo = ({ textColor = 'text.primary', logoSize = 34 }: Props) => {
  return (
    <RouterLink to={ROUTE_PATH.HOME}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          my: '16px',
          px: '4px',
        }}
      >
        <Box component='img' src={logoSrc} sx={{ mr: 2, width: logoSize, height: logoSize }} />
        <Text
          id='title'
          fontSize={16}
          lineHeight={1.1}
          component='div'
          sx={{ width: 'min-content' }}
          color={textColor}
        />
      </Box>
    </RouterLink>
  )
}
