// ./events.ts
import { sendFeedbackApi } from './api'
import { feedbackDomain } from './domain'
import { FeedbackError, SubmitFeedbackParams, SubmitFeedbackResponse } from './types'

// События открытия/закрытия модального окна
export const openFeedbackModal = feedbackDomain.createEvent<void>('openFeedbackModal')
export const closeFeedbackModal = feedbackDomain.createEvent<void>('closeFeedbackModal')

// Событие отправки обратной связи
export const submitFeedback = feedbackDomain.createEvent<SubmitFeedbackParams>('submitFeedback')

// Эффект для отправки обратной связи на сервер
export const submitFeedbackFx = feedbackDomain
  .createEffect<SubmitFeedbackParams, SubmitFeedbackResponse, FeedbackError>('submitFeedbackFx')
  .use(sendFeedbackApi)
