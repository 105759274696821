import { useEffect } from 'react'
import { NavigateFunction, NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom'

type NavigationContextType = {
  to: (to: To, options?: NavigateOptions) => void
  currentPath: string
  setCurrentPath: (path: string) => void
  setNavigate: (nav: NavigateFunction) => void
}

const Navigation: NavigationContextType = {
  to: () => {
    console.error('Navigation.to not initialize')
  },
  currentPath: '/',
  setCurrentPath(path: string) {
    this.currentPath = path
  },
  setNavigate(nav: NavigateFunction) {
    this.to = nav
  },
}

const useActivateNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    Navigation.setNavigate(navigate)
  }, [navigate])

  useEffect(() => {
    Navigation.setCurrentPath(location.pathname)
  }, [location])
}

export { Navigation, useActivateNavigation }
