import { Box, Typography } from '@mui/material'

interface CalendarHeaderProps {}

export const CalendarHeader: React.FC<CalendarHeaderProps> = () => (
  <Box sx={{ textAlign: 'center', mb: 3 }}>
    <Typography variant='h4' color='text.primary'>
      Календарь
    </Typography>
  </Box>
)
