import { useMediaQuery, useTheme } from '@mui/material'

type MediaQueryResult = {
  isDesktop: boolean
  isMobile: boolean
  isTablet: boolean
  isMobileOrTablet: boolean
}

export const useMedia = (): MediaQueryResult => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const isMobileOrTablet = isMobile || isTablet
  const isDesktop = !isMobileOrTablet

  return { isDesktop, isMobile, isTablet, isMobileOrTablet }
}
