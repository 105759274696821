import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import { Typography } from '@mui/material'

import * as Styled from './styled'

export const EmptyMemoriesMessage: React.FC = () => {
  return (
    <Styled.StyledEmptyMessageContainer>
      <CreateOutlinedIcon sx={{ fontSize: 64, color: '#6a1b9a' }} />
      <Typography variant='h5' sx={{ color: '#6a1b9a', fontWeight: 'bold' }}>
        Скоро тут будут ваши воспоминания!
      </Typography>
      <Typography variant='body1' sx={{ color: '#555', maxWidth: '400px', lineHeight: 1.5 }}>
        Записывай свои воспоминания ежедневно, и они будут появляться здесь. Начни сегодня, чтобы наполнить это место
        своими лучшими моментами! Создавай историю, к которой всегда приятно вернуться!
      </Typography>
    </Styled.StyledEmptyMessageContainer>
  )
}
