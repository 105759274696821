import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { TooltipRenderProps } from 'react-joyride'

import { closeSingleShow } from '../../model/events'

export const SingleTooltip: React.FC<TooltipRenderProps> = ({ step, tooltipProps }) => {
  return (
    <Box
      {...tooltipProps}
      sx={{ padding: 3, backgroundColor: '#ffffff', borderRadius: 2, boxShadow: 3, maxWidth: 400 }}
    >
      <Typography variant='h6' sx={{ mb: 1 }}>
        {step.title}
      </Typography>
      <Typography variant='body1' sx={{ mb: 2 }}>
        {step.content}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => closeSingleShow()} variant='text' color='secondary'>
          Закрыть
        </Button>
      </Box>
    </Box>
  )
}
