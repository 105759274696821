import { styled } from '@mui/material'

export const Fields = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > input:not(:last-child)': {
    marginBottom: '440px',
  },
})

export const Errors = styled('div')({
  margin: '0 8px 8px 8px',
})
