import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { CardContent, Typography } from '@mui/material'

import { MemoryEntry } from '../../model'

import * as Styled from './styled'
import { calculatePeriodLabel, getMoodIcon } from './utils'

interface MemoryItemProps {
  memory: MemoryEntry
  onClick: () => void
  large?: boolean
}

export const MemoryItem: React.FC<MemoryItemProps> = ({ memory, onClick, large = false }) => {
  const hasContent = Boolean(memory.content)
  const periodLabel = calculatePeriodLabel(memory.date, memory.period)
  const moodIconSize = large ? 60 : 50

  return (
    <Styled.StyledCard onClick={hasContent ? onClick : undefined} hasContent={hasContent} large={large}>
      <CardContent sx={{ textAlign: 'center' }}>
        {hasContent ? (
          getMoodIcon(memory.mood || '', moodIconSize)
        ) : (
          <HighlightOffIcon sx={{ fontSize: large ? 60 : 50, color: '#9e9e9e' }} />
        )}
        <Typography variant='h6' sx={{ mt: 2, color: hasContent ? '#4caf50' : '#9e9e9e', userSelect: 'none' }}>
          {periodLabel}
        </Typography>
      </CardContent>
    </Styled.StyledCard>
  )
}
