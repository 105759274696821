import { Box, Chip } from '@mui/material'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { IconButton } from '@/shared/components/icon-button'
import { Text } from '@/shared/components/text'
import { Choose, Otherwise, When } from '@/shared/utils/choose/'
import { If } from '@/shared/utils/if'

import { $changeEmailByCodeMode, ChangeEmailByCodeMode } from '../change-email/model'
import { changeChangeEmailByCodeMode } from '../change-email/model/events'

type Props = {
  email: string
  emailVerified: boolean
}

export const EmailSettingsInfo = ({ email, emailVerified }: Props) => {
  const changeEmailMode = useStore($changeEmailByCodeMode)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'center' }}>
      <If condition={emailVerified}>
        <If condition={changeEmailMode === ChangeEmailByCodeMode.NONE}>
          <IconButton
            iconName='Edit'
            iconSize={16}
            sx={{ mr: 1 }}
            onClick={() => changeChangeEmailByCodeMode(ChangeEmailByCodeMode.STEP1)}
          />
        </If>
        <If condition={changeEmailMode !== ChangeEmailByCodeMode.NONE}>
          <IconButton
            iconName='Close'
            iconSize={16}
            sx={{ mr: 1, color: 'error.main' }}
            onClick={() => changeChangeEmailByCodeMode(ChangeEmailByCodeMode.NONE)}
          />
        </If>
      </If>
      <Text>{email}</Text>
      <Choose>
        <When condition={!emailVerified}>
          <Chip
            label={<I18n id='features.profile.ui.email_settings.ui.info.need_confirm' />}
            size='small'
            color='warning'
            sx={{ ml: 1 }}
          />
        </When>
        <Otherwise>
          <Chip
            label={<I18n id='features.profile.ui.email_settings.ui.info.confirmed' />}
            size='small'
            color='success'
            sx={{ ml: 1 }}
          />
        </Otherwise>
      </Choose>
    </Box>
  )
}
