import ReactDOM from 'react-dom/client'

import { App } from '@/packages/app'

const startApp = () => {
  const rootElement = document.getElementById('root')

  if (!rootElement) return

  if (rootElement.hasChildNodes()) {
    // Если есть серверный рендер, используем hydrate для инициализации
    ReactDOM.hydrateRoot(rootElement, <App />)
  } else {
    // Если нет, используем обычный render
    ReactDOM.createRoot(rootElement).render(<App />)
  }
}

startApp()
