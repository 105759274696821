import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { changeDrawerMenu } from '../../model/menu'

export const useCloseDrawerOnRouteChange = () => {
  const location = useLocation()

  useEffect(() => {
    // Закрываем боковое меню при изменении URL
    setTimeout(() => {
      changeDrawerMenu(false)
    }, 0)
  }, [location])
}
