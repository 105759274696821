import Typography, { TypographyProps } from '@mui/material/Typography'
import React from 'react'

import { I18n, MessagesKeys } from '@/services/i18n'

interface PropsWithId extends TypographyProps {
  id: MessagesKeys
  children?: React.ReactNode
}

interface PropsWithChildren extends TypographyProps {
  id?: MessagesKeys
  children: React.ReactNode
}

type Props = PropsWithId | PropsWithChildren

export const Text: React.FC<Props> = ({ id, children, ...props }) => {
  return (
    <Typography color='text.primary' {...props}>
      {id && <I18n id={id} />} {children}
    </Typography>
  )
}
