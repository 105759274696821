import { TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { requestCodeNewEmailFx } from '@/services/email'
import { I18n } from '@/services/i18n'
import { $userError } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { If } from '@/shared/utils/if'

import { $NewEmailRequestCodeForm, $requestCodeNewEmailError, NewEmailByCodeMode } from '../../model'
import { changeNewEmailByCodeMode } from '../../model/events'

export const NewEmailByCodeStep1 = () => {
  const requestError = useStore($requestCodeNewEmailError)
  const requestCodePending = useStore(requestCodeNewEmailFx.pending)
  const userError = useStore($userError)

  const { fields, submit, errorText } = useForm($NewEmailRequestCodeForm)

  return (
    <>
      <TextField
        label={<I18n id='features.email-settings.new-email.set-email' />}
        fullWidth
        size='small'
        variant='standard'
        disabled={requestCodePending}
        error={fields.email.hasError()}
        helperText={errorText('email')}
        value={fields.email.value}
        onKeyDown={(e) => e.key === 'Enter' && submit()}
        onChange={(e) => fields.email.onChange(e.target.value)}
      />
      <LoadingButton
        sx={{ width: '100%', my: 2 }}
        size='small'
        variant='contained'
        loading={requestCodePending}
        onClick={() => {
          submit()
        }}
      >
        <I18n id='features.auth.ui.sign-up.without_pass.base.request_code' />
      </LoadingButton>
      <Button
        size='small'
        color='error'
        variant='contained'
        sx={{ mt: 1 }}
        onClick={() => changeNewEmailByCodeMode(NewEmailByCodeMode.NONE)}
      >
        <I18n id='base.cancel' />
      </Button>
      <If condition={requestError || userError}>
        <AlertError text={requestError || userError} variant='filled' sx={{ mt: 2 }} />
      </If>
    </>
  )
}
