import { createStore } from 'effector'

import { logout } from '@/services/api'

import { getDiaryEntryFx, getMonthEntriesFx, resetDiaryEntry, updateDiaryEntryFx } from './events'
import { DayStatus } from './types'

// Тип данных для кэша
interface CalendarCache {
  [key: string]: DayStatus[]
}

// Хранилище для данных календаря с кэшем
export const $calendar = createStore<CalendarCache>({})
  .on(getMonthEntriesFx.done, (state, { params, result }) => {
    const cacheKey = `${params.year}-${params.month}`

    return {
      ...state,
      [cacheKey]: result.data,
    }
  })
  .on(updateDiaryEntryFx.done, (state, { params }) => {
    const { date, content, mood } = params
    const [year, month] = date.split('-').map(Number)
    const cacheKey = `${year}-${month}`

    // Получаем список дней для указанного месяца
    const monthDays = state[cacheKey] || []

    // Обновляем день, соответствующий обновленной записи
    const updatedDays = monthDays.map((day) => {
      if (day.date === date) {
        return {
          ...day,
          content,
          mood,
          status: 'filled' as const,
        }
      }

      return day
    })

    return {
      ...state,
      [cacheKey]: updatedDays,
    }
  })
  .reset(logout)

// Новый стор для хранения выбранного дня и его данных
export const $selectedDiaryEntry = createStore<DayStatus | null>(null)
  .on(getDiaryEntryFx.doneData, (_, { data }) => data)
  .reset(getDiaryEntryFx.fail, getDiaryEntryFx.done, resetDiaryEntry, logout)
