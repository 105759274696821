import { Box, Card, CardActions, CardContent, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { $userAuthPending, $userError } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { Text } from '@/shared/components/text'
import { If } from '@/shared/utils/if'

import { SignInSecretCode } from '../../../sign-in-secret-code'
import { changeSignInMode, SignInMode } from '../../model'

import { $signInError, $SingInForm } from './model'
import * as Styled from './styled'

export const SignInByPassword = () => {
  const signInError = useStore($signInError)
  const userError = useStore($userError)
  const userAuthPending = useStore($userAuthPending)

  const { fields, submit, errorText } = useForm($SingInForm)

  return (
    <Card sx={{ minWidth: 250 }}>
      <CardContent>
        <Text
          id='features.auth.ui.sign-in.title'
          sx={{ fontSize: 24, textAlign: 'center', mb: 1 }}
          color='text.secondary'
          gutterBottom
        />
        <Styled.Fields>
          <TextField
            label={<I18n id='features.auth.ui.sign-in.field_login_label' />}
            autoFocus
            variant='standard'
            disabled={userAuthPending}
            error={fields.usernameOrEmail.hasError()}
            helperText={errorText('usernameOrEmail')}
            value={fields.usernameOrEmail.value}
            onKeyDown={(e) => e.key === 'Enter' && submit()}
            onChange={(e) => fields.usernameOrEmail.onChange(e.target.value)}
          />
          <TextField
            label={<I18n id='base.password' />}
            type='password'
            error={fields.password.hasError()}
            disabled={userAuthPending}
            variant='standard'
            helperText={errorText('password')}
            onKeyDown={(e) => e.key === 'Enter' && submit()}
            value={fields.password.value}
            onChange={(e) => fields.password.onChange(e.target.value)}
          />
        </Styled.Fields>
      </CardContent>
      <CardActions sx={{ flexDirection: 'column' }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          loading={userAuthPending}
          onClick={() => submit()}
        >
          <I18n id='features.auth.ui.sign-in.enter_button' />
        </LoadingButton>
        <Button sx={{ width: '100%' }} color='secondary' onClick={() => changeSignInMode(SignInMode.BY_CODE)}>
          <I18n id='features.auth.sign_in.enter_without_pass' />
        </Button>
        <Box sx={{ mt: 1 }}>
          <SignInSecretCode />
        </Box>
      </CardActions>
      <Styled.Errors>
        <If condition={signInError || userError}>
          <AlertError text={signInError || userError} variant='filled' sx={{ mt: 2 }} />
        </If>
      </Styled.Errors>
    </Card>
  )
}
