import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { I18n } from '@/services/i18n'
import { $user, deleteUser, deleteUserFx } from '@/services/user'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'

import { $modalVisible, changeModalVisible } from './model'

export const DeleteUser = () => {
  const isVisibleModal = useStore($modalVisible)
  const pending = useStore(deleteUserFx.pending)
  const user = useStore($user)
  const [inputValue, setInputValue] = useState('')

  const isCorrectUsername = inputValue === user?.username

  return (
    <>
      <Button size='small' variant='contained' color='error' onClick={() => changeModalVisible(true)}>
        <I18n id='features.profile.delete-user.delete-button' />
      </Button>
      <Dialog open={isVisibleModal} onClose={() => changeModalVisible(false)}>
        <DialogTitle fontSize={28}>
          <I18n id='features.profile.delete-user.modal.title' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography component='span' color='error' fontSize={30} lineHeight={1.3}>
              <I18n id='features.profile.delete-user.modal.description' />
            </Typography>
          </DialogContentText>
          <TextField
            fullWidth
            variant='standard'
            label={'Ввести ник для подтверждения'}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            disabled={pending}
            variant='contained'
            sx={{ width: '70%' }}
            color='success'
            onClick={() => changeModalVisible(false)}
          >
            <I18n id='base.cancel' />
          </Button>
          <LoadingButton
            size='small'
            variant='contained'
            color='error'
            loading={pending}
            onClick={() => deleteUser()}
            disabled={!isCorrectUsername} // Кнопка будет активна только при правильном вводе
          >
            <I18n id='features.profile.delete-user.modal.delete_button' />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
