import { i18n } from '@/services/i18n'

export const errorMessages = {
  INCORRECT_SIGNIN_DATA: () => i18n('services.auth.model.sign_in.incorrect_data'),
  INCORRECT_OLD_PASSWORD: () => i18n('errors.password.incorrect_old'),
  EMAIL_BUSY: () => i18n('services.auth.model.sign_up.email_busy'),
  EMAIL_REQUIRED: () => i18n('services.auth.model.sign_up.email_is_required'),
  CODE_EXPIRED: () => i18n('services.auth.model.verify_email.code_expired'),
  INCORRECT_CODE: () => i18n('services.auth.model.verify_email.incorrect_code'),
  USERNAME_REQUIRED: () => i18n('errors.username_required'),
  PASSWORD_REQUIRED: () => i18n('errors.password.required'),
  USERNAME_BUSY: () => i18n('errors.username_busy'),
  EMAIL_CHANGE_CODE_NOT_FOUND: () => i18n('errors.email_change_code_not_found'),
  UNKNOWN_ERROR: () => i18n('errors.unknown_error'),
  USERNAME_HAS_INCORRECT_SYMBOLS: () => i18n('errors.username_has_incorrect_symbols'),
}
