import { forward, guard } from 'effector'

import { signUpByPassword, signUpByPasswordApi, signUpByPasswordFx } from '@/services/user'

import { $SignUpForm } from './forms'

signUpByPasswordFx.use(signUpByPasswordApi)

guard({
  source: signUpByPassword,
  filter: signUpByPasswordFx.pending.map((state) => !state),
  target: signUpByPasswordFx,
})

forward({
  from: signUpByPasswordFx.doneData,
  to: [$SignUpForm.reset],
})

forward({
  from: $SignUpForm.formValidated,
  to: signUpByPassword,
})
