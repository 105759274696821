import { Api } from '@/services/api'

import {
  ChangeColorThemeParams,
  ChangeColorThemeResponse,
  ChangeLangParams,
  ChangeLangResponse,
  ChangeUsernameParams,
  ChangeUsernameResponse,
  DeleteUserParams,
  DeleteUserResponse,
  DisableSecretCodeResponse,
  GetUserResponse,
  RefreshSecretCodeResponse,
  SecretCodeResponse,
  SignInByCodeParams,
  SignInBySecretCodeParams,
  SignInParams,
  SignUpByCodeParams,
  SignUpByPasswordParams,
  SignUpFastParams,
  UserResponse,
} from './types'

export const getUserApi = (): Promise<GetUserResponse> => {
  return Api.get('/user/get/')
}

export const getUserSecretCodeApi = (): Promise<SecretCodeResponse> => {
  return Api.get('/user/secret-code/')
}

export const disableSecretCodeApi = (): Promise<DisableSecretCodeResponse> => {
  return Api.post('/user/disable-secret-code/')
}

export const refreshSecretCodeApi = (): Promise<RefreshSecretCodeResponse> => {
  return Api.post('/user/refresh-secret-code/')
}

export const changeColorThemeApi = (params: ChangeColorThemeParams): Promise<ChangeColorThemeResponse> => {
  return Api.post('/settings/change_theme/', params)
}

export const changeLangApi = (params: ChangeLangParams): Promise<ChangeLangResponse> => {
  return Api.post('/settings/change_lang/', params)
}

export const signInApi = (params: SignInParams): Promise<UserResponse> => {
  return Api.post('/user/sign_in/', params)
}

export const signInByCodeApi = (params: SignInByCodeParams): Promise<UserResponse> => {
  return Api.post('/user/sign_in_by_code/', params)
}

export const signUpByPasswordApi = (params: SignUpByPasswordParams): Promise<UserResponse> => {
  return Api.post('/user/sign_up/', params)
}

export const signUpByCodeApi = (params: SignUpByCodeParams): Promise<UserResponse> => {
  return Api.post('/user/sign_up_by_code/', params)
}

export const signInBySecretCodeApi = (params: SignInBySecretCodeParams): Promise<UserResponse> => {
  return Api.post('/user/sign_in_by_secret_code/', params)
}

export const signUpFastApi = (params: SignUpFastParams): Promise<UserResponse> => {
  return Api.post('/user/sign_up_fast/', params)
}

export const deleteUserApi = (params: DeleteUserParams): Promise<DeleteUserResponse> => {
  return Api.post('/user/delete/', params)
}

export const changeUsernameApi = (params: ChangeUsernameParams): Promise<ChangeUsernameResponse> => {
  return Api.post('/user/username/change/', params)
}
