import { i18n } from '@/services/i18n'
import { errorMessages, signInFx } from '@/services/user'

import { domain } from './domain'
import { $SingInForm } from './forms'

export const $signInError = domain
  .createStore<string | null>(null)
  .on(signInFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([signInFx.done, $SingInForm.$values.updates])
