import { createStore } from 'effector'

import { closeFeedbackModal, openFeedbackModal, submitFeedbackFx } from './events'

// Хранилище состояния модального окна
export const $isFeedbackModalOpen = createStore(false)
  .on(openFeedbackModal, () => true)
  .on(closeFeedbackModal, () => false)

// Хранилище для обработки ошибок
export const $errorFeedback = createStore<string | null>(null)
  .on(submitFeedbackFx.failData, (_, error) => error.message)
  .reset([submitFeedbackFx, closeFeedbackModal])

// Хранилище для отслеживания статуса отправки
export const $isFeedbackSubmitting = createStore<boolean>(false).on(submitFeedbackFx.pending, (_, pending) => pending)

// Хранилище для успешной отправки
export const $isFeedbackSubmitted = createStore<boolean>(false)
  .on(submitFeedbackFx.done, () => true)
  .reset([submitFeedbackFx, closeFeedbackModal])
