import { logout } from '@/services/api'
import { requestChangeEmailFx } from '@/services/email'
import { i18n } from '@/services/i18n'
import { errorMessages, verifyEmailFx } from '@/services/user'

import { domain } from './domain'
import { ChangeEmailByCodeMode } from './enum'
import { changeChangeEmailByCodeMode } from './events'

export const $changeEmailByCodeError = domain
  .createStore<string | null>(null)
  .on(verifyEmailFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([verifyEmailFx.done])

export const $requestCodeChangeEmailError = domain
  .createStore<string | null>(null)
  .on(requestChangeEmailFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([requestChangeEmailFx.done])

export const $changeEmailByCodeMode = domain
  .createStore<ChangeEmailByCodeMode>(ChangeEmailByCodeMode.NONE)
  .on(changeChangeEmailByCodeMode, (_, mode) => mode)
  .reset([verifyEmailFx.done, logout])
