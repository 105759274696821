import { forward, guard, sample } from 'effector'

import { i18n } from '@/services/i18n'
import { changePassword, changePasswordFx, resetCodeRequested } from '@/services/password'

import { PasswordMode, changePasswordMode } from '../../../model'

import { $ChangePasswordForm } from './forms'

forward({
  from: changePasswordFx.done,
  to: [$ChangePasswordForm.reset, changePasswordMode.prepend(() => PasswordMode.NONE), resetCodeRequested],
})

guard({
  clock: $ChangePasswordForm.formValidated,
  filter: ({ verificationCode, oldPassword }) => Boolean(!verificationCode && !oldPassword),
}).watch(() => {
  const errorText = i18n('shared.libs.effector_forms.rules.required')
  $ChangePasswordForm.addErrors([
    { field: 'oldPassword', errorText, rule: '' },
    { field: 'code', errorText, rule: '' },
  ])
})

sample({
  clock: $ChangePasswordForm.formValidated,
  filter: ({ verificationCode, oldPassword }) => Boolean(verificationCode || oldPassword),
  fn: ({ verificationCode, oldPassword, newPassword }) => {
    if (verificationCode) return { verificationCode, newPassword }

    return { oldPassword, newPassword }
  },
  target: changePassword,
})
