import { ListItem, styled } from '@mui/material'

export const Styled = {
  ListItem: styled(ListItem, {
    shouldForwardProp: (prop) => prop !== '$active',
  })<{ $active?: boolean }>(({ theme, $active }) => ({
    cursor: $active ? 'default' : 'pointer',
    '&:hover': {
      backgroundColor: $active ? '' : theme.palette.action.hover,
    },
  })),
}
