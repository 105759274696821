type IfProps = {
  condition: unknown
  children: React.ReactNode
}

export const If = ({ children, condition }: IfProps) => {
  if (condition) {
    return <>{children}</>
  }

  return null
}
