import { Box, Chip } from '@mui/material'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { Button } from '@/shared/components/button'
import { IconButton } from '@/shared/components/icon-button'
import { Choose, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { $passwordMode, PasswordMode, changePasswordMode } from '../../model'
import { ChangePassword } from '../change-password'
import { SetNewPassword } from '../new-password'

type Props = {
  passwordVerified: boolean
}

export const PasswordSettingsInfo = ({ passwordVerified }: Props) => {
  const passwordMode = useStore($passwordMode)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'center' }}>
      <Choose>
        <When condition={passwordMode === PasswordMode.NONE}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <If condition={passwordVerified}>
              <IconButton iconName='Edit' iconSize={18} onClick={() => changePasswordMode(PasswordMode.CHANGE)} />
              <Chip
                label={<I18n id='features.profile.password-settings.password-setted' />}
                size='small'
                color={'success'}
                sx={{ ml: 1 }}
              />
            </If>
            <If condition={!passwordVerified}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Chip
                  label={<I18n id='features.profile.password-settings.password-not-setted' />}
                  sx={{ mb: 2 }}
                  size='small'
                  color={'warning'}
                />
                <Button size='small' variant='contained' onClick={() => changePasswordMode(PasswordMode.SET)}>
                  <I18n id='features.profile.password-settings.set-password-button' />
                </Button>
              </Box>
            </If>
          </Box>
        </When>
        <When condition={passwordMode === PasswordMode.SET}>
          <SetNewPassword />
        </When>
        <When condition={passwordMode === PasswordMode.CHANGE}>
          <ChangePassword />
        </When>
      </Choose>
    </Box>
  )
}
