import { ThemeProvider } from '@mui/material'
import { useStore } from 'effector-react'

import { useActivateNavigation } from '@/services/navigate'
import { getTheme } from '@/services/theme'
import { $colorTheme } from '@/services/user'

type BaseTemplateProps = {
  children: React.ReactNode
}

export const BaseTemplate = ({ children }: BaseTemplateProps) => {
  const themeName = useStore($colorTheme)
  const theme = getTheme(themeName)
  useActivateNavigation()

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default BaseTemplate
