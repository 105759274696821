import { Box } from '@mui/material'
import { useStore } from 'effector-react'

import { $user, User } from '@/services/user'
import { If } from '@/shared/utils/if'

import { ChangeEmail } from './ui/change-email'
import { $changeEmailByCodeMode, ChangeEmailByCodeMode } from './ui/change-email/model'
import { EmailSettingsConfirmForm } from './ui/confirm-form'
import { EmailSettingsErrors } from './ui/errors'
import { EmailSettingsHeader } from './ui/header'
import { EmailSettingsInfo } from './ui/info'
import { ConfirmNewEmail } from './ui/new-email'

export const EmailSetting = () => {
  const user = useStore($user) as User
  const changeEmailMode = useStore($changeEmailByCodeMode)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <EmailSettingsHeader />
      <If condition={user.email}>
        <EmailSettingsInfo email={user.email} emailVerified={user.emailVerified} />
        <If condition={!user.emailVerified}>
          <EmailSettingsConfirmForm />
          <EmailSettingsErrors />
        </If>
        <If condition={user.emailVerified && changeEmailMode !== ChangeEmailByCodeMode.NONE}>
          <ChangeEmail />
        </If>
      </If>
      <If condition={!user.email}>
        <ConfirmNewEmail />
      </If>
    </Box>
  )
}
