import { i18n } from '@/services/i18n'
import { errorMessages, signUpFastFx } from '@/services/user'

import { domain } from './domain'
import { changeSignUpByUsernameModal } from './events'

export const $signUpByUsernameError = domain
  .createStore<string | null>(null)
  .on(signUpFastFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([signUpFastFx.done])

export const $signUpByUsernameModal = domain
  .createStore<boolean>(false)
  .on(changeSignUpByUsernameModal, (_, value) => value)
  .reset([signUpFastFx.done])
