import { SxProps } from '@mui/material'
import { LinkProps } from 'react-router-dom'

import * as Styled from './styled'

type RouterLinkProps = LinkProps & {
  color?: string
  isDisabled?: boolean
  sx?: SxProps
}

export const RouterLink = (props: RouterLinkProps) => {
  const { to, children, color = 'text.primary', isDisabled = false, sx = {}, ...otherProps } = props
  const styles = {
    color: isDisabled ? 'text.disabled' : color,
    cursor: isDisabled ? 'default' : 'pointer',
    ...sx,
  }

  return (
    <Styled.Root sx={styles} to={isDisabled ? '#' : to} {...otherProps}>
      {children}
    </Styled.Root>
  )
}
