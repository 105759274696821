import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { dateFormat } from '@/shared/utils/moment'

interface MemoryDialogProps {
  open: boolean
  handleClose: () => void
  date: string
  content: string
}

export const MemoryDialog: React.FC<MemoryDialogProps> = ({ open, handleClose, date, content }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='memory-dialog-title'
      maxWidth='md'
      PaperProps={{
        sx: { width: '600px', height: '400px' },
      }}
    >
      <DialogTitle id='memory-dialog-title'>Воспоминание от {dateFormat(date)}</DialogTitle>
      <DialogContent dividers>
        <Typography variant='body1'>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
