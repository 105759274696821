import { api } from '@/services/api'

import { ChangePasswordParams, PasswordResponse, SetPasswordParams } from '../user/types'

export const requestPasswordCodeApi = (): Promise<PasswordResponse> => {
  return api.post('/user/password/request_code/')
}

export const changePasswordApi = (params: ChangePasswordParams): Promise<PasswordResponse> => {
  return api.post('/user/password/change/', params)
}

export const setPasswordApi = (params: SetPasswordParams): Promise<PasswordResponse> => {
  return api.post('/user/password/set/', params)
}
