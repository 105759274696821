import { guard } from 'effector'

import {
  getDiaryEntry,
  getDiaryEntryFx,
  getMonthEntries,
  getMonthEntriesFx,
  updateDiaryEntry,
  updateDiaryEntryFx,
} from './events'
import { $calendar } from './stores'

// Защита от повторных запросов на получение данных календаря
getMonthEntries.watch(({ year, month }) => {
  const cacheKey = `${year}-${month}`
  const cachedData = $calendar.getState()[cacheKey]

  if (!cachedData) {
    getMonthEntriesFx({ year, month })
  }
})

guard({
  source: updateDiaryEntry,
  filter: updateDiaryEntryFx.pending.map((state) => !state),
  target: updateDiaryEntryFx,
})

guard({
  source: getDiaryEntry,
  filter: getDiaryEntryFx.pending.map((state) => !state),
  target: getDiaryEntryFx,
})
