import { Box, Divider, ListItemIcon, ListItemText } from '@mui/material'
import { useStore } from 'effector-react'
import { useLocation } from 'react-router-dom'

import { $isTourRunning, changeOnboardingNextStep } from '@/features/onboarding'
import { ROUTE_PATH } from '@/packages/paths'
import { Icon } from '@/shared/components/icon'
import { RouterLink } from '@/shared/components/router-link'
import { NAV_CLASSES } from '@/shared/consts/onboarding'
import { If } from '@/shared/utils/if'

import { getActiveColor } from '../../utils/getActiveColor'
import { OnboardingButton } from '../onboarding-button'

import { Styled } from './styled'

type Props = {
  onboardingStep: number
}

export const SidebarHelp = ({ onboardingStep }: Props) => {
  const location = useLocation()
  const onboardingTour = useStore($isTourRunning)

  const onClickSidebar = () => {
    if (onboardingTour) changeOnboardingNextStep()
  }

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Divider sx={{ mt: 1 }} />
      <If condition={onboardingStep !== -1}>
        <OnboardingButton />
        <Divider />
      </If>
      <RouterLink to={ROUTE_PATH.HELP}>
        <Styled.ListItem
          $active={ROUTE_PATH.HELP === location.pathname}
          className={NAV_CLASSES.SIDEBAR.HELP}
          onClick={onClickSidebar}
        >
          <ListItemIcon>
            <Icon name='Help' sx={{ color: getActiveColor(ROUTE_PATH.HELP) }} />
          </ListItemIcon>
          <ListItemText primary='Помощь' sx={{ color: getActiveColor(ROUTE_PATH.HELP) }} />
        </Styled.ListItem>
      </RouterLink>
    </Box>
  )
}
