import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import { TokenService } from '../token'

import { api, refreshTokenApi } from './api'
import { logout } from './events'

class InterceprotsServiceClass {
  interseptorRequest(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    if (config.headers) {
      const token = TokenService.getAccessToken()
      if (token) {
        config.headers.authorization = `Bearer ${token}`
      }
    }

    return config
  }

  interseptorRequestError(error: unknown) {
    return Promise.reject(error)
  }

  interseprotResponse(response: AxiosResponse): AxiosResponse {
    return response
  }

  async interseprotResponseError(error: Error | AxiosError) {
    if (!axios.isAxiosError(error)) {
      return Promise.reject(error)
    }
    const originalConfig = error.config

    if (!originalConfig) {
      return Promise.reject(error)
    }

    if (originalConfig.url === '/token/refresh/') {
      logout()

      return Promise.reject(error)
    }

    if (originalConfig.url !== '/token/' && error.response) {
      // @ts-ignore Not found _retry
      if (error.response.status === 401 && !originalConfig._retry) {
        // @ts-ignore Not found _retry
        originalConfig._retry = true

        const refreshToken = await TokenService.getRefreshToken()
        if (refreshToken === null) {
          logout()

          return api(originalConfig)
        }
        const { data: authData } = await refreshTokenApi({ refresh: refreshToken })
        TokenService.setTokens(authData.access, authData.refresh)

        return api(originalConfig)
      }
    }

    return Promise.reject(error)
  }
}

export const InterceprotsService = new InterceprotsServiceClass()
