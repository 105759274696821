import { Box, Dialog, DialogContent, Tab, Tabs } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import React from 'react'

import { I18n } from '@/services/i18n'
import { Choose, When } from '@/shared/utils/choose'

import { SignIn } from '../sign-in'
import { SignUp } from '../sign-up'
import { SignUpByUsername } from '../sign-up-by-username'

import { TabType } from './enum'
import { $isAuthModalVisible, changeAuthModalVisible } from './model'
import { AuthGate } from './model/gate'

type Props = {}

const { SIGN_IN, SIGN_UP } = TabType

export const AuthModal = ({}: Props) => {
  const isVisibleModal = useStore($isAuthModalVisible)
  const [tab, setTab] = React.useState<TabType>(SIGN_IN)
  useGate(AuthGate)

  const handleChange = (_: React.SyntheticEvent, newTab: TabType) => {
    setTab(newTab)
  }

  return (
    <Dialog
      sx={{ padding: { xs: 0, sm: 2 } }}
      fullWidth
      open={isVisibleModal}
      onClose={() => changeAuthModalVisible(false)}
    >
      <DialogContent sx={{ width: { xs: '100%' }, maxWidth: 'lg' }}>
        <Tabs variant='fullWidth' value={tab} onChange={handleChange} sx={{ pb: 2 }}>
          <Tab label={<I18n id='base.signin' />} value={SIGN_IN} />
          <Tab label={<I18n id='base.signup' />} value={SIGN_UP} />
        </Tabs>
        <Choose>
          <When condition={tab === SIGN_IN}>
            <SignIn />
          </When>
          <When condition={tab === SIGN_UP}>
            <SignUp />
          </When>
        </Choose>
        <Box sx={{ mt: 3 }}>
          <SignUpByUsername />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
