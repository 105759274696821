import { random, sample } from 'lodash'

const nicknames = [
  'Воспоминание',
  'Мгновение',
  'Дневник',
  'Память',
  'ТайныйМир',
  'Летопись',
  'Хранитель',
  'МирПамяти',
  'СекретСердца',
  'Тепло',
  'Момент',
  'Тайна',
  'Вдохновение',
]

export const generateUsername = (): string => {
  // Список географических названий, связанных с Россией

  // Выбор случайного географического названия
  const geoName: string = sample(nicknames) || 'User'

  // Генерация случайного числа для уникальности
  const num: number = random(1, 999_999_999)

  // Комбинирование выбранного слова и числа
  const username: string = `${geoName}${num}`

  return username
}
