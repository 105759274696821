import { AutoAwesome, Star } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

// Компонент для приветственного сообщения с анимацией
export const WelcomeMessage: React.FC = () => {
  const [showMessage, setShowMessage] = useState(false)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: 4,
        border: '2px solid #A0CAF9',
        borderRadius: 8,
        backgroundColor: '#E3F2FD',
        color: '#1E88E5',
        maxWidth: '100%',
        margin: '50px auto',
        position: 'relative',
      }}
    >
      <Typography variant='h6' sx={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#1976D2' }} gutterBottom>
        Путешествие начинается!
      </Typography>
      <Typography variant='body1' sx={{ color: '#555', marginTop: 1, textAlign: 'center' }}>
        Поделитесь своим настроением сегодня и уже завтра этот календарик оживёт!
      </Typography>

      <Box sx={{ position: 'relative', marginTop: 3, height: 60 }}>
        <AnimatePresence mode='wait' initial={false}>
          {!showMessage ? (
            <IconButton
              component={motion.div}
              whileHover={{
                scale: 1.15,
                rotate: [0, 10, -10, 10, 0],
                transition: { duration: 4, ease: 'easeInOut' },
              }}
              onClick={() => setShowMessage(true)}
              sx={{
                backgroundColor: '#D1E7FF',
                color: '#1976D2',
                borderRadius: '50%',
                fontSize: '1.5rem',
                width: 60,
                height: 60,
                border: '2px solid #A0CAF9',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              key='star'
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 4 }}
            >
              <Star fontSize='large' />
            </IconButton>
          ) : (
            // Пожелание
            <motion.div
              key='message'
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 4 }}
              style={{
                padding: '8px 16px',
                backgroundColor: '#FFF7E6',
                color: '#F57C00',
                borderRadius: '12px',
                boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AutoAwesome sx={{ fontSize: '1.5rem', color: '#FFC107', marginRight: 1 }} />
              <Typography sx={{ fontWeight: 'bold', color: '#D84315' }}>Пусть все ваши мечты сбудутся!</Typography>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  )
}
