import { Box, styled } from '@mui/material'

export const StyledEmptyMessageContainer = styled(Box)({
  marginTop: '32px',
  padding: '24px',
  border: '1px solid #ddd', // Тонкая обводка
  borderRadius: '16px',
  textAlign: 'center',
  background: 'linear-gradient(135deg, #f9f9fc, #e3eafc)', // Более светлый градиент
  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)', // Более мягкая тень для эффекта "поднятости"
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
})
