import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Alert, Box, IconButton, Snackbar, Typography } from '@mui/material'
import React, { useState } from 'react'

import { If } from '@/shared/utils/if'

type Props = {
  viewText: string
  copyText?: string
  onAfterCopy?: () => void
  showIcon?: boolean
  withSnackbar?: boolean
  blurred?: boolean
}

export const Clipboard = ({
  viewText,
  copyText,
  onAfterCopy,
  showIcon = false,
  withSnackbar = false,
  blurred = false,
}: Props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [isBlurred, setIsBlurred] = useState(blurred) // Состояние для управления размытостью текста

  const handleClickText = () => {
    setIsBlurred((prev) => !prev) // Переключение состояния размытости
  }

  const handleClickIcon = (event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement>) => {
    event.stopPropagation()

    navigator.clipboard.writeText(copyText || viewText).then(() => {
      withSnackbar && setOpenSnackbar(true)
      onAfterCopy && onAfterCopy()
    })
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <>
      <Box display='flex' alignItems='center'>
        <Typography
          onClick={handleClickText} // Клик по тексту переключает размытость
          sx={{
            cursor: 'pointer',
            filter: isBlurred ? 'blur(5px)' : 'none',
            userSelect: 'none',
            transition: 'filter 0.5s ease-in-out', // Плавный переход для размытия
          }}
        >
          {viewText}
        </Typography>
        <If condition={showIcon}>
          <IconButton size='small' onClick={handleClickIcon} sx={{ ml: 1 }}>
            <ContentCopyIcon fontSize='inherit' />
          </IconButton>
        </If>
      </Box>
      <If condition={withSnackbar}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            variant='filled'
            onClose={handleCloseSnackbar}
            severity='success'
            sx={{ width: '100%', color: 'common.white' }}
          >
            Скопировано
          </Alert>
        </Snackbar>
      </If>
    </>
  )
}
