import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Box, IconButton, MenuItem, Select, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import moment from 'moment'

import { $user } from '@/services/user'

interface MonthYearSwitcherProps {
  year: number
  month: number
  onYearChange: (year: number) => void
  onMonthChange: (month: number) => void
}

export const MonthYearSwitcher: React.FC<MonthYearSwitcherProps> = ({ year, month, onYearChange, onMonthChange }) => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1
  const user = useStore($user)
  const userJoinedDate = moment(user?.dateJoined)

  const handlePrevMonth = () => {
    if (month === 1) {
      onMonthChange(12)
      onYearChange(year - 1)
    } else {
      onMonthChange(month - 1)
    }
  }

  const handleNextMonth = () => {
    if (month === 12) {
      onMonthChange(1)
      onYearChange(year + 1)
    } else {
      onMonthChange(month + 1)
    }
  }

  // Определяем, является ли следующий месяц будущим
  const isNextMonthInFuture = year > currentYear || (year === currentYear && month >= currentMonth)

  // Определяем, является ли предыдущий месяц до даты регистрации пользователя
  const isPrevMonthBeforeJoinedDate =
    year < userJoinedDate.year() || (year === userJoinedDate.year() && month <= userJoinedDate.month() + 1)

  // Получаем список годов, начиная с года регистрации и до текущего года
  const availableYears = Array.from(
    { length: currentYear - userJoinedDate.year() + 1 },
    (_, i) => userJoinedDate.year() + i
  )

  return (
    <Box display='flex' alignItems='center' justifyContent='center' sx={{ mb: 3 }}>
      <IconButton onClick={handlePrevMonth} sx={{ fontSize: '2rem' }} disabled={isPrevMonthBeforeJoinedDate}>
        <ChevronLeft fontSize='inherit' />
      </IconButton>

      <Typography variant='h5' sx={{ mx: 2, userSelect: 'none', color: 'text.primary' }}>
        {new Date(year, month - 1).toLocaleString('default', { month: 'long' })}
      </Typography>

      {/* Выбор года */}
      <Select
        value={year}
        onChange={(e) => onYearChange(e.target.value as number)}
        variant='standard'
        disableUnderline
        sx={{ fontSize: '1.5rem', mx: 1 }}
      >
        {availableYears.map((yearOption) => (
          <MenuItem key={yearOption} value={yearOption}>
            {yearOption}
          </MenuItem>
        ))}
      </Select>

      <IconButton onClick={handleNextMonth} sx={{ fontSize: '2rem' }} disabled={isNextMonthInFuture}>
        <ChevronRight fontSize='inherit' />
      </IconButton>
    </Box>
  )
}
