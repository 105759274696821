import ButtonUi, { ButtonProps } from '@mui/material/Button'
import React from 'react'

interface Props extends ButtonProps {
  children: React.ReactNode
}

export const Button: React.FC<Props> = ({ children, ...props }) => {
  return <ButtonUi {...props}>{children}</ButtonUi>
}
