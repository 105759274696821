import { i18n } from '@/services/i18n'
import {
  errorMessages,
  signInFx,
  signUpByCodeFx,
  signUpByPasswordFx,
  verifyChangeEmail,
  verifyChangeEmailFx,
  verifyEmail,
  verifyEmailFx,
} from '@/services/user'

import { domain } from './domain'
import {
  requestChangeEmail,
  requestChangeEmailFx,
  requestCodeNewEmail,
  requestCodeNewEmailFx,
  requestCodeProfile,
  requestCodeProfileFx,
  requestCodeSignIn,
  requestCodeSignInFx,
  requestCodeSignUpFx,
} from './events'

export const $verifyEmailError = domain
  .createStore<string | null>(null)
  .on([verifyEmailFx.failData, verifyChangeEmailFx.failData], (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([verifyEmail, verifyChangeEmail])

export const $verifyEmailPending = verifyEmailFx.pending

export const $isCodeRequested = domain
  .createStore(false)
  .on(
    [
      requestCodeSignInFx.done,
      requestCodeSignUpFx.done,
      requestCodeProfileFx.done,
      requestCodeNewEmailFx.done,
      requestChangeEmailFx.done,
    ],
    () => true
  )
  .reset([signInFx.done, signUpByCodeFx.done, signUpByPasswordFx.done, verifyEmailFx.done, verifyChangeEmailFx.done])

export const $requestVerifyCodeError = domain
  .createStore<string | null>(null)
  .on(
    [
      requestCodeSignInFx.failData,
      requestCodeSignUpFx.failData,
      requestCodeProfileFx.failData,
      requestCodeNewEmailFx.failData,
      requestCodeNewEmailFx.failData,
    ],
    (_, { response, message }) => {
      if (response?.status === 400) {
        return errorMessages[response.data.type]()
      }

      return `${i18n('services.auth.model.unknown_error')} ${message}`
    }
  )
  .reset([requestCodeProfile, requestCodeSignIn, requestCodeSignUpFx, requestCodeNewEmail, requestChangeEmail])
