import { useEffect } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'

import { I18n } from '@/services/i18n'
import { Button } from '@/shared/components/button'
import { RouterLink } from '@/shared/components/router-link'
import { Text } from '@/shared/components/text'

import { ROUTE_PATH } from '../../paths'
import { Template } from '../../templates/main'

import * as Styled from './styled'

export const ErrorPage = () => {
  const error: unknown = useRouteError()
  const navigate = useNavigate()

  useEffect(() => {
    if (error && typeof error === 'object' && 'status' in error) {
      const { status } = error as { status: number }

      if (status === 404) {
        navigate(ROUTE_PATH.HOME)
      }
    } else {
      console.error('Caught an unknown error:', error)
    }
  }, [error, navigate])

  return (
    <Template withSidebar={false}>
      <Styled.Root>
        <Text id='packages.pages.error.title' component='div' fontSize={30} sx={{ width: '100%', my: 5 }} />
        <RouterLink to={ROUTE_PATH.HOME}>
          <Button sx={{ width: '100%' }} variant='contained'>
            <I18n id='packages.pages.error.to_main' />
          </Button>
        </RouterLink>
      </Styled.Root>
    </Template>
  )
}

export default ErrorPage
