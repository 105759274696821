import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'

import { DayStatus, getDiaryEntryFx, resetDiaryEntry, updateDiaryEntryFx } from '../../model'

import { useEditEntryForm } from './hooks'
import { ConfirmDialog } from './ui/confirm-dialog'
import { MoodSelect } from './ui/mood-select'

interface EditEntryDialogProps {
  open: boolean
  onClose: () => void
  day: DayStatus
}

export const EditEntryDialog: React.FC<EditEntryDialogProps> = ({ open, onClose, day }) => {
  const isUpdating = useStore(updateDiaryEntryFx.pending)
  const isLoading = useStore(getDiaryEntryFx.pending)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [wasUpdating, setWasUpdating] = useState(false) // Новое состояние для отслеживания начала сохранения

  const { content, mood, hasUnsavedChanges, handleContentChange, handleMoodChange, handleSave } = useEditEntryForm(
    open,
    day.date
  )

  const handleClose = () => {
    if (hasUnsavedChanges) {
      setConfirmDialogOpen(true)
    } else {
      resetDiaryEntry()
      onClose()
    }
  }

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false)
    resetDiaryEntry()
    onClose()
  }

  // Обновленный useEffect для отслеживания завершения сохранения
  useEffect(() => {
    if (wasUpdating && !isUpdating) {
      // Закрываем модалку только если обновление завершено после того, как оно началось
      resetDiaryEntry()
      onClose()
    }
    setWasUpdating(isUpdating) // Обновляем состояние для отслеживания статуса обновления
  }, [isUpdating, wasUpdating, onClose])

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>Редактировать заметку за {new Date(day.date).toLocaleDateString()}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight={200}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ mt: 1, mb: 2 }}>
                <TextField
                  label='Содержание'
                  value={content}
                  onChange={(e) => handleContentChange(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  disabled={isUpdating}
                  variant='outlined'
                  size='small'
                />
              </Box>
              <MoodSelect value={mood} onChange={handleMoodChange} disabled={isUpdating} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' disabled={isLoading || isUpdating}>
            Отмена
          </Button>
          <Button onClick={handleSave} color='primary' variant='contained' disabled={isUpdating || isLoading}>
            {isUpdating ? 'Сохранение...' : 'Сохранить'}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmClose}
      />
    </>
  )
}
