import { guard } from 'effector'

import { verifyEmail, verifyEmailFx } from '@/services/user'

import { verifyChangeEmail, verifyChangeEmailFx } from '../user/events'

import * as api from './api'
import {
  requestChangeEmail,
  requestChangeEmailFx,
  requestCodeNewEmail,
  requestCodeNewEmailFx,
  requestCodeProfile,
  requestCodeProfileFx,
  requestCodeSignIn,
  requestCodeSignInFx,
  requestCodeSignUp,
  requestCodeSignUpFx,
} from './events'

// Verify email

verifyEmailFx.use(api.verifyEmailApi)

guard({
  source: verifyEmail,
  filter: verifyEmailFx.pending.map((state) => !state),
  target: verifyEmailFx,
})

// Verify change email

verifyChangeEmailFx.use(api.verifyChangeEmailApi)

guard({
  source: verifyChangeEmail,
  filter: verifyChangeEmailFx.pending.map((state) => !state),
  target: verifyChangeEmailFx,
})

// Request profile

requestCodeProfileFx.use(api.requestCodeProfileApi)

guard({
  source: requestCodeProfile,
  filter: requestCodeProfileFx.pending.map((state) => !state),
  target: requestCodeProfileFx,
})

// Request sign in

requestCodeSignInFx.use(api.requestCodeSignInApi)

guard({
  source: requestCodeSignIn,
  filter: requestCodeSignInFx.pending.map((state) => !state),
  target: requestCodeSignInFx,
})

// Request sign up

requestCodeSignUpFx.use(api.requestCodeSignUpApi)

guard({
  source: requestCodeSignUp,
  filter: requestCodeSignUpFx.pending.map((state) => !state),
  target: requestCodeSignUpFx,
})

// Request code new email

requestCodeNewEmailFx.use(api.requestCodeNewEmailApi)

guard({
  source: requestCodeNewEmail,
  filter: requestCodeNewEmailFx.pending.map((state) => !state),
  target: requestCodeNewEmailFx,
})

// Request change email

requestChangeEmailFx.use(api.requestChangeEmailApi)

guard({
  source: requestChangeEmail,
  filter: requestChangeEmailFx.pending.map((state) => !state),
  target: requestChangeEmailFx,
})
