export const getBackgroundColor = (
  status: string,
  mood?: string | null,
  isToday: boolean = false,
  isLoading: boolean = false
): string => {
  if (isLoading) return '#f0f0f0' // Цвет для состояния загрузки
  if (isToday) return '#cfffec' // Цвет для сегодняшнего дня
  if (status === 'inactive') return '#f0f0f0' // Светло-серый для неактивных дней
  if (status === 'filled') {
    switch (mood) {
      case 'very_bad':
        return '#ffebee' // Светло-красный
      case 'bad':
        return '#fff3e0' // Светло-оранжевый
      case 'neutral':
        return '#e8f5e9' // Светло-зеленый
      case 'good':
        return '#e8f5e9' // Светло-зеленый
      case 'great':
        return '#e8f5e9' // Светло-зеленый
      case 'special':
        return '#c988d3' // Светло-золотой цвет для премиального вида
      default:
        return '#fff'
    }
  }

  return '#ffcccb' // Более насыщенный светло-красный для незаполненных дней
}
