import { Divider, List, ListItemIcon, ListItemText } from '@mui/material'
import { useStore } from 'effector-react'
import { useLocation } from 'react-router-dom'

import { $isTourRunning, changeOnboardingNextStep } from '@/features/onboarding'
import { Icon } from '@/shared/components/icon'
import { RouterLink } from '@/shared/components/router-link'
import { useMedia } from '@/shared/utils/media'

import { changeSidebarVisible } from '../../../../../../model'
import { getActiveColor } from '../../utils/getActiveColor'

import { sidebarItems } from './consts'
import { Styled } from './styled'

export const SidebarList = () => {
  const location = useLocation()
  const onboardingTour = useStore($isTourRunning)
  const { isMobile } = useMedia()

  const onClickSidebar = () => {
    if (isMobile) changeSidebarVisible(false)
    if (onboardingTour) changeOnboardingNextStep()
  }

  return (
    <List sx={{ width: '100%', py: 0 }}>
      <Divider />
      {sidebarItems.map(({ path, text, icon, className }, index) => (
        <RouterLink to={path} key={index}>
          <Styled.ListItem className={className} $active={path === location.pathname} onClick={onClickSidebar}>
            <ListItemIcon>
              <Icon name={icon} sx={{ color: getActiveColor(path) }} />
            </ListItemIcon>
            <ListItemText primary={text} sx={{ color: getActiveColor(path) }} />
          </Styled.ListItem>
          <Divider />
        </RouterLink>
      ))}
    </List>
  )
}
