// /main/components/WeekDaysHeader.tsx
import { Box, Typography } from '@mui/material'

export const WeekDaysHeader: React.FC = () => (
  <Box display='grid' gridTemplateColumns='repeat(7, 1fr)' gap={1} sx={{ mb: 1, justifyItems: 'center' }}>
    {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map((day, index) => (
      <Typography key={index} variant='subtitle2' textAlign='center' color='text.primary'>
        {day}
      </Typography>
    ))}
  </Box>
)
