import { useStore } from 'effector-react'
import { useEffect, useState } from 'react'

import { $selectedDiaryEntry, getDiaryEntry, updateDiaryEntry } from '../../model'

export const useEditEntryForm = (open: boolean, dayDate: string) => {
  const selectedDiaryEntry = useStore($selectedDiaryEntry)
  const [content, setContent] = useState('')
  const [mood, setMood] = useState<string | null>(null)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  useEffect(() => {
    if (open) {
      getDiaryEntry(dayDate)
    }
  }, [open, dayDate])

  useEffect(() => {
    if (selectedDiaryEntry) {
      setContent(selectedDiaryEntry.content || '')
      setMood(selectedDiaryEntry.mood || null)
      setHasUnsavedChanges(false)
    }
  }, [selectedDiaryEntry])

  const handleContentChange = (value: string) => {
    setContent(value)
    setHasUnsavedChanges(true)
  }

  const handleMoodChange = (value: string) => {
    setMood(value)
    setHasUnsavedChanges(true)
  }

  const handleSave = () => {
    updateDiaryEntry({ date: dayDate, content, mood })
    setHasUnsavedChanges(false)
  }

  return {
    content,
    mood,
    hasUnsavedChanges,
    handleContentChange,
    handleMoodChange,
    handleSave,
  }
}
