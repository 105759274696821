import { Box, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { $passwordError } from '@/services/password'
import { $userError, setPasswordFx } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Icon } from '@/shared/components/icon'
import { LoadingButton } from '@/shared/components/loading-button'
import { If } from '@/shared/utils/if'

import { $NewPasswordForm } from './model'

export const SetNewPassword = () => {
  const requestError = useStore($passwordError)
  const pending = useStore(setPasswordFx.pending)
  const userError = useStore($userError)

  const { fields, submit, errorText } = useForm($NewPasswordForm)

  return (
    <Box sx={{ width: 300 }}>
      <Box sx={{ width: '100%', my: 2, display: 'flex', alignItems: 'center' }}>
        <TextField
          label={<I18n id='features.password-settings.new-password.set' />}
          type='password'
          fullWidth
          size='small'
          variant='standard'
          disabled={pending}
          error={fields.newPassword.hasError()}
          helperText={errorText('newPassword')}
          value={fields.newPassword.value}
          onKeyDown={(e) => e.key === 'Enter' && submit()}
          onChange={(e) => fields.newPassword.onChange(e.target.value)}
        />
        <LoadingButton
          sx={{ ml: 1, minWidth: 20, minHeight: 30 }}
          size='small'
          variant='contained'
          loading={pending}
          onClick={() => submit()}
        >
          <Icon name='Check' sx={{ fontSize: 20 }} />
        </LoadingButton>
      </Box>
      <If condition={requestError || userError}>
        <AlertError text={requestError || userError} variant='filled' sx={{ mt: 2 }} />
      </If>
    </Box>
  )
}
