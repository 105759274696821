import { AxiosError, AxiosResponse } from 'axios'

// Тип для заметки
export interface DiaryEntry {
  content: string
  mood: string
  date: string
}

// Типы для эффектов и событий
export interface UpdateTodayEntryParams {
  content: string
}

export interface UpdateMoodParams {
  mood: string
}

export type GetTodayEntryResponse = AxiosResponse<DiaryEntry>
export type UpdateTodayEntryResponse = AxiosResponse<DiaryEntry>
export type UpdateMoodResponse = AxiosResponse<DiaryEntry>
export type DiaryError = AxiosError<void>

export enum StatusType {
  IDLE = 'IDLE',
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  ERROR = 'ERROR',
}
