import { Box, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import {
  $isCodeRequested,
  $passwordError,
  changePasswordFx,
  requestPasswordCode,
  requestPasswordCodeFx,
  resetCodeRequested,
  resetPasswordErrors,
} from '@/services/password'
import { $user, $userError, User } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { Text } from '@/shared/components/text'
import { If } from '@/shared/utils/if'

import { changePasswordMode, PasswordMode } from '../../model'

import { $ChangePasswordForm } from './model'

export const ChangePassword = () => {
  const changePasswordError = useStore($passwordError)
  const changePasswordPending = useStore(changePasswordFx.pending)
  const requestCodePending = useStore(requestPasswordCodeFx.pending)
  const userError = useStore($userError)
  const isCodeRequested = useStore($isCodeRequested)
  const user = useStore($user) as User

  const { fields, submit, errorText, reset } = useForm($ChangePasswordForm)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: 300 }}>
      {isCodeRequested ? (
        <Box>
          <TextField
            label={<I18n id='features.profile.change_password.confirmation_code' />}
            fullWidth
            disabled={changePasswordPending}
            error={fields.verificationCode.hasError()}
            variant='standard'
            helperText={errorText('verificationCode')}
            onKeyDown={(e) => e.key === 'Enter' && submit()}
            value={fields.verificationCode.value}
            onChange={(e) => fields.verificationCode.onChange(e.target.value)}
          />
          <Text id='features.profile.change_password.set_code' sx={{ color: 'success.main', my: 1 }}>
            {user.email}
          </Text>
        </Box>
      ) : (
        <TextField
          label={<I18n id='features.profile.change_password.old_password' />}
          fullWidth
          type='password'
          disabled={changePasswordPending}
          error={fields.oldPassword.hasError()}
          variant='standard'
          helperText={errorText('oldPassword')}
          onKeyDown={(e) => e.key === 'Enter' && submit()}
          value={fields.oldPassword.value}
          onChange={(e) => fields.oldPassword.onChange(e.target.value)}
        />
      )}
      <TextField
        label={<I18n id='features.profile.change_password.new_password' />}
        fullWidth
        type='password'
        disabled={changePasswordPending}
        error={fields.newPassword.hasError()}
        variant='standard'
        helperText={errorText('newPassword')}
        onKeyDown={(e) => e.key === 'Enter' && submit()}
        value={fields.newPassword.value}
        onChange={(e) => fields.newPassword.onChange(e.target.value)}
      />
      <Box sx={{ mt: 2 }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          size='small'
          loading={changePasswordPending}
          onClick={() => submit()}
        >
          <I18n id='base.change' />
        </LoadingButton>
        <If condition={!isCodeRequested && user.email}>
          <LoadingButton
            sx={{ mb: 2, width: '100%' }}
            variant='outlined'
            color='secondary'
            size='small'
            loading={requestCodePending}
            onClick={() => {
              resetPasswordErrors()
              requestPasswordCode()
            }}
          >
            <I18n id='features.profile.change_password.forgot_password' />
          </LoadingButton>
        </If>
        <Button
          sx={{ width: '100%' }}
          size='small'
          variant='text'
          color='error'
          onClick={() => {
            reset()
            resetPasswordErrors()
            resetCodeRequested()
            changePasswordMode(PasswordMode.NONE)
          }}
        >
          <I18n id='base.cancel' />
        </Button>
      </Box>
      <If condition={changePasswordError || userError}>
        <AlertError text={changePasswordError || userError} variant='filled' sx={{ mt: 2 }} />
      </If>
    </Box>
  )
}
