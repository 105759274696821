import { getTodayEntryApi, updateMoodApi, updateTodayEntryApi } from './api'
import { domain } from './domain'
import {
  DiaryError,
  GetTodayEntryResponse,
  UpdateMoodParams,
  UpdateMoodResponse,
  UpdateTodayEntryParams,
} from './types'

export const getTodayEntry = domain.createEvent<void>('getTodayEntry')
export const getTodayEntryFx = domain
  .createEffect<void, GetTodayEntryResponse, DiaryError>('getTodayEntryFx')
  .use(getTodayEntryApi)

export const updateTodayEntry = domain.createEvent<UpdateTodayEntryParams>('updateTodayEntry')
export const updateTodayEntryFx = domain
  .createEffect<UpdateTodayEntryParams, GetTodayEntryResponse, DiaryError>('updateTodayEntryFx')
  .use(updateTodayEntryApi)

export const updateMood = domain.createEvent<UpdateMoodParams>('updateMood')
export const updateMoodFx = domain
  .createEffect<UpdateMoodParams, UpdateMoodResponse, DiaryError>('updateMoodFx')
  .use(updateMoodApi)
