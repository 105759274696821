import { Box } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { $user, User } from '@/services/user'
import { IconButton } from '@/shared/components/icon-button'
import { Text } from '@/shared/components/text'
import { Choose, When } from '@/shared/utils/choose'

import { $ChangeUsernameForm, $usernameMode, UsernameMode, changeUsernameMode } from './model'
import { UsernameFormSettings } from './ui/form'
import { UsernameSettingsHeader } from './ui/header'

export const UsernameSettings = () => {
  const user = useStore($user) as User
  const usernameMode = useStore($usernameMode)
  const { fields } = useForm($ChangeUsernameForm)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <UsernameSettingsHeader />
      <Choose>
        <When condition={usernameMode === UsernameMode.NONE}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <IconButton
              iconName='Edit'
              iconSize={18}
              sx={{ mr: 1 }}
              onClick={() => {
                fields.newUsername.onChange(user.username)
                changeUsernameMode(UsernameMode.CHANGE)
              }}
            />
            <Text fontSize={22}>{user.username}</Text>
          </Box>
        </When>
        <When condition={usernameMode === UsernameMode.CHANGE}>
          <UsernameFormSettings />
        </When>
      </Choose>
    </Box>
  )
}
