import { Card, CardActions, CardContent, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { requestCodeSignUpFx } from '@/services/email'
import { I18n } from '@/services/i18n'
import { $userError } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { Text } from '@/shared/components/text'
import { If } from '@/shared/utils/if'

import { changeSignUpMode, SignUpMode } from '../../../../model'
import { $requestCodeSignUpError, $SignUpRequestCodeForm } from '../../model'

import * as Styled from './styled'

export const SignUpByCodeStep1 = () => {
  const requestError = useStore($requestCodeSignUpError)
  const requestCodePending = useStore(requestCodeSignUpFx.pending)
  const userError = useStore($userError)

  const { fields, submit, errorText } = useForm($SignUpRequestCodeForm)

  return (
    <Card sx={{ minWidth: 250 }}>
      <CardContent>
        <Text
          id='features.auth.ui.sign-up.without_pass.title'
          sx={{ fontSize: 24, textAlign: 'center', mb: 1 }}
          color='text.secondary'
          gutterBottom
        />
        <Styled.Fields>
          <TextField
            label={<I18n id='base.email' />}
            autoFocus
            variant='standard'
            disabled={requestCodePending}
            error={fields.email.hasError()}
            helperText={errorText('email')}
            value={fields.email.value}
            onKeyDown={(e) => e.key === 'Enter' && submit()}
            onChange={(e) => fields.email.onChange(e.target.value)}
          />
        </Styled.Fields>
      </CardContent>
      <CardActions sx={{ flexDirection: 'column' }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          loading={requestCodePending}
          onClick={() => {
            submit()
          }}
        >
          <I18n id='features.auth.ui.sign-up.without_pass.base.request_code' />
        </LoadingButton>
        <Button sx={{ width: '100%' }} color='secondary' onClick={() => changeSignUpMode(SignUpMode.BY_PASSWORD)}>
          <I18n id='features.auth.ui.sign-up.with-pass.reg_base' />
        </Button>
      </CardActions>
      <If condition={requestError || userError}>
        <AlertError text={requestError || userError} variant='filled' sx={{ mt: 2 }} />
      </If>
    </Card>
  )
}
