import { domain } from '../domain'

import { changeOnboardingStepApi } from './api'
import { ChangeOnboardingStepParams, ChangeOnboardingStepResponse, Error } from './types'

// События для управления онбордингом
export const changeOnboardingStep = domain.createEvent<ChangeOnboardingStepParams>('changeOnboardingStep')
export const changeOnboardingStepFx = domain
  .createEffect<ChangeOnboardingStepParams, ChangeOnboardingStepResponse, Error>('changeOnboardingStepFx')
  .use(changeOnboardingStepApi)
