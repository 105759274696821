import { getDiaryEntryApi, getMonthEntriesApi, updateDiaryEntryApi } from './api'
import { domain } from './domain'
import {
  CalendarError,
  GetDiaryEntryResponse,
  GetMonthEntriesParams,
  GetMonthEntriesResponse,
  UpdateDiaryEntryParams,
  UpdateDiaryEntryResponse,
} from './types'

// Событие для получения данных календаря
export const getMonthEntries = domain.createEvent<{ year: number; month: number }>('getMonthEntries')

// Эффект для получения данных календаря
export const getMonthEntriesFx = domain
  .createEffect<GetMonthEntriesParams, GetMonthEntriesResponse, CalendarError>('getMonthEntriesFx')
  .use(getMonthEntriesApi)

// Событие для обновления заметки
export const updateDiaryEntry = domain.createEvent<UpdateDiaryEntryParams>('updateDiaryEntry')

// Эффект для обновления заметки
export const updateDiaryEntryFx = domain
  .createEffect<UpdateDiaryEntryParams, UpdateDiaryEntryResponse, CalendarError>('updateDiaryEntryFx')
  .use(updateDiaryEntryApi)

// Событие для загрузки данных дня
export const getDiaryEntry = domain.createEvent<string>('getDiaryEntry')
// Событие для загрузки данных дня
export const resetDiaryEntry = domain.createEvent<void>('resetDiaryEntry')

// Эффект для загрузки данных дня
export const getDiaryEntryFx = domain
  .createEffect<string, GetDiaryEntryResponse, CalendarError>('getDiaryEntryFx')
  .use(getDiaryEntryApi)
