import { TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { requestChangeEmailFx } from '@/services/email'
import { I18n } from '@/services/i18n'
import { $userError } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { LoadingButton } from '@/shared/components/loading-button'
import { If } from '@/shared/utils/if'

import { $ChangeEmailRequestCodeForm, $requestCodeChangeEmailError } from '../../model'

export const ChangeEmailByCodeStep1 = () => {
  const requestError = useStore($requestCodeChangeEmailError)
  const requestCodePending = useStore(requestChangeEmailFx.pending)
  const userError = useStore($userError)

  const { fields, submit, errorText } = useForm($ChangeEmailRequestCodeForm)

  return (
    <>
      <TextField
        label={<I18n id='features.profile.change_email.new_email' />}
        fullWidth
        variant='standard'
        disabled={requestCodePending}
        error={fields.newEmail.hasError()}
        helperText={errorText('newEmail')}
        value={fields.newEmail.value}
        onKeyDown={(e) => e.key === 'Enter' && submit()}
        onChange={(e) => fields.newEmail.onChange(e.target.value)}
      />
      <LoadingButton
        sx={{ width: '100%', my: 2 }}
        variant='contained'
        loading={requestCodePending}
        onClick={() => {
          submit()
        }}
      >
        <I18n id='features.auth.ui.sign-up.without_pass.base.request_code' />
      </LoadingButton>
      <If condition={requestError || userError}>
        <AlertError text={requestError || userError} variant='filled' sx={{ mt: 2 }} />
      </If>
    </>
  )
}
