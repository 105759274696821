import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

import { API_CONFIG } from './config'
import { RefreshTokenParams, RefreshTokenResponse } from './types'

export const api = applyCaseMiddleware(axios.create(API_CONFIG))

export const refreshTokenApi = (params: RefreshTokenParams): Promise<RefreshTokenResponse> => {
  return api.post('/token/refresh/', params)
}
