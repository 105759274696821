import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { logout } from '@/services/api'
import { useActivateNavigation } from '@/services/navigate'
import { TokenService } from '@/services/token'
import { $user, getUser } from '@/services/user'

import { Template } from '../../templates/main'

export type ProtectedRouteProps = {
  element: JSX.Element
}

export const AuthRoute = ({ element }: ProtectedRouteProps) => {
  const user = useStore($user)
  useActivateNavigation()

  useEffect(() => {
    const refreshToken = TokenService.getRefreshToken()

    if (refreshToken) {
      getUser()
    }
    if (!user || !refreshToken) {
      logout()
    }
  }, [])

  if (!user) {
    return <Template withSidebar={false}>{''}</Template>
  }

  return element
}

export default AuthRoute
