import { Box, styled } from '@mui/material'

import { SIDEBAR_WIDTH } from '@/shared/consts/sizes'

export const SidebarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isDesktop',
})<{ $isDesktop: boolean }>(({ theme, $isDesktop }) => ({
  width: `${SIDEBAR_WIDTH}px`,
  height: '100vh',
  position: 'sticky',
  top: 0,
  left: 0,
  overflow: 'hidden',
  borderRight: $isDesktop ? `1px solid ${theme.palette.divider}` : '',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
}))
