import { Card, styled } from '@mui/material'

export const StyledCard = styled(Card)<{ hasContent: boolean; large: boolean }>(({ hasContent, large }) => ({
  width: large ? 220 : 180,
  height: large ? 260 : 220,
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: hasContent ? 'pointer' : 'default',
  backgroundColor: hasContent ? '#e8f5e9' : '#f0f0f0',
  boxShadow: hasContent ? '0 6px 20px rgba(0, 128, 0, 0.3)' : '0 4px 12px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: hasContent ? 'scale(1.05)' : 'none',
  },
}))
