import { CircularProgress, Typography } from '@mui/material'

import * as Styled from './styled'

export const LoadingSpinner: React.FC = () => {
  return (
    <Styled.LoaderContainer>
      <CircularProgress size={80} thickness={4} color='primary' />
      <Typography variant='h6' sx={{ mt: 2, color: '#6a1b9a' }}>
        Загружаем ваши воспоминания...
      </Typography>
    </Styled.LoaderContainer>
  )
}
