import { Box } from '@mui/material'

import { Sidebar } from '@/features/sidebar'
import { MOBILE_NAVIGATION_HEIGHT } from '@/shared/consts/sizes'
import { Choose, Otherwise, When } from '@/shared/utils/choose'
import { useMedia } from '@/shared/utils/media'

import * as Styled from './styled'

type TemplateProps = {
  children: React.ReactNode
  withSidebar?: boolean
  withContainer?: boolean
  backgroundUrl?: string
}

export const Template = ({ children, withSidebar = true, withContainer = true, backgroundUrl }: TemplateProps) => {
  const { isDesktop } = useMedia()

  return (
    <Styled.Root $backgroundUrl={backgroundUrl}>
      <Choose>
        <When condition={withContainer || isDesktop}>
          <Styled.Container>
            {withSidebar && <Sidebar />}
            <Styled.Content>{children}</Styled.Content>
          </Styled.Container>
        </When>
        <Otherwise>
          {withSidebar && <Sidebar />}
          <Box sx={{ flex: 1 }}>{children}</Box>
          <Box sx={{ mb: MOBILE_NAVIGATION_HEIGHT }} />
        </Otherwise>
      </Choose>
    </Styled.Root>
  )
}
