import { combine } from 'effector'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'
import { ColorTheme } from '@/services/theme'

import { i18n, Language, updateIntl } from '../i18n'

import { domain } from './domain'
import { errorMessages } from './errors'
import {
  changeColorTheme,
  changeLang,
  changeUsernameFx,
  disableSecretCodeFx,
  getUserFx,
  getUserSecretCodeFx,
  refreshSecretCodeFx,
  resetUser,
  setPasswordFx,
  setUserError,
  signInByCodeFx,
  signInBySecretCodeFx,
  signInFx,
  signUpByCodeFx,
  signUpByPasswordFx,
  signUpFastFx,
  verifyChangeEmailFx,
  verifyEmailFx,
} from './events'
import { changeOnboardingStepFx } from './preferences'
import { User } from './types'

export const initUser = Storage.get<User>(STORAGE_NAMES.USER)
export const colorTheme = Storage.get<ColorTheme>(STORAGE_NAMES.COLOR_THEME)
export const lang = Storage.get<Language>(STORAGE_NAMES.LANG)

export const $user = domain
  .createStore<User | null>(initUser)
  .on(getUserFx.doneData, (_, { data }) => data)
  .on(signInFx.doneData, (_, { data: { user } }) => user)
  .on(signInByCodeFx.doneData, (_, { data: { user } }) => user)
  .on(signInBySecretCodeFx.doneData, (_, { data: { user } }) => user)
  .on(signUpByPasswordFx.doneData, (_, { data }) => data.user)
  .on(signUpByCodeFx.doneData, (_, { data }) => data.user)
  .on(signUpFastFx.doneData, (_, { data }) => data.user)
  .on(verifyEmailFx.doneData, (user, { data }) => (user ? { ...user, email: data.email, emailVerified: true } : null))
  .on(verifyChangeEmailFx.doneData, (user, { data }) => (user ? { ...user, email: data.newEmail } : null))
  .on(setPasswordFx.doneData, (user) => (user ? { ...user, hasPassword: true } : null))
  .on(changeUsernameFx.doneData, (user, { data }) => (user ? { ...user, username: data.username } : null))
  .on(changeOnboardingStepFx.doneData, (user, { data }) =>
    user ? { ...user, preferences: { ...user.preferences, onboardingStep: data.onboardingStep } } : null
  )
  .on(resetUser, () => null)

export const $getUserPending = getUserFx.pending
export const $signInPending = signInFx.pending

export const $userAuthPending = combine({
  getUserPending: $getUserPending,
  signInPending: $signInPending,
}).map(({ getUserPending, signInPending }) => getUserPending || signInPending)

export const $userError = domain
  .createStore<string | null>(null)
  .on(setUserError, (_, data) => `${i18n('services.auth.model.unknown_error')} ${data.message}`)
  .reset(resetUser)

export const $usernameError = domain
  .createStore<string | null>(null)
  .on(changeUsernameFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([changeUsernameFx.done, resetUser])

export const $colorTheme = domain
  .createStore<ColorTheme>(colorTheme || ColorTheme.LIGHT)
  .on(changeColorTheme, (_, data) => data.theme)

export const $lang = domain.createStore<Language>(lang || Language.RU).on(changeLang, (_, data) => data.lang)

$user.watch((user) => {
  if (!user) return null

  const lang = Storage.get<Language>(STORAGE_NAMES.LANG) || Language.RU

  if (lang && user?.settings?.language !== lang) {
    changeLang({ lang })

    updateIntl(lang)
  }

  const colorTheme = Storage.get<ColorTheme>(STORAGE_NAMES.COLOR_THEME)
  if (colorTheme && user?.settings?.theme !== colorTheme) {
    changeColorTheme({ theme: colorTheme })
  }
})

export const $secretCode = domain
  .createStore<string | null>(null)
  .on(getUserSecretCodeFx.doneData, (_, { data }) => data.secretCode)
  .on(refreshSecretCodeFx.doneData, (_, { data }) => data.secretCode)
  .reset(disableSecretCodeFx.done)
