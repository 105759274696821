import { ROUTE_PATH } from '@/packages/paths'
import { Navigation } from '@/services/navigate'

import { TokenService } from '../token'

type Args = {
  access: string
  refresh: string
}

export const loginUser = ({ access, refresh }: Args) => {
  TokenService.setTokens(access, refresh)
  if ([ROUTE_PATH.PROMO].includes(Navigation.currentPath)) {
    Navigation.to(ROUTE_PATH.HOME)
  }
}
