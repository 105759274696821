import { createForm } from 'effector-forms'

import { RULES } from '@/shared/libs/effector-forms'

export const $NewEmailRequestCodeForm = createForm({
  fields: {
    email: {
      init: '',
      rules: [RULES.email()],
    },
  },
  validateOn: ['submit'],
})

export const $NewEmailVerifyEmailForm = createForm({
  fields: {
    code: {
      init: '',
      rules: [RULES.required()],
    },
  },
  validateOn: ['submit'],
})
