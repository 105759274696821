import { requestCodeSignUpFx } from '@/services/email'
import { i18n } from '@/services/i18n'
import { errorMessages, signUpByCodeFx } from '@/services/user'

import { domain } from './domain'
import { SingUpByCodeMode } from './enum'
import { changeSingUpByCodeMode, resetErrors } from './events'

export const $signUpByCodeError = domain
  .createStore<string | null>(null)
  .on(signUpByCodeFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([signUpByCodeFx.done, resetErrors])

export const $requestCodeSignUpError = domain
  .createStore<string | null>(null)
  .on(requestCodeSignUpFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([requestCodeSignUpFx.done, resetErrors])

export const $singUpByCodeMode = domain
  .createStore<SingUpByCodeMode>(SingUpByCodeMode.STEP1)
  .on(changeSingUpByCodeMode, (_, mode) => mode)
  .reset(requestCodeSignUpFx.done)
