import { Avatar, Box, Typography } from '@mui/material'

import { Icon } from '@/shared/components/icon'

import { getFontSize } from './utils'

type Props = {
  username: string
}

export const UserProfile = ({ username }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Avatar sx={{ width: 64, height: 64, margin: '0 auto' }}>
        <Icon name='Person' size='large' sx={{ color: 'white' }} />
      </Avatar>
      <Typography
        variant='h6'
        sx={{
          mt: 2,
          fontSize: getFontSize(username),
          maxWidth: '200px',
          color: 'text.primary',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {username}
      </Typography>
    </Box>
  )
}
