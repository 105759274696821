import { Rule } from 'effector-forms'
import isEmail from 'validator/lib/isEmail'

import { i18n } from '@/services/i18n'

export const RULES = {
  required: (): Rule<string> => ({
    name: 'required',
    validator: (value) => Boolean(value),
    errorText: i18n('shared.libs.effector_forms.rules.required'),
  }),
  email: (): Rule<string> => ({
    name: 'email',
    validator: (value) => isEmail(value),
    errorText: i18n('shared.libs.effector_forms.rules.email'),
  }),
  minLength: (min: number): Rule<string> => ({
    name: 'minLength',
    validator: (value) => value.length >= min,
    errorText: i18n('errors.min_length', { value: min }),
  }),
  maxLength: (max: number): Rule<string> => ({
    name: 'maxLength',
    validator: (value) => value.length <= max,
    errorText: i18n('errors.max_length', { value: max }),
  }),
  minValue: (min: number): Rule<number> => ({
    name: 'minValue',
    validator: (value) => Number(value) >= min,
    errorText: i18n('errors.min_value', { value: min }),
  }),
  maxValue: (max: number): Rule<number> => ({
    name: 'maxValue',
    validator: (value) => Number(value) <= max,
    errorText: i18n('errors.max_value', { value: max }),
  }),
}
