// ./ui/status-indicator/index.tsx
import { Box, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import React from 'react'

import { $statusEntry } from '../../model'

import { statusMessages } from './consts'

export const StatusIndicator: React.FC = () => {
  const status = useStore($statusEntry)

  // Получаем соответствующее сообщение и цвет для текущего статуса
  const { message, color } = statusMessages[status]

  return (
    <Box display='flex' justifyContent='flex-end' mt={1}>
      <Typography variant='subtitle2' sx={{ color }}>
        {message}
      </Typography>
    </Box>
  )
}
