import { Box } from '@mui/material'
import { RouterProvider } from 'react-router-dom'

import { OnboardingTour } from '@/features/onboarding'
import { YandexMetrika } from '@/features/yandex-metrika'
import { I18nProvider } from '@/services/i18n'
import { NAV_CLASSES } from '@/shared/consts/onboarding'

import { router } from './routes'

export const App = () => {
  return (
    <I18nProvider>
      <OnboardingTour />
      <YandexMetrika />
      <Box sx={{ height: '100%' }} className={NAV_CLASSES.APP}>
        <RouterProvider router={router} />
      </Box>
    </I18nProvider>
  )
}
