export const ROUTE_PATH = {
  HOME: '/',
  TODAY: '/',
  CALENDAR: '/calendar',
  PROMO: '/promo',
  MEMORIES: '/memories',
  PROFILE: '/profile',
  HELP: '/help',
  PAYMENT: '/payment',
  PAYMENT_SUCCESS: '/payment/success/:id',
}
