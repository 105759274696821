import { Template } from '../../templates/main'

import { Memories } from './ui/main'

export const MemoriesPage = () => {
  return (
    <Template withContainer={false}>
      <Memories />
    </Template>
  )
}

export default MemoriesPage
