import { Main } from '@/packages/pages/main/features/main'

import { Template } from '../../templates/main'

export const MainPage = () => {
  return (
    <Template withContainer={false}>
      <Main />
    </Template>
  )
}

export default MainPage
