import { Api } from '@/services/api'

import {
  GetDiaryEntryResponse,
  GetMonthEntriesParams,
  GetMonthEntriesResponse,
  UpdateDiaryEntryParams,
  UpdateDiaryEntryResponse,
} from './types'

// API-запрос для получения данных календаря
export const getMonthEntriesApi = ({ year, month }: GetMonthEntriesParams): Promise<GetMonthEntriesResponse> => {
  return Api.get(`/diary-entry/calendar/get_month_entries/`, {
    params: { year, month },
  })
}

export const updateDiaryEntryApi = ({
  date,
  content,
  mood,
}: UpdateDiaryEntryParams): Promise<UpdateDiaryEntryResponse> => {
  return Api.put(`/diary-entry/entry/update/`, {
    date,
    content,
    mood,
  })
}

// API-запрос для получения данных дня
export const getDiaryEntryApi = (date: string): Promise<GetDiaryEntryResponse> => {
  return Api.get(`/diary-entry/entry/${date}/`)
}
