import { api } from '@/services/api'
import { VerifyEmailParams, VerifyEmailResponse } from '@/services/user'

import { VerifyChangeEmailParams, VerifyChangeEmailResponse } from '../user/types'

import { RequestChangeEmailParams, RequestCodeResponse, RequestCodeWithEmailParams } from './types'

export const verifyEmailApi = (params: VerifyEmailParams): Promise<VerifyEmailResponse> => {
  return api.post('/user/email/verify/', params)
}

export const verifyChangeEmailApi = (params: VerifyChangeEmailParams): Promise<VerifyChangeEmailResponse> => {
  return api.post('/user/email/change/verify/', params)
}

export const requestCodeProfileApi = (): Promise<RequestCodeResponse> => {
  return api.post('user/email/request_code_profile/')
}

export const requestCodeSignInApi = (params: RequestCodeWithEmailParams): Promise<RequestCodeResponse> => {
  return api.post('user/email/request_code_sign_in/', params)
}

export const requestCodeSignUpApi = (params: RequestCodeWithEmailParams): Promise<RequestCodeResponse> => {
  return api.post('user/email/request_code_sign_up/', params)
}

export const requestCodeNewEmailApi = (params: RequestCodeWithEmailParams): Promise<RequestCodeResponse> => {
  return api.post('user/email/request_code_new_email/', params)
}

export const requestChangeEmailApi = (params: RequestChangeEmailParams): Promise<RequestCodeResponse> => {
  return api.post('user/email/change/request_code/', params)
}
