import { createStore } from 'effector'

import { logout } from '@/services/api'

import { getMemoriesFx } from './events'
import { MemoriesState } from './types'

// Хранилище для всех воспоминаний
export const $memories = createStore<MemoriesState | null>(null)
  .on(getMemoriesFx.doneData, (_, { data }) => data)
  .reset(logout)
