import { Box, CircularProgress } from '@mui/material'

import { DayStatus } from '../../model/types'
import { CalendarDay } from '../calendar-day'

interface CalendarGridProps {
  calendar: DayStatus[] | undefined
  emptyDays: number
  isLoading: boolean
  onDayClick: (day: DayStatus) => void
}

export const CalendarGrid: React.FC<CalendarGridProps> = ({ calendar, emptyDays, isLoading, onDayClick }) => (
  <Box
    display='grid'
    gridTemplateColumns='repeat(7, 1fr)'
    gap={1}
    sx={{
      justifyItems: 'center',
      '@media (max-width: 600px)': { gap: 0.5 },
      minHeight: 300,
      alignItems: 'center',
    }}
  >
    {isLoading ? (
      <CircularProgress sx={{ gridColumn: '1 / -1' }} />
    ) : (
      <>
        {Array.from({ length: emptyDays }).map((_, index) => (
          <Box key={`empty-${index}`} sx={{ width: 40, height: 40 }} />
        ))}
        {calendar?.map((day) => <CalendarDay key={day.date} day={day} onClick={() => onDayClick(day)} />)}
      </>
    )}
  </Box>
)
