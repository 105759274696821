import { SxProps } from '@mui/material'
import IconButtonUi, { IconButtonProps } from '@mui/material/IconButton'
import React from 'react'

import { Icon, IconName } from '@/shared/components/icon'

interface BaseProps extends IconButtonProps {
  sx?: SxProps
  iconSize?: number
}

interface PropsWithIconName extends BaseProps {
  iconName: IconName
  children?: never
}

interface PropsWithChildren extends BaseProps {
  iconName?: never
  children: React.ReactNode
}

type Props = PropsWithIconName | PropsWithChildren

export const IconButton: React.FC<Props> = ({ iconName, iconSize, children, sx, ...props }) => {
  return (
    <IconButtonUi sx={sx} {...props}>
      {iconName ? <Icon name={iconName} size={iconSize} /> : children}
    </IconButtonUi>
  )
}
