import { Box, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { $verifyEmailError } from '@/services/email'
import { I18n } from '@/services/i18n'
import { $userError, verifyEmailFx } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { If } from '@/shared/utils/if'

import { $NewEmailRequestCodeForm, $NewEmailVerifyEmailForm, NewEmailByCodeMode } from '../../model'
import { changeNewEmailByCodeMode } from '../../model/events'

export const NewEmailByCodeStep2 = () => {
  const newEmailError = useStore($verifyEmailError)
  const newEmailPending = useStore(verifyEmailFx.pending)
  const userError = useStore($userError)

  const {
    fields: { email },
  } = useForm($NewEmailRequestCodeForm)
  const { fields, submit, errorText } = useForm($NewEmailVerifyEmailForm)

  return (
    <>
      <TextField variant='standard' fullWidth label={<I18n id='base.email' />} autoFocus disabled value={email.value} />
      <TextField
        label={<I18n id='base.code' />}
        fullWidth
        disabled={newEmailPending}
        error={fields.code.hasError()}
        variant='standard'
        helperText={errorText('code')}
        onKeyDown={(e) => e.key === 'Enter' && submit()}
        value={fields.code.value}
        onChange={(e) => fields.code.onChange(e.target.value)}
      />
      <Box sx={{ mt: 2 }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          loading={newEmailPending}
          onClick={() => submit()}
        >
          <I18n id='base.confirm' />
        </LoadingButton>
        <Button
          sx={{ width: '100%' }}
          color='secondary'
          onClick={() => {
            changeNewEmailByCodeMode(NewEmailByCodeMode.STEP1)
          }}
        >
          <I18n id='base.cancel' />
        </Button>
      </Box>
      <If condition={newEmailError || userError}>
        <AlertError text={newEmailError || userError} variant='filled' sx={{ mt: 2 }} />
      </If>
    </>
  )
}
