import { navigate } from '@/shared/utils/navigate'

import { joyrideSteps } from './consts'
import { OnboardingStepNames } from './enums'
import { changeOnboardingStepByName } from './events'

const stepNameToIndex: Record<string, number> = joyrideSteps.reduce(
  (acc, step, index) => {
    if (step.name) {
      acc[step.name] = index
    }

    return acc
  },
  {} as Record<string, number>
)

export const navigateToStepByName = (stepName: OnboardingStepNames) => {
  const stepIndex = stepNameToIndex[stepName]

  if (stepIndex !== undefined) {
    const step = joyrideSteps[stepIndex]

    // Если у шага есть маршрут, осуществляем навигацию
    if (step.route) {
      navigate(step.route)
    }

    // Переключаемся на нужный шаг
    changeOnboardingStepByName(stepIndex)
  }
}
