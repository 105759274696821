import { createForm } from 'effector-forms'

import { RULES } from '@/shared/libs/effector-forms'

import { generateUsername } from './utils'

export const $SignUpByUsernameForm = createForm({
  fields: {
    username: {
      init: generateUsername(),
      rules: [RULES.required()],
    },
  },
  validateOn: ['submit'],
})
