import { createForm } from 'effector-forms'

import { RULES } from '@/shared/libs/effector-forms'

export const $ChangePasswordForm = createForm({
  fields: {
    verificationCode: {
      init: '',
      rules: [],
    },
    oldPassword: {
      init: '',
      rules: [],
    },
    newPassword: {
      init: '',
      rules: [RULES.minLength(8)],
    },
  },
  validateOn: ['submit'],
})
