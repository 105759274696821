import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { TooltipRenderProps } from 'react-joyride'

import { useMedia } from '@/shared/utils/media'

export const TourTooltip: React.FC<TooltipRenderProps> = ({
  continuous,
  index,
  step,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
}) => {
  const { isMobile } = useMedia()
  const isLast = index === size - 1

  return (
    <Box
      {...tooltipProps}
      sx={{
        padding: 3,
        backgroundColor: '#ffffff',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: isMobile ? '100%' : 400, // Для мобильных устройств - на всю ширину
        width: isMobile ? '100%' : 'auto', // Устанавливаем ширину на 100% для мобильных
      }}
    >
      <Typography variant='h6' sx={{ mb: 1 }}>
        {step.title}
      </Typography>
      <Typography variant='body1' sx={{ mb: 2 }}>
        {step.content}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button {...closeProps} variant='text' color='secondary'>
          Закрыть
        </Button>
        {continuous && (
          <Button {...primaryProps} variant='contained' color='primary'>
            {isLast ? 'Завершить' : 'Дальше'}
          </Button>
        )}
      </Box>
    </Box>
  )
}
