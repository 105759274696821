import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import {
  $isFeedbackModalOpen,
  $isFeedbackSubmitted,
  $isFeedbackSubmitting,
  closeFeedbackModal,
  feedbackForm,
} from '../../model'
import { FeedbackStatus } from '../feedback-status'

import { requestTypeOptions } from './consts'

export const FeedbackModal = () => {
  const isOpen = useStore($isFeedbackModalOpen)
  const isSubmitting = useStore($isFeedbackSubmitting)
  const isSubmitted = useStore($isFeedbackSubmitted)

  const { fields, submit, errorText, reset } = useForm(feedbackForm)

  const handleClose = () => {
    reset()
    closeFeedbackModal()
  }

  return (
    <>
      <Dialog open={isOpen && !isSubmitted} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>Поделитесь с нами вашим мнением!</DialogTitle>
        <DialogContent>
          <Box display='flex' flexDirection='column'>
            <Typography variant='body1' color='text.secondary' sx={{ mb: 1 }}>
              Мы всегда рады услышать вас! Пожалуйста, расскажите нам, что вам понравилось, или поделитесь идеями о том,
              как мы можем стать лучше.
            </Typography>

            {/* Используем Select для выпадающего списка */}
            <FormControl fullWidth margin='normal' error={fields.requestType.hasError()}>
              <InputLabel id='request-type-label'>Тема сообщения</InputLabel>
              <Select
                labelId='request-type-label'
                value={fields.requestType.value}
                onChange={(e) => fields.requestType.onChange(e.target.value)}
                label='Тема сообщения'
              >
                {requestTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant='body2' color='error'>
                {errorText('requestType')}
              </Typography>
            </FormControl>

            <TextField
              label='Ваше сообщение'
              value={fields.text.value}
              onChange={(e) => fields.text.onChange(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin='normal'
              variant='outlined'
              error={fields.text.hasError()}
              helperText={errorText('text')}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Отмена
          </Button>
          <Button onClick={() => submit()} variant='contained' color='primary'>
            {isSubmitting ? 'Отправка...' : 'Отправить'}
          </Button>
        </DialogActions>
      </Dialog>

      {isSubmitted && <FeedbackStatus onClose={handleClose} />}
    </>
  )
}
