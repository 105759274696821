import { Box, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { $userError, $usernameError, changeUsernameFx } from '@/services/user'
import { AlertError } from '@/shared/components/alert'
import { Button } from '@/shared/components/button'
import { LoadingButton } from '@/shared/components/loading-button'
import { If } from '@/shared/utils/if'

import { $ChangeUsernameForm, UsernameMode, changeUsernameMode } from '../../model'

export const UsernameFormSettings = () => {
  const changeUsernameError = useStore($usernameError)
  const changeUsernamePending = useStore(changeUsernameFx.pending)
  const userError = useStore($userError)

  const { fields, submit, errorText, reset } = useForm($ChangeUsernameForm)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: 300 }}>
      <TextField
        label={<I18n id='base.username' />}
        fullWidth
        type='username'
        disabled={changeUsernamePending}
        error={fields.newUsername.hasError()}
        variant='standard'
        helperText={errorText('newUsername')}
        onKeyDown={(e) => e.key === 'Enter' && submit()}
        value={fields.newUsername.value}
        onChange={(e) => fields.newUsername.onChange(e.target.value)}
      />
      <Box sx={{ mt: 2 }}>
        <LoadingButton
          sx={{ width: '100%', mb: 2 }}
          variant='contained'
          size='small'
          loading={changeUsernamePending}
          onClick={() => submit()}
        >
          <I18n id='base.change' />
        </LoadingButton>
        <Button
          color='error'
          variant='outlined'
          size='small'
          onClick={() => {
            reset()
            changeUsernameMode(UsernameMode.NONE)
          }}
        >
          <I18n id='base.cancel' />
        </Button>
      </Box>
      <If condition={changeUsernameError || userError}>
        <AlertError text={changeUsernameError || userError} variant='filled' sx={{ mt: 2 }} />
      </If>
    </Box>
  )
}
