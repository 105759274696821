import { ROUTE_PATH } from '@/packages/paths'
import { NAV_CLASSES } from '@/shared/consts/onboarding'

import { OnboardingStepNames } from './enums'
import { StepWithRoute } from './types'

// Объект с шагами
export const JOYRIDE_STATES: Record<OnboardingStepNames, StepWithRoute> = {
  [OnboardingStepNames.Welcome]: {
    name: OnboardingStepNames.Welcome,
    target: `.${NAV_CLASSES.APP}`,
    content:
      'Привет! Здесь мы вместе будем вести дневник и следить за настроением. Готов начать это волшебное путешествие?',
    placement: 'center',
    spotlightClicks: false,
    disableOverlay: false,
    disableBeacon: true,
  },
  [OnboardingStepNames.ChangeSettings]: {
    name: OnboardingStepNames.ChangeSettings,
    target: `.${NAV_CLASSES.SIDEBAR.CHANGE_SETTINGS}`,
    content: 'Здесь ты сможешь выбрать язык и тему сайта. Сделай его таким, как тебе нравится!',
    placement: 'top',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.GoToToday]: {
    name: OnboardingStepNames.GoToToday,
    target: `.${NAV_CLASSES.SIDEBAR.TODAY}`,
    content: 'Перейдём к записи на сегодня? Нажми здесь, чтобы начать!',
    placement: 'right',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.InputToday]: {
    name: OnboardingStepNames.InputToday,
    target: `.${NAV_CLASSES.TODAY.FORM_INPUT}`,
    content: 'Вот здесь ты можешь рассказать о том, что случилось сегодня. Это твоя история!',
    placement: 'bottom',
    spotlightPadding: 0,
    route: ROUTE_PATH.TODAY,
    disableBeacon: true,
  },
  [OnboardingStepNames.MoodToday]: {
    name: OnboardingStepNames.MoodToday,
    target: `.${NAV_CLASSES.TODAY.FORM_MOOD}`,
    content: 'И не забудь указать своё настроение! Это поможет тебе понять свои эмоции в будущем.',
    placement: 'bottom',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.GoToMemories]: {
    name: OnboardingStepNames.GoToMemories,
    target: `.${NAV_CLASSES.SIDEBAR.MEMORIES}`,
    content: 'Давай посмотрим воспоминания! Нажми сюда, чтобы погрузиться в прошлые моменты.',
    placement: 'right',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.Memories]: {
    name: OnboardingStepNames.Memories,
    target: `.${NAV_CLASSES.MEMORIES.PAGE}`,
    content: 'Здесь будут собираться твои воспоминания. Скоро у тебя тут станет много чего удивительного!',
    placement: 'bottom',
    spotlightPadding: 0,
    route: ROUTE_PATH.MEMORIES,
    disableBeacon: true,
  },
  [OnboardingStepNames.GoToCalendar]: {
    name: OnboardingStepNames.GoToCalendar,
    target: `.${NAV_CLASSES.SIDEBAR.CALENDAR}`,
    content: 'Хочешь увидеть свои записи по дням? Нажми сюда, чтобы открыть календарь.',
    placement: 'right',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.Calendar]: {
    name: OnboardingStepNames.Calendar,
    target: `.${NAV_CLASSES.CALENDAR.PAGE}`,
    content: 'Календарь поможет тебе следить за своими записями по дням. Такой полезный инструмент!',
    placement: 'center',
    spotlightPadding: 0,
    route: ROUTE_PATH.CALENDAR,
    disableBeacon: true,
  },
  [OnboardingStepNames.GoToHelp]: {
    name: OnboardingStepNames.GoToHelp,
    target: `.${NAV_CLASSES.SIDEBAR.HELP}`,
    content: 'Нужна помощь? Нажми сюда, и я покажу, где можно найти ответы на свои вопросы!',
    placement: 'top',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.Help]: {
    name: OnboardingStepNames.Help,
    target: `.${NAV_CLASSES.HELP.PAGE}`,
    content: 'Здесь ты найдёшь ответы на вопросы. Мы всегда готовы помочь!',
    placement: 'top',
    spotlightPadding: 0,
    route: ROUTE_PATH.HELP,
    disableBeacon: true,
  },
  [OnboardingStepNames.GoToProfile]: {
    name: OnboardingStepNames.GoToProfile,
    target: `.${NAV_CLASSES.SIDEBAR.PROFILE}`,
    content: 'Настройки профиля — это место, где ты можешь обновить свои данные. Давай посмотрим!',
    placement: 'right',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.Profile]: {
    name: OnboardingStepNames.Profile,
    target: `.${NAV_CLASSES.APP}`,
    content: 'В профиле ты можешь изменить свои данные. Это легко и удобно!',
    placement: 'center',
    spotlightPadding: 0,
    route: ROUTE_PATH.PROFILE,
    disableBeacon: true,
  },
  [OnboardingStepNames.RegisterEmail]: {
    name: OnboardingStepNames.RegisterEmail,
    target: `.${NAV_CLASSES.PROFILE.EMAIL}`,
    content: 'Зарегистрируй почту, чтобы сохранить свои данные. Так будет надёжнее!',
    placement: 'bottom',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.SetPassword]: {
    name: OnboardingStepNames.SetPassword,
    target: `.${NAV_CLASSES.PROFILE.PASSWORD}`,
    content: 'Можешь установить пароль для удобства. Это не обязательно!',
    placement: 'bottom',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.ChangeUsername]: {
    name: OnboardingStepNames.ChangeUsername,
    target: `.${NAV_CLASSES.PROFILE.USERNAME}`,
    content: 'Ты можешь поменять свой ник, если захочешь! Это очень просто, нужно просто нажать на карандашик!',
    placement: 'bottom',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.SecretCode]: {
    name: OnboardingStepNames.SecretCode,
    target: `.${NAV_CLASSES.PROFILE.SECRET_CODE}`,
    content:
      'Секретный код — это ещё один способ войти в свой аккаунт. Если ты не указывал почту, то лучше его сохранить, что бы не потерять доступ к своим воспоминаниям!',
    placement: 'top',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.DeleteAccount]: {
    name: OnboardingStepNames.DeleteAccount,
    target: `.${NAV_CLASSES.PROFILE.DELETE}`,
    content: 'Ой, сюда лучше не смотреть! Надеюсь эта кнопка тебе не понадобится!',
    placement: 'top',
    spotlightPadding: 0,
    disableBeacon: true,
  },
  [OnboardingStepNames.Finish]: {
    name: OnboardingStepNames.Finish,
    target: `.${NAV_CLASSES.APP}`,
    content:
      'Ну вот мы всё и посмотрели! Продолжай записывать свои моменты и помни! Каждый день — это история, которую важно помнить. Записывай свои моменты, и скоро ты сможешь взглянуть на свою жизнь с новой стороны!',
    placement: 'center',
    spotlightPadding: 0,
    route: ROUTE_PATH.TODAY,
    disableBeacon: true,
  },
}

export const joyrideSteps: StepWithRoute[] = [
  JOYRIDE_STATES[OnboardingStepNames.Welcome],
  JOYRIDE_STATES[OnboardingStepNames.ChangeSettings],
  JOYRIDE_STATES[OnboardingStepNames.GoToToday],
  JOYRIDE_STATES[OnboardingStepNames.InputToday],
  JOYRIDE_STATES[OnboardingStepNames.MoodToday],
  JOYRIDE_STATES[OnboardingStepNames.GoToMemories],
  JOYRIDE_STATES[OnboardingStepNames.Memories],
  JOYRIDE_STATES[OnboardingStepNames.GoToCalendar],
  JOYRIDE_STATES[OnboardingStepNames.Calendar],
  JOYRIDE_STATES[OnboardingStepNames.GoToProfile],
  JOYRIDE_STATES[OnboardingStepNames.Profile],
  JOYRIDE_STATES[OnboardingStepNames.RegisterEmail],
  JOYRIDE_STATES[OnboardingStepNames.SetPassword],
  JOYRIDE_STATES[OnboardingStepNames.ChangeUsername],
  JOYRIDE_STATES[OnboardingStepNames.SecretCode],
  JOYRIDE_STATES[OnboardingStepNames.DeleteAccount],
  JOYRIDE_STATES[OnboardingStepNames.GoToHelp],
  JOYRIDE_STATES[OnboardingStepNames.Help],
  JOYRIDE_STATES[OnboardingStepNames.Finish],
]
