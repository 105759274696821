import { useStore } from 'effector-react'

import { Choose, When } from '@/shared/utils/choose'

import { $signInMode, SignInMode } from './model'
import { SignInByCode } from './ui/by-code'
import { SignInByPassword } from './ui/by-password'

export const SignIn = () => {
  const signInMode = useStore($signInMode)

  return (
    <Choose>
      <When condition={signInMode === SignInMode.BY_PASSWORD}>
        <SignInByPassword />
      </When>
      <When condition={signInMode === SignInMode.BY_CODE}>
        <SignInByCode />
      </When>
    </Choose>
  )
}
