import { ROUTE_PATH } from '@/packages/paths'
import { I18n } from '@/services/i18n'
import { IconName } from '@/shared/components/icon'
import { NAV_CLASSES } from '@/shared/consts/onboarding'

interface MenuItem {
  path: string
  text: JSX.Element
  icon: IconName
  className: string
}

export const sidebarItems: MenuItem[] = [
  {
    className: NAV_CLASSES.SIDEBAR.TODAY,
    path: ROUTE_PATH.HOME,
    text: <I18n id='sidebar.item.today' />,
    icon: 'Today',
  },
  {
    className: NAV_CLASSES.SIDEBAR.CALENDAR,
    path: ROUTE_PATH.CALENDAR,
    text: <I18n id='sidebar.item.calendar' />,
    icon: 'CalendarMonth',
  },
  {
    className: NAV_CLASSES.SIDEBAR.MEMORIES,
    path: ROUTE_PATH.MEMORIES,
    text: <I18n id='sidebar.item.memories' />,
    icon: 'PendingActions',
  },
  {
    className: NAV_CLASSES.SIDEBAR.PROFILE,
    path: ROUTE_PATH.PROFILE,
    text: <I18n id='sidebar.item.profile' />,
    icon: 'Settings',
  },
]
