import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import StarIcon from '@mui/icons-material/Star'

interface MoodIconProps {
  mood?: string | null
  iconSize?: number
}

export const MoodIcon: React.FC<MoodIconProps> = ({ mood, iconSize = 32 }) => {
  if (!mood) {
    return <HighlightOffIcon sx={{ fontSize: iconSize, color: '#ff1744' }} />
  }

  switch (mood) {
    case 'very_bad':
      return <SentimentVeryDissatisfiedIcon sx={{ fontSize: iconSize, color: '#e57373' }} />
    case 'bad':
      return <SentimentDissatisfiedIcon sx={{ fontSize: iconSize, color: '#ffb74d' }} />
    case 'neutral':
      return <SentimentSatisfiedIcon sx={{ fontSize: iconSize, color: '#81c784' }} />
    case 'good':
      return <SentimentSatisfiedAltIcon sx={{ fontSize: iconSize, color: '#4caf50' }} />
    case 'great':
      return <SentimentVerySatisfiedIcon sx={{ fontSize: iconSize, color: '#4caf50' }} />
    case 'special':
      return <StarIcon sx={{ fontSize: iconSize, color: '#ffeb3b' }} />
    default:
      return <HighlightOffIcon sx={{ fontSize: iconSize, color: '#ff1744' }} />
  }
}
