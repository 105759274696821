import { logout } from '@/services/api'
import { changePasswordFx } from '@/services/password'
import { changeUsernameFx } from '@/services/user'

import { domain } from './domain'
import { UsernameMode } from './enum'
import { changeUsernameMode } from './events'

export const $usernameMode = domain
  .createStore<UsernameMode>(UsernameMode.NONE)
  .on(changeUsernameMode, (_, mode) => mode)
  .reset([changeUsernameFx.done, changePasswordFx.done, logout])
