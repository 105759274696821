import { domain } from './domain'
import {
  RequestChangeEmailParams,
  RequestCodeError,
  RequestCodeParams,
  RequestCodeResponse,
  RequestCodeWithEmailParams,
} from './types'

export const requestCodeProfile = domain.event<RequestCodeParams>('requestCodeProfile')
export const requestCodeProfileFx = domain.effect<RequestCodeParams, RequestCodeResponse, RequestCodeError>(
  'requestCodeProfileFx'
)

export const requestCodeSignIn = domain.event<RequestCodeWithEmailParams>('requestCodeSignIn')
export const requestCodeSignInFx = domain.effect<RequestCodeWithEmailParams, RequestCodeResponse, RequestCodeError>(
  'requestCodeSignInFx'
)

export const requestCodeSignUp = domain.event<RequestCodeWithEmailParams>('requestCodeSignUp')
export const requestCodeSignUpFx = domain.effect<RequestCodeWithEmailParams, RequestCodeResponse, RequestCodeError>(
  'requestCodeSignUpFx'
)

export const requestCodeNewEmail = domain.event<RequestCodeWithEmailParams>('requestCodeNewEmail')
export const requestCodeNewEmailFx = domain.effect<RequestCodeWithEmailParams, RequestCodeResponse, RequestCodeError>(
  'requestCodeNewEmailFx'
)

export const requestChangeEmail = domain.event<RequestChangeEmailParams>('requestChangeEmail')
export const requestChangeEmailFx = domain.effect<RequestChangeEmailParams, RequestCodeResponse, RequestCodeError>(
  'requestChangeEmailFx'
)
