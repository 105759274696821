import { Box } from '@mui/material'
import { useEvent, useGate, useStore } from 'effector-react'
import Joyride, { CallBackProps, EVENTS, LIFECYCLE, STATUS } from 'react-joyride'

import { $user, changeOnboardingStep } from '@/services/user'

import {
  $currentStepIndex,
  $isSingleShow,
  $isTourRunning,
  navigateTo,
  OnboardingGate,
  OnboardingStepNames,
  stopTour,
} from '../../model'
import { joyrideSteps } from '../../model/consts'
import { SingleTooltip } from '../single-tooltip'
import { TourTooltip } from '../tour-tooltip'

import { ExtendedCallBackProps } from './types'

export const OnboardingTour = () => {
  const runTour = useStore($isTourRunning)
  const isSingleShow = useStore($isSingleShow)
  const stepIndex = useStore($currentStepIndex)
  const navigate = useEvent(navigateTo)
  const user = useStore($user)
  useGate(OnboardingGate, { onboardingStep: user?.preferences.onboardingStep })

  // Восстановление шага при монтировании компонента

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, index, type, step, lifecycle } = data as ExtendedCallBackProps

    if (type !== EVENTS.STEP_AFTER && type !== EVENTS.TARGET_NOT_FOUND) {
      return
    }

    if (lifecycle === LIFECYCLE.COMPLETE && step.name === OnboardingStepNames.Finish) {
      stopTour()
      changeOnboardingStep({ onboardingStep: -1 })

      return
    }

    // Обработка закрытия тура на первом шаге
    if ((status === STATUS.FINISHED || status === STATUS.SKIPPED || action === 'close') && index === 0) {
      changeOnboardingStep({ onboardingStep: 1 }) // Обновляем шаг перед закрытием
      stopTour() // Закрываем тур

      return
    }

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED || action === 'close') {
      stopTour()

      return
    }

    // Переход между шагами
    const nextStepIndex = action === 'next' ? index + 1 : index - 1
    if (nextStepIndex >= 0 && nextStepIndex < joyrideSteps.length) {
      const nextStep = joyrideSteps[nextStepIndex]

      if (nextStep?.route) {
        navigate(nextStep.route)
      }
      const timer = nextStep?.route ? 200 : 0
      setTimeout(() => {
        changeOnboardingStep({ onboardingStep: nextStepIndex })
      }, timer)
    }
  }

  return (
    <Box>
      <Joyride
        steps={joyrideSteps}
        run={runTour || isSingleShow}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        continuous
        showProgress
        scrollDuration={1000}
        disableCloseOnEsc
        scrollToFirstStep={false}
        disableOverlayClose
        showSkipButton
        hideCloseButton={false}
        spotlightClicks
        tooltipComponent={isSingleShow ? SingleTooltip : TourTooltip}
      />
    </Box>
  )
}
