import { Box, Chip } from '@mui/material'
import { useStore } from 'effector-react'

import { I18n } from '@/services/i18n'
import { Button } from '@/shared/components/button'
import { Choose, When } from '@/shared/utils/choose'

import { $newEmailByCodeMode, NewEmailByCodeMode } from './model'
import { changeNewEmailByCodeMode } from './model/events'
import { NewEmailByCodeStep1 } from './ui/step1'
import { NewEmailByCodeStep2 } from './ui/step2'

export const ConfirmNewEmail = () => {
  const newEmailMode = useStore($newEmailByCodeMode)

  return (
    <Choose>
      <When condition={newEmailMode === NewEmailByCodeMode.NONE}>
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Chip size='small' color='warning' label={<I18n id='features.profile.new_email.email_not_set' />} />
          <Button
            size='small'
            variant='contained'
            sx={{ mt: 2 }}
            onClick={() => changeNewEmailByCodeMode(NewEmailByCodeMode.STEP1)}
          >
            <I18n id='features.profile.new_email.set-email-button' />
          </Button>
        </Box>
      </When>
      <When condition={newEmailMode === NewEmailByCodeMode.STEP1}>
        <NewEmailByCodeStep1 />
      </When>
      <When condition={newEmailMode === NewEmailByCodeMode.STEP2}>
        <NewEmailByCodeStep2 />
      </When>
    </Choose>
  )
}
