import { ArrowBack } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton,
  Typography,
} from '@mui/material'
import { useState } from 'react'

import { Icon } from '@/shared/components/icon'
import { NAV_CLASSES } from '@/shared/consts/onboarding'
import { useMedia } from '@/shared/utils/media'

import { openFeedbackModal } from '../../model'
import { FeedbackModal } from '../feedback-modal'

import { content, sections } from './consts'
import { SectionKey } from './enum'
import * as Styled from './styled'

export const HelpMain = () => {
  const { isDesktop } = useMedia()
  const isMobileView = !isDesktop
  const defaultSelectedSection = isMobileView ? null : SectionKey.HowTo
  const [selectedSection, setSelectedSection] = useState<SectionKey | null>(defaultSelectedSection)

  const handleSectionChange = (sectionId: SectionKey) => {
    setSelectedSection(sectionId)
  }

  return (
    <Styled.Root>
      <Container className={NAV_CLASSES.HELP.PAGE} maxWidth='lg' sx={{ textAlign: 'center' }}>
        <Typography color='text.primary' fontSize={32} mb={4}>
          Раздел помощи
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: isMobileView ? 'column' : 'row' }}>
          {(isMobileView && selectedSection === null) || !isMobileView ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'stretch',
                minWidth: isDesktop ? '240px' : '100%',
                mt: isDesktop ? '52px' : 0,
                mr: 4,
              }}
            >
              {sections.map(({ id, label, icon }) => (
                <Button
                  key={id}
                  variant='contained'
                  startIcon={<Icon name={icon} />}
                  onClick={() => handleSectionChange(id as SectionKey)}
                  sx={{ width: '100%' }}
                >
                  {label}
                </Button>
              ))}
              <Button
                variant='outlined'
                startIcon={<Icon name='ContactMail' />}
                onClick={() => openFeedbackModal()}
                sx={{ width: '100%' }}
              >
                Обратная связь
              </Button>
            </Box>
          ) : null}

          {(isMobileView && selectedSection !== null) || !isMobileView ? (
            <Box sx={{ flexGrow: 1 }}>
              {selectedSection && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {isMobileView && (
                      <IconButton onClick={() => setSelectedSection(null)} sx={{ alignSelf: 'flex-start', mr: 1 }}>
                        <ArrowBack />
                      </IconButton>
                    )}
                    <Typography color='text.primary' fontSize={28}>
                      {sections.find((section) => section.id === selectedSection)?.label}
                    </Typography>
                  </Box>
                  {content[selectedSection].map(({ question, answer }, index) => (
                    <Accordion key={index}>
                      <AccordionSummary sx={{ textAlign: 'left' }}>
                        <Typography fontSize={18} sx={{ color: 'text.primary' }}>
                          {question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ textAlign: 'left' }}>
                        <Typography fontSize={16} sx={{ color: 'text.primary' }}>
                          {answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              )}
            </Box>
          ) : null}
        </Box>

        {/* Подключение модального окна */}
        <FeedbackModal />
      </Container>
    </Styled.Root>
  )
}
