import { i18n } from '@/services/i18n'
import { errorMessages, setPasswordFx } from '@/services/user'

import { domain } from './domain'
import {
  changePasswordFx,
  requestPasswordCode,
  requestPasswordCodeFx,
  resetCodeRequested,
  resetPasswordErrors,
} from './events'

export const $passwordError = domain
  .createStore<string | null>(null)
  .on([changePasswordFx.failData, setPasswordFx.failData], (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([changePasswordFx, setPasswordFx, resetPasswordErrors])

export const $isCodeRequested = domain
  .createStore(false)
  .on([requestPasswordCodeFx.done], () => true)
  .reset(resetCodeRequested)

export const $passwordCodeError = domain
  .createStore<string | null>(null)
  .on([requestPasswordCodeFx.failData], (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([requestPasswordCode, resetPasswordErrors])
