import { CircularProgress, Typography } from '@mui/material'

import * as Styled from './styled'

interface LoadingOverlayProps {
  loading: boolean
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading }) => {
  if (!loading) return null

  return (
    <Styled.Overlay>
      <CircularProgress size={80} thickness={4} sx={{ color: 'text.primary' }} /> {/* Спиннер с нейтральным цветом */}
      <Typography variant='h6' sx={{ mt: 2, color: 'text.primary' }}>
        Загружаем ваши воспоминания...
      </Typography>
      <Styled.MotivationalText variant='subtitle1' sx={{ color: 'text.primary' }}>
        Каждый момент важен 🌟
      </Styled.MotivationalText>
    </Styled.Overlay>
  )
}
