import { forward } from 'effector'

import { setPassword, setPasswordFx } from '@/services/user'

import { PasswordMode, changePasswordMode } from '../../../model'

import { $NewPasswordForm } from './forms'

forward({
  from: $NewPasswordForm.formValidated,
  to: setPassword,
})

forward({
  from: setPasswordFx.done,
  to: [$NewPasswordForm.reset, changePasswordMode.prepend(() => PasswordMode.NONE)],
})
