import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import StarIcon from '@mui/icons-material/Star'

import { pluralize } from '@/shared/utils/pluralize'

export const calculatePeriodLabel = (date: string, period: string): string => {
  const memoryDate = new Date(date)
  const currentDate = new Date()

  // Если период - год, вычисляем количество лет назад
  if (period === 'year') {
    const yearDifference = currentDate.getFullYear() - memoryDate.getFullYear()

    return `${yearDifference} ${pluralize(yearDifference, 'год', 'года', 'лет')} назад`
  }

  // Возвращаем текст для других периодов (неделя, месяц, квартал)
  switch (period) {
    case 'week':
      return '1 Неделя назад'
    case 'month':
      return '1 Месяц назад'
    case 'quarter':
      return '3 Месяца назад'
    default:
      return ''
  }
}

export const getMoodIcon = (mood: string, size: number) => {
  switch (mood) {
    case 'very_bad':
      return <SentimentVeryDissatisfiedIcon sx={{ fontSize: size, color: '#e57373' }} />
    case 'bad':
      return <SentimentDissatisfiedIcon sx={{ fontSize: size, color: '#ffb74d' }} />
    case 'neutral':
      return <SentimentSatisfiedIcon sx={{ fontSize: size, color: '#81c784' }} />
    case 'good':
      return <SentimentSatisfiedAltIcon sx={{ fontSize: size, color: '#81c784' }} />
    case 'great':
      return <SentimentVerySatisfiedIcon sx={{ fontSize: size, color: '#4caf50' }} />
    case 'special':
      return <StarIcon sx={{ fontSize: size, color: '#fbc02d' }} />
    default:
      return <HighlightOffIcon sx={{ fontSize: size, color: '#9e9e9e' }} /> // Используем крестик, если настроение не указано
  }
}
