import { LoadingButtonProps, LoadingButton as LoadingButtonUi } from '@mui/lab'
import React from 'react'

interface Props extends LoadingButtonProps {
  children: React.ReactNode
}

export const LoadingButton: React.FC<Props> = ({ children, ...props }) => {
  return <LoadingButtonUi {...props}>{children}</LoadingButtonUi>
}
