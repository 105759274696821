import { forward, guard } from 'effector'

import { signInBySecretCode, signInBySecretCodeApi, signInBySecretCodeFx } from '@/services/user'

import { $SignInSecretCodeForm } from './forms'

signInBySecretCodeFx.use(signInBySecretCodeApi)

guard({
  source: signInBySecretCode,
  filter: signInBySecretCodeFx.pending.map((state) => !state),
  target: signInBySecretCodeFx,
})

forward({
  from: $SignInSecretCodeForm.formValidated,
  to: signInBySecretCode,
})

forward({
  from: signInBySecretCodeFx.doneData,
  to: [$SignInSecretCodeForm.reset],
})
