import { styled } from '@mui/material'

export const Root = styled('div')({
  width: '100%',
  marginTop: '12px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const AuthWrapper = styled('div')({
  position: 'absolute',
  right: '0px',
  top: '0px',
})
