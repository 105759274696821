import { forward, guard, sample, split } from 'effector'

import { ROUTE_PATH } from '@/packages/paths'
import { logout } from '@/services/api'
import { STORAGE_NAMES, Storage } from '@/services/local-storage'
import { Navigation } from '@/services/navigate'

import { updateIntl } from '../i18n'

import * as api from './api'
import {
  changeColorTheme,
  changeColorThemeFx,
  changeLang,
  changeLangFx,
  changeUsername,
  changeUsernameFx,
  deleteUser,
  deleteUserFx,
  disableSecretCode,
  disableSecretCodeFx,
  getUser,
  getUserFx,
  getUserSecretCode,
  getUserSecretCodeFx,
  refreshSecretCode,
  refreshSecretCodeFx,
  resetUser,
  setUserError,
  signInByCodeFx,
  signInBySecretCodeFx,
  signInFx,
  signUpByCodeFx,
  signUpByPasswordFx,
  signUpFastFx,
} from './events'
import { $user } from './store'
import { loginUser } from './utils'

getUserFx.use(api.getUserApi)

guard({
  source: getUser,
  filter: getUserFx.pending.map((state) => !state),
  target: getUserFx,
})

split({
  source: getUserFx.failData,
  match: {
    unauthorizedError: (data) => data.response?.status === 401,
  },
  cases: {
    unauthorizedError: logout,
    __: setUserError,
  },
})

forward({
  from: logout,
  to: resetUser,
})

logout.watch(() => {
  return Navigation.to(ROUTE_PATH.PROMO)
})

$user.watch((data) => {
  if (data) {
    Storage.set(STORAGE_NAMES.USER, data)
  }
})

resetUser.watch(() => {
  Storage.removeExcept([STORAGE_NAMES.COLOR_THEME, STORAGE_NAMES.LANG])
})

signInFx.doneData.watch(({ data }) => loginUser({ access: data.access, refresh: data.refresh }))
signInByCodeFx.doneData.watch(({ data }) => loginUser({ access: data.access, refresh: data.refresh }))
signInBySecretCodeFx.doneData.watch(({ data }) => loginUser({ access: data.access, refresh: data.refresh }))
signUpByCodeFx.doneData.watch(({ data }) => loginUser({ access: data.access, refresh: data.refresh }))
signUpByPasswordFx.doneData.watch(({ data }) => loginUser({ access: data.access, refresh: data.refresh }))
signUpFastFx.doneData.watch(({ data }) => loginUser({ access: data.access, refresh: data.refresh }))

// Change color theme
changeColorThemeFx.use(api.changeColorThemeApi)

guard({
  source: changeColorTheme,
  filter: sample({
    source: { isPending: changeColorThemeFx.pending, user: $user },
    fn: ({ isPending, user }) => !isPending && user !== null,
  }),

  target: changeColorThemeFx,
})

changeColorTheme.watch(({ theme }) => {
  Storage.set(STORAGE_NAMES.COLOR_THEME, theme)
})

// Change lang
changeLangFx.use(api.changeLangApi)

guard({
  source: changeLang,
  filter: sample({
    source: { isPending: changeLangFx.pending, user: $user },
    fn: ({ isPending, user }) => !isPending && user !== null,
  }),

  target: changeLangFx,
})

changeLang.watch(({ lang }) => {
  updateIntl(lang)
})

// delete User
deleteUserFx.use(api.deleteUserApi)

guard({
  source: deleteUser,
  filter: deleteUserFx.pending.map((state) => !state),
  target: deleteUserFx,
})

forward({
  from: deleteUserFx.done,
  to: logout,
})

// change Username
changeUsernameFx.use(api.changeUsernameApi)

guard({
  source: changeUsername,
  filter: changeUsernameFx.pending.map((state) => !state),
  target: changeUsernameFx,
})

// get secret code
getUserSecretCodeFx.use(api.getUserSecretCodeApi)

guard({
  source: getUserSecretCode,
  filter: getUserSecretCodeFx.pending.map((state) => !state),
  target: getUserSecretCodeFx,
})

// disable
disableSecretCodeFx.use(api.disableSecretCodeApi)

guard({
  source: disableSecretCode,
  filter: disableSecretCodeFx.pending.map((state) => !state),
  target: disableSecretCodeFx,
})

// refresh
refreshSecretCodeFx.use(api.refreshSecretCodeApi)

guard({
  source: refreshSecretCode,
  filter: refreshSecretCodeFx.pending.map((state) => !state),
  target: refreshSecretCodeFx,
})
