import { Box, ListItemIcon, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useStore } from 'effector-react'
import React from 'react'

import { NAV_CLASSES } from '@/shared/consts/onboarding'

import { $entry, updateMood, updateMoodFx } from '../../model'

import { moodOptions } from './consts'

interface MoodSelectorProps {
  disabled: boolean
}

export const MoodSelector: React.FC<MoodSelectorProps> = ({ disabled }) => {
  const entry = useStore($entry)
  const updatingMood = useStore(updateMoodFx.pending)

  // Обновление настроения
  const handleMoodChange = (event: SelectChangeEvent<string>) => {
    const selectedMood = event.target.value as string
    updateMood({ mood: selectedMood })
  }

  if (!entry) return null

  return (
    <Select
      sx={{
        width: 200,
        '& .MuiSelect-select': {
          padding: '8px', // Уменьшение внутренних отступов в Select
        },
      }}
      className={NAV_CLASSES.TODAY.FORM_MOOD}
      value={entry.mood || ''}
      onChange={handleMoodChange}
      disabled={updatingMood || disabled}
      displayEmpty
      renderValue={(selected) => {
        if (!selected) {
          return <Box sx={{ fontSize: 14, color: '#9e9e9e' }}>Как проходит ваш день?</Box>
        }
        const selectedMood = moodOptions.find((mood) => mood.value === selected)

        return (
          <Box display='flex' alignItems='center'>
            <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>{selectedMood?.icon}</ListItemIcon>
            {selectedMood?.label}
          </Box>
        )
      }}
    >
      {moodOptions.map((mood) => (
        <MenuItem key={mood.value} value={mood.value} sx={{ p: '8px' }}>
          <ListItemIcon>{mood.icon}</ListItemIcon>
          {mood.label}
        </MenuItem>
      ))}
    </Select>
  )
}
