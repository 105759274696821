import { useStore } from 'effector-react'

import { $user } from '@/services/user'
import { useMedia } from '@/shared/utils/media'

import { MobileNavigation } from './ui/mobile-navigation'
import { SidebarMenu } from './ui/sidebar-menu'

export const Sidebar = () => {
  const { isDesktop } = useMedia()
  const user = useStore($user)

  if (!user) return null

  if (!isDesktop) {
    return <MobileNavigation />
  }

  return <SidebarMenu />
}
