import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

export const FeedbackStatus = ({ onClose }: { onClose: () => void }) => {
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='xs'>
      <DialogTitle>Спасибо большое!</DialogTitle>
      <DialogContent dividers>
        <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
          <Typography variant='h6' color='text.primary' align='center'>
            Твоё сообщение полетело к нам как лучик света!
          </Typography>
          <Typography variant='body1' color='text.secondary' align='center'>
            Мы очень благодарны за твою обратную связь. Она помогает нам становиться лучше для вас!
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='contained' color='primary' fullWidth>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
