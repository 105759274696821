import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useState } from 'react'

import { Clipboard } from '@/shared/components/clipboard'

interface LogoutConfirmationDialogProps {
  open: boolean
  secretCode: string | null
  emailVerified: boolean
  onClose: () => void
  onLogout: () => void
}

export const LogoutConfirmationDialog: React.FC<LogoutConfirmationDialogProps> = ({
  open,
  secretCode,
  emailVerified,
  onClose,
  onLogout,
}) => {
  const [inputCode, setInputCode] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputCode(event.target.value)
  }

  const isLogoutDisabled = !emailVerified && inputCode !== secretCode

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Подтверждение выхода</DialogTitle>
      <DialogContent>
        {!emailVerified && (
          <>
            <DialogContentText color='error'>
              У тебя пока не подтвержден аккаунт. Очень важно сохранить этот секретный код – он поможет тебе снова войти
              в свой мир воспоминаний. Пожалуйста, скопируй и сохрани его в надежном месте. Я верю, что у тебя всё
              получится!
            </DialogContentText>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <Clipboard viewText={secretCode || ''} showIcon={true} withSnackbar={true} />
            </Box>
            <TextField
              label='Введите секретный код для выхода'
              variant='standard'
              fullWidth
              sx={{ mt: 2 }}
              value={inputCode}
              onChange={handleInputChange}
            />
          </>
        )}
        {emailVerified && <DialogContentText sx={{ mt: 2 }}>Выйти из аккаунта?</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Отмена
        </Button>
        <Button onClick={onLogout} color='error' variant='contained' disabled={isLogoutDisabled}>
          Выйти
        </Button>
      </DialogActions>
    </Dialog>
  )
}
