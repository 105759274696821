import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ROUTE_PATH } from '@/packages/paths'
import { Icon } from '@/shared/components/icon'

import { changeDrawerMenu } from '../../model/menu/events'
import { DrawerMenu } from '../drawer-menu'

import { navigationItems } from './utils'

export const MobileNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [active, setActive] = useState<string>(location.pathname)

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={3}>
      <BottomNavigation showLabels value={active}>
        {navigationItems.map(({ path, icon, text }) => (
          <BottomNavigationAction
            value={path}
            key={path}
            onClick={() => {
              setActive(path)
              navigate(path)
            }}
            label={text}
            icon={<Icon name={icon} />}
          />
        ))}
        <BottomNavigationAction
          value={ROUTE_PATH.PROFILE}
          onClick={() => changeDrawerMenu(true)} // Меняем состояние меню
          label='Меню'
          icon={<Icon name={'Menu'} />}
        />
      </BottomNavigation>
      <DrawerMenu />
    </Paper>
  )
}
