import { Alert } from '@mui/material'
import { useStore } from 'effector-react'

import { $requestVerifyCodeError, $verifyEmailError } from '@/services/email'
import { If } from '@/shared/utils/if'

export const EmailSettingsErrors = () => {
  const verifyEmailError = useStore($verifyEmailError)
  const requestVerifyCodeError = useStore($requestVerifyCodeError)

  return (
    <>
      <If condition={verifyEmailError}>
        <Alert variant='filled' severity='error' sx={{ my: 2 }}>
          {verifyEmailError}
        </Alert>
      </If>
      <If condition={requestVerifyCodeError}>
        <Alert variant='filled' severity='error' sx={{ my: 2 }}>
          {requestVerifyCodeError}
        </Alert>
      </If>
    </>
  )
}
