import { Box, Menu, MenuItem } from '@mui/material'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { langConfig, langList, Language } from '@/services/i18n'
import { $lang, changeLang } from '@/services/user'
import { Button } from '@/shared/components/button'
import { Text } from '@/shared/components/text'

const LANG_MENU_ID = 'theme_menu_header'

export const LangMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const activeLang = useStore($lang)

  const handleClose = () => setAnchorEl(null)

  const onChangeLang = (lang: Language) => {
    changeLang({ lang })
    handleClose()
  }

  const { icon: ActiveIcon, name } = langConfig[activeLang]

  return (
    <>
      <Button
        size='small'
        aria-controls={LANG_MENU_ID}
        aria-haspopup='true'
        onClick={(event) => setAnchorEl(event?.currentTarget)}
      >
        {<ActiveIcon style={{ width: 31 }} title={name} />}
      </Button>
      <Menu id={LANG_MENU_ID} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {langList.map(({ code, name, icon: Icon }) => (
          <MenuItem key={code} onClick={() => onChangeLang(code)} disabled={code === activeLang}>
            <Box sx={{ width: 20, mr: 1 }}>
              <Icon />
            </Box>
            <Text>{name}</Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
