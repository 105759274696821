/**
 * Склоняет слово в зависимости от числа
 * @param count - число, которое определяет форму слова
 * @param one - форма слова для числа 1 (например, "день")
 * @param few - форма слова для чисел 2, 3, 4 (например, "дня")
 * @param many - форма слова для чисел 5, 6, 7, 8, 9, 0 и чисел от 11 до 14 (например, "дней")
 * @returns - строка с правильной формой слова
 */
export const pluralize = (count: number, one: string, few: string, many: string): string => {
  const mod10 = count % 10
  const mod100 = count % 100

  if (mod100 >= 11 && mod100 <= 14) {
    return many
  }

  if (mod10 === 1) {
    return one
  }

  if (mod10 >= 2 && mod10 <= 4) {
    return few
  }

  return many
}
