import BlockIcon from '@mui/icons-material/Block'
import EditIcon from '@mui/icons-material/Edit' // Иконка карандаша
import { Box, Skeleton, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useNavigate } from 'react-router-dom' // Импортируем хук для редиректа

import { MoodIcon } from '@/shared/components/mood'

import { DayStatus } from '../../model'
import { getMonthEntriesFx } from '../../model/events'

import { getBackgroundColor } from './utils'

interface CalendarDayProps {
  day: DayStatus | null
  onClick?: () => void // Добавляем обработчик клика как пропс
}

export const CalendarDay: React.FC<CalendarDayProps> = ({ day, onClick }) => {
  const isLoading = useStore(getMonthEntriesFx.pending)
  const navigate = useNavigate() // Хук для навигации
  const iconSize = 32

  // Функция для определения иконки
  const getDayIcon = () => {
    if (isToday) return <EditIcon sx={{ fontSize: iconSize, color: '#2196f3' }} /> // Иконка для сегодняшнего дня
    if (!day) return <BlockIcon sx={{ fontSize: iconSize, color: '#ccc' }} />

    return <MoodIcon mood={day.mood} iconSize={iconSize} />
  }

  // Проверка, является ли день "inactive"
  const isInactive = day?.status === 'inactive'

  // Проверка, является ли день сегодняшним
  const isToday = day ? new Date(day.date).toDateString() === new Date().toDateString() : false

  // Обработчик клика
  const handleClick = () => {
    if (isToday) {
      navigate('/') // Редирект на главную страницу
    } else if (onClick) {
      onClick() // Вызываем переданный обработчик клика
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        border: '1px solid #ccc',
        backgroundColor: getBackgroundColor(day?.status || '', day?.mood, isToday, isLoading),
        transition: 'transform 0.3s, background-color 0.3s',
        opacity: isInactive ? 0.5 : 1,
        cursor: isInactive ? 'not-allowed' : 'pointer',
        transform: isInactive ? 'none' : 'scale(1)',
        '&:hover': {
          transform: isInactive || isLoading ? 'none' : 'scale(1.1)',
        },
      }}
      onClick={!isInactive ? handleClick : undefined} // Добавляем обработчик клика
    >
      {isLoading ? (
        <Skeleton variant='circular' width={iconSize} height={iconSize} />
      ) : isInactive ? (
        <BlockIcon sx={{ fontSize: iconSize, color: '#ccc' }} />
      ) : (
        getDayIcon()
      )}
      <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mt: '2px', color: isInactive ? '#ccc' : 'inherit' }}>
        {day ? new Date(day.date).getDate() : ''}
      </Typography>
    </Box>
  )
}
