import { Box, Card, CardContent, Container, Typography } from '@mui/material'

import { NAV_CLASSES } from '@/shared/consts/onboarding'

import { DeleteUser } from '../delete-user'
import { EmailSetting } from '../email-settings'
import { UsernameSettings } from '../login-settings'
import { PasswordSettings } from '../password-settings'
import { SecretCodeSettings } from '../secret-code-settings'

import * as Styled from './styled'

export const Profile = () => {
  return (
    <Styled.Root>
      <Container maxWidth='sm'>
        <Typography
          className={NAV_CLASSES.PROFILE.PAGE}
          variant='h4'
          sx={{ textAlign: 'center', mb: 4, fontWeight: 'bold', color: 'text.primary' }}
        >
          Профиль пользователя
        </Typography>

        <Card className={NAV_CLASSES.PROFILE.EMAIL} sx={{ mb: 3 }}>
          <CardContent>
            <EmailSetting />
          </CardContent>
        </Card>

        <Card className={NAV_CLASSES.PROFILE.PASSWORD} sx={{ mb: 3 }}>
          <CardContent>
            <PasswordSettings />
          </CardContent>
        </Card>

        <Card className={NAV_CLASSES.PROFILE.USERNAME} sx={{ mb: 3 }}>
          <CardContent>
            <UsernameSettings />
          </CardContent>
        </Card>

        <Card className={NAV_CLASSES.PROFILE.SECRET_CODE} sx={{ mb: 3 }}>
          <CardContent>
            <SecretCodeSettings />
          </CardContent>
        </Card>

        <Card className={NAV_CLASSES.PROFILE.DELETE}>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ textAlign: 'center' }}>
              <DeleteUser />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Styled.Root>
  )
}
