export enum OnboardingStepNames {
  Welcome = 'welcome',
  ChangeSettings = 'change-settings',
  GoToToday = 'go-to-today',
  InputToday = 'input-today',
  MoodToday = 'mood-today',
  GoToMemories = 'go-to-memories',
  Memories = 'memories',
  GoToCalendar = 'go-to-calendar',
  Calendar = 'calendar',
  GoToHelp = 'go-to-help',
  Help = 'help',
  GoToProfile = 'go-to-profile',
  Profile = 'profile',
  RegisterEmail = 'register-email',
  SetPassword = 'set-password',
  ChangeUsername = 'change-username',
  SecretCode = 'secret-code',
  DeleteAccount = 'delete-account',
  Finish = 'finish',
}
