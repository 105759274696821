import { ChangePasswordParams, PasswordError, PasswordResponse, RequestPasswordCodeParams } from '../user/types'

import { domain } from './domain'

export const requestPasswordCode = domain.event<RequestPasswordCodeParams>('requestPasswordCode')
export const requestPasswordCodeFx = domain.effect<RequestPasswordCodeParams, PasswordResponse, PasswordError>(
  'requestPasswordCodeFx'
)

export const changePassword = domain.event<ChangePasswordParams>('changePassword')
export const changePasswordFx = domain.effect<ChangePasswordParams, PasswordResponse, PasswordError>('changePasswordFx')

export const resetCodeRequested = domain.event<void>('resetCodeRequestted')
export const resetPasswordErrors = domain.event<void>('resetPasswordErrors')
