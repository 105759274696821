import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useStore } from 'effector-react'

import { startTour } from '@/features/onboarding'
import { $user } from '@/services/user'
import { Icon } from '@/shared/components/icon'
import { NAV_CLASSES } from '@/shared/consts/onboarding'

export const OnboardingButton = () => {
  const user = useStore($user)
  const onboardingStep = user?.preferences?.onboardingStep || null

  if (onboardingStep === 0) return null

  return (
    <ListItem sx={{ cursor: 'pointer' }} className={NAV_CLASSES.SIDEBAR.STUDY} onClick={() => startTour()}>
      <ListItemIcon>
        <Icon name='School' />
      </ListItemIcon>
      <ListItemText primary='Обучение' sx={{ color: 'text.primary' }} />
    </ListItem>
  )
}
