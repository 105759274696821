import { Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useState } from 'react'

import { $user } from '@/services/user'
import { NAV_CLASSES } from '@/shared/consts/onboarding'

import { $memories, MemoriesGate, MemoryEntry } from './model'
import * as Styled from './styled'
import { EmptyMemoriesMessage } from './ui/empty-memories-message'
import { LoadingSpinner } from './ui/loading-spinner'
import { MemoryDialog } from './ui/memory-dialog'
import { MemoryList } from './ui/memory-list'

export const Memories = () => {
  const memories = useStore($memories)
  const user = useStore($user)
  const [selectedMemory, setSelectedMemory] = useState<null | { date: string; content: string }>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  // Используем гейт для инициализации
  useGate(MemoriesGate)

  if (!memories) {
    return <LoadingSpinner />
  }

  const handleMemoryClick = (memory: MemoryEntry) => {
    if (memory.content && memory.date) {
      setSelectedMemory({ date: memory.date, content: memory.content })
      setDialogOpen(true)
    }
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedMemory(null)
  }

  return (
    <Styled.Root className={NAV_CLASSES.MEMORIES.PAGE}>
      <Typography variant='h4' sx={{ mb: 3, textAlign: 'center', color: 'text.primary' }}>
        Воспоминания
      </Typography>

      {user?.isNewUser ? (
        <EmptyMemoriesMessage />
      ) : (
        <MemoryList memories={memories} onMemoryClick={handleMemoryClick} />
      )}

      {selectedMemory && (
        <MemoryDialog
          open={dialogOpen}
          handleClose={handleCloseDialog}
          date={selectedMemory.date}
          content={selectedMemory.content}
        />
      )}
    </Styled.Root>
  )
}
