import { guard, sample } from 'effector'

import { closeFeedbackModal, submitFeedback, submitFeedbackFx } from './events'
import { feedbackForm } from './forms'

// Связываем событие отправки с эффектом отправки на сервер
guard({
  source: submitFeedback,
  filter: submitFeedbackFx.pending.map((state) => !state),
  target: submitFeedbackFx,
})

sample({
  source: feedbackForm.formValidated,
  fn: ({ requestType, text }) => ({
    requestType,
    text,
  }),
  target: submitFeedbackFx,
})

// Сбрасываем состояние отправки при закрытии модалки
closeFeedbackModal.watch(() => {
  feedbackForm.reset()
})
