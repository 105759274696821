import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

// Оверлей с нейтральным фоном
export const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10,
  textAlign: 'center',
  backdropFilter: 'blur(8px)', // Размытие фона позади
})

// Дополнительный текст с мягкой анимацией
export const MotivationalText = styled(Typography)({
  marginTop: '10px',
  color: '#ddd', // Более нейтральный цвет текста
  fontStyle: 'italic',
  animation: 'pulse 3s infinite', // Более медленная анимация
  '@keyframes pulse': {
    '0%': { opacity: 0.5 },
    '50%': { opacity: 0.8 },
    '100%': { opacity: 0.5 },
  },
})
