import { guard } from 'effector'
import { debounce } from 'lodash'

import {
  getTodayEntry,
  getTodayEntryFx,
  updateMood,
  updateMoodFx,
  updateTodayEntry,
  updateTodayEntryFx,
} from './events'

guard({
  source: getTodayEntry,
  filter: getTodayEntryFx.pending.map((state) => !state),
  target: getTodayEntryFx,
})

guard({
  source: updateMood,
  filter: updateMoodFx.pending.map((state) => !state),
  target: updateMoodFx,
})

const debouncedUpdate = debounce(({ content }) => {
  updateTodayEntryFx({ content })
}, 1000)

updateTodayEntry.watch((content) => {
  debouncedUpdate(content)
})
