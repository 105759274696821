import { createGate } from 'effector-react'

import { $user } from '@/services/user'

import { startTour } from './events'

// Гейт для запуска онбординга
export const OnboardingGate = createGate<{ onboardingStep?: number }>('OnboardingGate')

OnboardingGate.state.watch(({ onboardingStep }) => {
  const user = $user.getState()
  if (!user) return

  if (onboardingStep === 0) {
    setTimeout(() => {
      startTour()
    }, 200)
  }
})
