import { forward } from 'effector'

import { changeUsername, changeUsernameFx } from '@/services/user'

import { UsernameMode } from './enum'
import { changeUsernameMode } from './events'
import { $ChangeUsernameForm } from './forms'

forward({
  from: $ChangeUsernameForm.formValidated,
  to: changeUsername,
})

forward({
  from: changeUsernameFx.done,
  to: [$ChangeUsernameForm.reset, changeUsernameMode.prepend(() => UsernameMode.NONE)],
})
