import { Box, Divider } from '@mui/material'
import { useStore } from 'effector-react'
import { Element } from 'react-scroll'

import { AuthModal } from '@/features/auth'
import { $user } from '@/services/user'
import { If } from '@/shared/utils/if'

import { ELEMENTS } from './consts'
import * as Styled from './styled'
import { BlockInteractive } from './ui/block-interactive'
import { BlockIntro } from './ui/block-intro'
import { BlockInvite } from './ui/block-invite'
import { Header } from './ui/header'

export const Promo = () => {
  const user = useStore($user)

  return (
    <>
      <Styled.Root>
        <Header user={user} />
        <BlockIntro user={user} />
        <Box sx={{ width: '100%', my: 5 }}>
          <Divider />
        </Box>
        <Element name={ELEMENTS.BLOCK2}>
          <If condition={!user}>
            <BlockInteractive />
          </If>
          <If condition={user}>
            <BlockInvite />
          </If>
        </Element>
      </Styled.Root>
      <AuthModal />
    </>
  )
}
