import { guard } from 'effector'

import { getMemories, getMemoriesFx } from './events'

// Защита от повторных запросов на получение воспоминаний
guard({
  source: getMemories,
  filter: getMemoriesFx.pending.map((state) => !state),
  target: getMemoriesFx,
})
