import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { scroller } from 'react-scroll'

import { changeAuthModalVisible } from '@/features/auth'
import { ROUTE_PATH } from '@/packages/paths'
import { I18n } from '@/services/i18n'
import { User } from '@/services/user'
import { Text } from '@/shared/components/text'
import { Choose, Otherwise, When } from '@/shared/utils/choose'

import { ELEMENTS } from '../../consts'

import memoriesSrc from './assets/memories.jpg'
import * as Styled from './styled'

const onScrollBlock2 = () => {
  scroller.scrollTo(ELEMENTS.BLOCK2, {
    duration: 1000,
    delay: 0,
    smooth: 'linear',
  })
}

export const BlockIntro = ({ user }: { user: User | null }) => {
  const navigate = useNavigate()

  return (
    <Styled.Root>
      <Box sx={{ maxWidth: 600 }}>
        <Text
          id='features.promo.block-intro.title'
          component='h1'
          textAlign='center'
          sx={{ fontSize: { xs: 28, sm: 40, md: 48 } }}
          lineHeight={1.1}
        />
        <Text id='features.promo.block-intro.description' sx={{ mt: 2, fontSize: { xs: 16, sm: 16, md: 20 } }} />
        <Box component='img' src={memoriesSrc} sx={{ height: 320, my: { xs: 2, sm: 3 } }} />
        <Styled.ButtonWrapper>
          <Choose>
            <When condition={user}>
              <Box sx={{ width: 'max-fit' }}>
                <Typography sx={{ color: 'text.primary', fontSize: 16, mb: 1 }}>Привет, {user?.username}</Typography>
                <Button
                  sx={{ width: 300 }}
                  variant='contained'
                  color='success'
                  size='large'
                  onClick={() => navigate(ROUTE_PATH.HOME)}
                >
                  Войти
                </Button>
              </Box>
            </When>
            <Otherwise>
              <Button size='large' color='success' variant='contained' onClick={() => onScrollBlock2()}>
                <I18n id='features.promo.block-intro.button-go' />
              </Button>
              <Box sx={{ mt: { xs: 2, sm: 3 }, width: 'max-fit' }}>
                <Button variant='contained' onClick={() => changeAuthModalVisible(true)}>
                  <I18n id='features.promo.block-intro.button-enter' />
                </Button>
              </Box>
            </Otherwise>
          </Choose>
        </Styled.ButtonWrapper>
      </Box>
    </Styled.Root>
  )
}
