import { Template } from '../../templates/main'

import { Promo } from './ui/main'

export const PromoPage = () => {
  return (
    <Template withContainer={false} withSidebar={false}>
      <Promo />
    </Template>
  )
}

export default PromoPage
