import { i18n } from '@/services/i18n'
import { errorMessages, signInBySecretCodeFx } from '@/services/user'

import { domain } from './domain'
import { changeVisibleSecretModeModal } from './events'

export const $signInBySecretCodeError = domain
  .createStore<string | null>(null)
  .on(signInBySecretCodeFx.failData, (_, { response, message }) => {
    if (response?.status === 400) {
      return errorMessages[response.data.type]()
    }

    return `${i18n('services.auth.model.unknown_error')} ${message}`
  })
  .reset([signInBySecretCodeFx.done])

export const $secretModeModalVisible = domain
  .createStore<boolean>(false)
  .on(changeVisibleSecretModeModal, (_, value) => value)
  .reset([signInBySecretCodeFx.done])
