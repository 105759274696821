import { AxiosInstance } from 'axios'

import { api } from './api'
import { logout } from './events'
import { InterceprotsService } from './interceptors'

class ApiServiceClass {
  api: AxiosInstance
  constructor() {
    this.api = api
    this.api.interceptors.request.use(
      InterceprotsService.interseptorRequest,
      InterceprotsService.interseptorRequestError
    )

    this.api.interceptors.response.use(
      InterceprotsService.interseprotResponse,
      InterceprotsService.interseprotResponseError
    )
  }
}

const ApiService = new ApiServiceClass()
const Api = ApiService.api

export { Api, api, ApiService, logout }
