import { signUpByCodeFx, signUpByPasswordFx } from '@/services/user'

import { domain } from './domain'
import { SignUpMode } from './enum'
import { changeSignUpMode } from './events'

export const $signUpMode = domain
  .createStore<SignUpMode>(SignUpMode.BY_PASSWORD)
  .on(changeSignUpMode, (_, mode) => mode)
  .reset([signUpByCodeFx.done, signUpByPasswordFx.done])
