import { onboardingDomain } from './domain'

// События для управления онбордингом
export const startTour = onboardingDomain.createEvent<void>('startTour')
export const stopTour = onboardingDomain.createEvent<void>('stopTour')

export const changeOnboardingStepByName = onboardingDomain.createEvent<number>('changeOnboardingStepByName')
export const changeOnboardingNextStep = onboardingDomain.createEvent<void>('changeOnboardingNextStep')
export const closeSingleShow = onboardingDomain.createEvent<void>('closeSingleShow')

export const navigateTo = onboardingDomain.createEvent<string>('navigateTo') // Событие для навигации
