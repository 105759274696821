import { domain } from './domain'
import {
  ChangeColorThemeError,
  ChangeColorThemeParams,
  ChangeColorThemeResponse,
  ChangeLangError,
  ChangeLangParams,
  ChangeLangResponse,
  ChangeUsernameError,
  ChangeUsernameParams,
  ChangeUsernameResponse,
  DeleteUserError,
  DeleteUserParams,
  DeleteUserResponse,
  DisableSecretCodeParams,
  DisableSecretCodeResponse,
  GetUserError,
  GetUserParams,
  GetUserResponse,
  PasswordError,
  PasswordResponse,
  RefreshSecretCodeParams,
  RefreshSecretCodeResponse,
  SecretCodeParams,
  SecretCodeResponse,
  SetPasswordParams,
  SignInByCodeParams,
  SignInBySecretCodeParams,
  SignInParams,
  SignUpByCodeParams,
  SignUpByPasswordParams,
  SignUpFastParams,
  UserError,
  UserResponse,
  VerifyChangeEmailParams,
  VerifyChangeEmailResponse,
  VerifyEmailParams,
  VerifyEmailResponse,
} from './types'

export const getUser = domain.event<GetUserParams>('getUser')
export const getUserFx = domain.effect<GetUserParams, GetUserResponse, GetUserError>('getUserFx')

export const getUserSecretCode = domain.event<SecretCodeParams>('getUserSecretCode')
export const getUserSecretCodeFx = domain.effect<SecretCodeParams, SecretCodeResponse, GetUserError>(
  'getUserSecretCodeFx'
)

export const disableSecretCode = domain.event<DisableSecretCodeParams>('disableSecretCode')
export const disableSecretCodeFx = domain.effect<DisableSecretCodeParams, DisableSecretCodeResponse, GetUserError>(
  'disableSecretCodeFx'
)

export const refreshSecretCode = domain.event<RefreshSecretCodeParams>('refreshSecretCode')
export const refreshSecretCodeFx = domain.effect<RefreshSecretCodeParams, RefreshSecretCodeResponse, GetUserError>(
  'refreshSecretCodeFx'
)

export const changeColorTheme = domain.event<ChangeColorThemeParams>('changeColorTheme')
export const changeColorThemeFx = domain.effect<
  ChangeColorThemeParams,
  ChangeColorThemeResponse,
  ChangeColorThemeError
>('changeColorThemeFx')

export const changeLang = domain.event<ChangeLangParams>('changeLang')
export const changeLangFx = domain.effect<ChangeLangParams, ChangeLangResponse, ChangeLangError>('changeLangFx')

export const signIn = domain.event<SignInParams>('signIn')
export const signInFx = domain.effect<SignInParams, UserResponse, UserError>('signInFx')

export const signInByCode = domain.event<SignInByCodeParams>('signInByCode')
export const signInByCodeFx = domain.effect<SignInByCodeParams, UserResponse, UserError>('signInByCodeFx')

export const signInBySecretCode = domain.event<SignInBySecretCodeParams>('signInBySecretCode')
export const signInBySecretCodeFx = domain.effect<SignInBySecretCodeParams, UserResponse, UserError>(
  'signInBySecretCodeFx'
)

export const getRefreshToken = domain.event<void>('getRefreshToken')
export const getAccessToken = domain.event<void>('getRefreshToken')

export const setUserError = domain.event<GetUserError>('setUserError')

export const resetUser = domain.event<void>('resetUser')

export const signUpByPassword = domain.event<SignUpByPasswordParams>('signUpByPassword')
export const signUpByPasswordFx = domain.effect<SignUpByPasswordParams, UserResponse, UserError>('signUpByPasswordFx')

export const signUpByCode = domain.event<SignUpByCodeParams>('signUpByCode')
export const signUpByCodeFx = domain.effect<SignUpByCodeParams, UserResponse, UserError>('signUpByCodeFx')

export const signUpFast = domain.event<SignUpFastParams>('signUpFast')
export const signUpFastFx = domain.effect<SignUpFastParams, UserResponse, UserError>('signUpFastFx')

export const verifyEmail = domain.event<VerifyEmailParams>('verifyEmail')
export const verifyEmailFx = domain.effect<VerifyEmailParams, VerifyEmailResponse, UserError>('verifyEmailFx')

export const verifyChangeEmail = domain.event<VerifyChangeEmailParams>('verifyChangeEmail')
export const verifyChangeEmailFx = domain.effect<VerifyChangeEmailParams, VerifyChangeEmailResponse, UserError>(
  'verifyChangeEmailFx'
)

export const setPassword = domain.event<SetPasswordParams>('setPassword')
export const setPasswordFx = domain.effect<SetPasswordParams, PasswordResponse, PasswordError>('setPasswordFx')

export const deleteUser = domain.event<DeleteUserParams>('deleteUser')
export const deleteUserFx = domain.effect<DeleteUserParams, DeleteUserResponse, DeleteUserError>('deleteUserFx')

export const changeUsername = domain.event<ChangeUsernameParams>('changeUsername')
export const changeUsernameFx = domain.effect<ChangeUsernameParams, ChangeUsernameResponse, ChangeUsernameError>(
  'changeUsernameFx'
)
