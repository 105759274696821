import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'

import AuthRoute from './pages/auth-route'
import CalendarPage from './pages/calendar'
import ErrorPage from './pages/error'
import HelpPage from './pages/help'
import MainPage from './pages/main'
import MemoriesPage from './pages/memories'
import ProfilePage from './pages/profile'
import PromoPage from './pages/promo'
import { ROUTE_PATH } from './paths'
import BaseTemplate from './templates/base'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={ROUTE_PATH.HOME}
      errorElement={
        <BaseTemplate>
          <ErrorPage />
        </BaseTemplate>
      }
    >
      <Route
        index
        element={
          <BaseTemplate>
            <AuthRoute element={<MainPage />} />
          </BaseTemplate>
        }
      />
      <Route
        path={ROUTE_PATH.PROFILE}
        element={
          <BaseTemplate>
            <AuthRoute element={<ProfilePage />} />
          </BaseTemplate>
        }
      />
      <Route
        path={ROUTE_PATH.HELP}
        element={
          <BaseTemplate>
            <HelpPage />
          </BaseTemplate>
        }
      />
      <Route
        path={ROUTE_PATH.CALENDAR}
        element={
          <BaseTemplate>
            <AuthRoute element={<CalendarPage />} />
          </BaseTemplate>
        }
      />
      <Route
        path={ROUTE_PATH.MEMORIES}
        element={
          <BaseTemplate>
            <AuthRoute element={<MemoriesPage />} />
          </BaseTemplate>
        }
      />
      <Route
        path={ROUTE_PATH.PROMO}
        element={
          <BaseTemplate>
            <PromoPage />
          </BaseTemplate>
        }
      />
    </Route>
  )
)
