import { Template } from '../../templates/main'

import { Calendar } from './main'

export const CalendarPage = () => {
  return (
    <Template withContainer={false}>
      <Calendar />
    </Template>
  )
}

export default CalendarPage
