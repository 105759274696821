import { createStore } from 'effector'

import { logout } from '@/services/api'

import { getTodayEntryFx, updateMood, updateTodayEntry, updateTodayEntryFx } from './events'
import { DiaryEntry, StatusType } from './types'

export const $entry = createStore<DiaryEntry | null>(null)
  .on(getTodayEntryFx.doneData, (_, { data }) => data)
  .on(updateTodayEntry, (data, { content }) => (data ? { ...data, content } : null))
  .on(updateMood, (data, { mood }) => (data ? { ...data, mood } : null))
  .reset(logout)

export const $errorUpdateEntry = createStore<string | null>(null)
  .on(updateTodayEntryFx.failData, (_, error) => error.message) // Обновляем стор в случае ошибки
  .reset([updateTodayEntryFx, logout])

export const $statusEntry = createStore<StatusType>(StatusType.IDLE)
  .on(updateTodayEntry, () => StatusType.SAVING)
  .on(updateTodayEntryFx.done, () => StatusType.SAVED)
  .on(updateTodayEntryFx.failData, () => StatusType.ERROR)
  .reset(logout)
